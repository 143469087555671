import React from 'react'

export default function ShowExtraMoney(props) {
  
    const {extraHours} = props

    return(<li>
        <strong>שעות נוספות: </strong> <span>{extraHours.hours125} ב-125% - ₪{extraHours.hours125T}</span>
        {extraHours.hours150 && <>
            <span> | {extraHours.hours150} ב-150% -  ₪{extraHours.hours150T}</span>
        </>}
        <span> סה"כ </span>- <strong><small>₪</small>{extraHours.result}</strong>
    </li>)

}
