import React, { Component } from 'react'

import {getAllUrlParams} from "./../../models/UrlParameters";

import { Accordion } from 'react-bootstrap';

import SingleOrderAcordOrderDetails from './SingleOrderAcordOrderDetails'

import getDataFromApi from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import { orderSatus, showCancelStatus } from './../../models/ordersFuncNathan';

import WorkerLinkMain from './WorkerLinkMain'

import OrderPayIndex from './OrderPayIndex'
import OrderStatusIndex from './OrderStatusIndex'

import { NavLink } from 'react-router-dom'; 
import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { toast } from 'react-toastify';

import { replaceText } from "../../models/ordersFuncNathan";

export default class SingleOrder extends Component {

    constructor(props) {
        super(props)

        let orderId =  this.props.editOrderId ? this.props.editOrderId : false;

        let urlParams = getAllUrlParams(window.location.search);
        if(urlParams?.orderid && !this.props.editOrderId) {orderId = urlParams.orderid;}

        if(this.props.editOrderId && this.props.editOrderId !== urlParams?.orderid) {
            window.history.pushState('עריכת הזמנה','הזמנת שירות','/');
        }
        

        //console.log(urlParams);
    
        this.state = {
            currentSearchStatus : this.props?.currentSearchStatus ? this.props.currentSearchStatus : false,
            step : '1',

            orderId : orderId,

            loading : false,
            orderData : false,
            loadPays : false,
            loadStatus: false,

            isNewOrder : false,
            isRetroOrder : false,

            reloadOrders : false,
            loadWorkerLink : false,

            cancelRequest : false

        }
    }
    
    goBack = () => {
        
        let send = this.state;
        send.editOrderId = false;
        this.props.goBack(send);

    }

    newOrderIdHandle = (id, isRetroOrder = false) => {
        
        this.setState({

            step : '2',
            orderId : id,
            isNewOrder : true,
            isRetroOrder : isRetroOrder

        }, ()=> {

            this.getOrder(id);
            //console.log(this.state);
            
        })

    }

    getOrder = (orderId) => {

        this.setState( {orderData : false} )

        let sendObj = {
            orderId : orderId
        };

        this.sendtoAdmin('getOrder','orders',sendObj,'orderData');
        
    }
    
    componentDidMount() {
        
        if( this.state.orderId ) {
            
            window.history.pushState('עריכת הזמנה','הזמנת שירות','/order?orderId=' + this.state.orderId);

            this.getOrder(this.state.orderId);

        }

    }

    
    loadAcc = (name) => {
        
        if(!this.state[name]) {

            this.setState({ [name]: true });

        }
        

    }
    
    cancelOrderRequest = () => {

        const user = JSON.parse(localStorage.getItem('userData'));
        let sendObj = {
            orderId : this.state.orderId,
            userName : user.name,
            hospitalName : user.hospital.name
        };

        this.cancelOrderRequestApi('cancelOrderRequestSMS','orders',sendObj,'cancelRequest');

    }

    render() {

        let orderData = this.state.orderData && this.state.orderData.order ?  this.state.orderData.order : false; 
        let btnStatus = orderData ? orderSatus(orderData.orderStatus) : '';
        let user = JSON.parse(localStorage.getItem('userData'));
        
        if(this.state.orderData && !this.state.orderData?.order?.id) {
            return(
                <div className="smallWrapper">

                    <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
                    <h1 className="titleStyle">הזמנת שירות</h1>
                    
                    <div className="goBackBtnCont"><button onClick = { () =>  window.open('/userConsole', '_self') } className="goBackBtn bounce2">« חזור</button></div>
                    <p style={{color: 'red'}}>שגיאה בקישור ההזמנה / ההזמנה לא קיימת</p>
                </div>
            )
        }

        return (
            <div className="smallWrapper">

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                {this.props.goBack ? <div className="goBackBtnCont"><button onClick = { () => this.goBack() } className="goBackBtn bounce2">« חזור</button></div> : false }
                
                <h1 className="titleStyle">הזמנת שירות</h1>

                { ( user.userCredential !== '1' && this.state.orderId && !orderData?.cancelRequest) && <button disabled={this.state.cancelRequest || this.state.loading } className={`defaultBtnPic noPic noPadd ${!this.state.cancelRequest ? 'cancelOrderBtn' : 'cancelOrderBtn grey'}`} onClick={ this.cancelOrderRequest } >בקשה לביטול הזמנה</button> }

                {orderData?.cancelRequest && <button disabled={true} className={`defaultBtnPic noPic noPadd cancelOrderBtn`} >נשלחה בקשה לביטול הזמנה ב- {orderData.cancelRequest}</button>}

                <div className="accordionSec">
                    {/* defaultActiveKey="0" */}
                    <Accordion className="accordions acc1" defaultActiveKey= 'openMe'>

                        <Accordion.Item eventKey={ this.state.step === '1' ? 'openMe' : 'no1' } >
                            <Accordion.Header>
                                <span>פרטי הזמנה</span>

                                { orderData ?

                                    <header className='editHazmanaHeader'>
                                        <ul>
                                            <li><span>סטטוס:</span><div className={`showStatus ${btnStatus.nameClass}`}> { replaceText(btnStatus.text) } { showCancelStatus(orderData) } </div></li>
                                            <li><span>תאריך פתיחה: </span><strong>{ orderData.created_at }</strong></li>
                                            <li><span>נפתח על ידי: </span><strong>{ orderData.openUserName }</strong></li>
                                        </ul>
                                    </header> : false }

                            </Accordion.Header>

                            <Accordion.Body>
                                
                                { (this.state.orderId && this.state.orderData?.order?.id) || !this.state.orderId ? <SingleOrderAcordOrderDetails  goBack = { () => this.goBack() } orderData = { this.state.orderData } reloadOrders = { () => this.setState({ reloadOrders : true }) } newOrderId = { (id,isRetroOrder) => this.newOrderIdHandle(id,isRetroOrder) } /> : false }
                                
                            </Accordion.Body>

                        </Accordion.Item>

                        { this.state.orderId && (!orderData || orderData.orderStatus !== '4') ? <Accordion.Item onClick={ () => this.loadAcc('loadWorkerLink')  } eventKey={ this.state.step === '2' ? 'openMe' : 'no2' } >
                            
                            <Accordion.Header>
                                <span>שיוך עובד</span>
                            </Accordion.Header>

                            

                            <Accordion.Body>
                                <div className='acordCont colorDown noPaddLR'>

                                    {this.state.isNewOrder && user.userCredential === '1' ? 
                                        <header className='isNewOrder addPadd'>
                                            
                                            <h2 className='okH2 animate__animated animate__bounceIn'>בחרו עובד לשייך להזמנה: </h2>
                                            
                                        
                                    </header>  : false}
                                        
                                        
                                    { (this.state.loadWorkerLink || this.state.step === '2') && orderData ?
                                       < WorkerLinkMain orderData = { orderData } is_4selectWorker = {true} reloadOrders = { () => this.setState({ reloadOrders : true }) }  />
                                    : false }

                                    {this.state.isNewOrder ? <div className='goBackPageCont addPadd'>

                                        <NavLink
                                            className='defaultBtnPic noPic greyBack'
                                            role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                                            to='/userConsole' >
                                                <span>&laquo; חזרה לכל ההזמנות</span>
                                        </NavLink>

                                    </div> : false }

                                    {this.props.goBack ? <div className='addPadd'>

                                        <button className='defaultBtnPic noPic noPadd' onClick={ () => this.goBack() } >
                                            <span>&laquo; חזרה לכל ההזמנות</span>
                                        </button>

                                    </div> : false }

                                
                                </div>
                                
                            </Accordion.Body>

                        </Accordion.Item> : false }

                        
                        { ( orderData && orderData.is_prati === '1' && user.userCredential === '1' ) && <Accordion.Item onClick={ () => this.loadAcc('loadPays')  } eventKey={ this.state.step === '3' ? 'openMe' : 'no3' } >
                            
                            <Accordion.Header>
                                <span>גביית תשלום</span> <small>(#{orderData.id})</small>
                            </Accordion.Header>

                            <Accordion.Body>
                                    
                                {(this.state.loadPays || this.state.step === '3') && <div className='acordCont colorDown noPaddLR' style={{paddingTop: '0'}}>
                                    
                                    <OrderPayIndex orderData={ orderData } reloadOrders = { () => this.setState({ reloadOrders : true }) } reloadOrder={ ()=>this.getOrder(this.state.orderId) } />
                                    
                                </div> }
                                
                            </Accordion.Body>

                        </Accordion.Item> }

                        { ( orderData && orderData.is_prati !== '1' && user.userCredential === '1' ) && <Accordion.Item onClick={ () => this.loadAcc('loadStatus')  } eventKey={ this.state.step === '4' ? 'openMe' : 'no4' } >
                            
                            <Accordion.Header>
                                <span>סטטוס הזמנה</span> <small>(#{orderData.id})</small>
                            </Accordion.Header>

                            <Accordion.Body>

                                {(this.state.loadStatus || this.state.step === '4') && <div className='acordCont colorDown noPaddLR' style={{paddingTop: '0'}}>
                                    
                                    <OrderStatusIndex orderData={ orderData } reloadOrders = { () => this.setState({ reloadOrders : true }) } reloadOrder={ ()=>this.getOrder(this.state.orderId) } />

                                </div> }
                                
                            </Accordion.Body>

                        </Accordion.Item> }

                    </Accordion>
                    
                </div>
                
            </div>
        )
    }


    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        //console.log('sending...');

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false,
        
        }, function(){

            if( url === 'getOrder' ) {

                //console.log(getData.order);

                if ( (getData && getData.order && getData.order.orderStatus === '0') || ( getData.order?.is_retroOrder === '1' & !getData.order?.nurseData?.id )  ) {

                    this.setState({
                        step : '2'
                    })

                } else if (getData && getData.order && (getData.order.orderStatus === '2' || getData.order.orderStatus === '3') ) {

                    if(getData.order.is_prati === '1' ) {

                        this.setState({
                            step : '3'
                        })

                    } else {

                        this.setState({
                            step : '4'
                        })

                    }
                    

                }
            }

            //console.log(getData);
            
            /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */
            
            
        }))
    }


    cancelOrderRequestApi = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        //console.log('sending...');

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : true, loading : false,
        
        }, function(){

            if (getData?.ok) {

                toast.success(getData.ok);
                

            } else {


                toast.error('שגיאה');

            }
            
            
        }))
    }


}
