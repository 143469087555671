import React, { useState } from 'react'


import loader from "./../../../img/preLoader.gif";

import OrderPayShowTotals from './OrderPayShowTotals';
import OrderPayShowSavedPayedData from './OrderPayShowSavedPayedData'

import WorkerPaysIndex from './WorkerPaysIndex';
import OrderStatusChange from './OrderStatusChange';

import { sendtoAdmin } from '../../api/getDataFromApi'

export default function OrderStatusIndex(props) {

  const { orderData, reloadOrder } = props;
  //console.log(orderData);
  const [responseData, setResponseData] = useState(false);
  const [loading, setLoading] = useState(false);
  
  const [reloadWorkerPayIndex, setReloadWorkerPayIndex] = useState(false);


  if (!loading && !responseData) {

    const send = {
      orderId: orderData.id
    };

    sendtoAdmin('getPayData', 'Pay', send, setResponseData, setLoading);

  }

  const reloadData = () => { 

      reloadOrder();

   }


  return (
    <div className='OrderPayIndex animate__animated animate__fadeIn'>
      
      <div className='reloadData'>

        <button className='reloadBtn' onClick={ reloadData } >
            <i className="fas fa-sync-alt"></i>
            <span>רענון הזמנה</span>
        </button>

      </div>

      

      <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

      {responseData && <>
      
        <OrderPayShowTotals orderData={orderData} noPay={true} payData={responseData} />

        { (orderData.orderStatus === '3' && (orderData.travelMoney || orderData.shiftMoney) ) && <OrderPayShowSavedPayedData orderData = {orderData} payData={responseData}/> }

        { (orderData.orderStatus !== '3' && (!orderData.travelMoney && !orderData.shiftMoney) ) && <>
          { (orderData.nurseStart && orderData.nurseStop) ?

            <WorkerPaysIndex orderData={orderData} payData={responseData} reloadOrder = {reloadOrder} reloadResponse = {reloadWorkerPayIndex} responsePayData={responseData}/> :
            
            <div className='defaulAcordPading'>
              <h2 style={{color: 'red'}}>אין שעות עבודה לחישוב התשלום</h2>
            </div>
          }
        </>}
      
      
      </> }

      <OrderStatusChange responseData = {responseData} setResponseData = {setResponseData} orderData = {orderData} reloadOrder = {reloadOrder} setReloadWorkerPayIndex = {setReloadWorkerPayIndex} />

    </div>
  )



}







