import React, { useState } from 'react'

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

import { Form, FloatingLabel  } from 'react-bootstrap';
import addIco from './../../../img/nathan/svg/add.svg'

export default function OrderPayAddPayItemsForm(props) {

    const {orderData, showTravelExpenses } = props;

    //const [response, setRequest] = useState('')
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState(defaultValueForm(showTravelExpenses))
    const [showErrors, setshowErrors] = useState(false);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }

    const doSubmit = () => {

        setshowErrors(true);

        if(!formFlagError) {

            //console.log("ok");

            if (!loading) {

                const send = {
                    orderId: orderData.id,
                    name: formData.name,
                    SumValue: formData.price
                }
        
                sendtoAdmin('addPayItem', 'pay', send, setLoading, props.reloadData)

            }
            //props.cardForm(formData);
        }
        
    }

    let formFlagError = (!formData.name || !formData.price);

    
    return (
        <div className='inputs'>
                
                <h2>{orderData.is_prati !== '1' ? 'תשלום נסיעות' : 'הוספת פריטים לתשלום' }</h2>
                <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

                <div className='input text'>
                    <FloatingLabel label='שם פריט' >


                        <Form.Control
                            className="customFloatInput"
                            placeholder= 'שם פריט' 
                            isInvalid={showErrors && (!formData.name) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            disabled = { orderData.is_prati !== '1' ? true : false }
                            type="text"
                            name="name"
                            value={formData.name}
                            onChange={onChange}
                        />
                    </FloatingLabel>
                </div>

                <div className='input price'>
                    <FloatingLabel label="סכום לתשלום" >

                        <Form.Control
                            className="customFloatInput"
                            placeholder='סכום לתשלום'
                            isInvalid={showErrors && (!formData.price) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="number"
                            name="price"
                            value={formData.price}
                            onChange={onChange}
                        />
                    </FloatingLabel>
                </div>

                <button type="button" className={`defaultBtnPic ${formFlagError ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} >
                    <img src={addIco} alt='הוספה'/>
                    <span>הוספת פריט</span>
                </button>

            </div>
    )
}

function defaultValueForm(showTravelExpenses) {

    //console.log(pay);

    return {
        name : showTravelExpenses ? 'נסיעות' : '',
        price : ''
    }

}

function sendtoAdmin(url, controller, objectToSend, setLoading, reloadData) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {
        setLoading(false)
        //console.log(getData);

      if (getData.ok) {

        reloadData();
        //setRequest(getData.ok)
        //toast.success(getData.ok)
      } else if (getData.error) {
        toast.error(getData.error)
      }
    })

    getData.catch((error) => {
        setLoading(false)
      console.log(error)
    })

    getData.finally(() => {
      //setLoading(false)
    })
}
