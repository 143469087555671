import React, { Component } from 'react'
import WorkerLinkSearch from './WorkerLinkSearch'
import getDataFromApi from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import WorkersList from "./WorkersList"
import changeIcon from "./../../../img/nathan/svg/change.svg"
import WorkerEdit from './WorkerEdit';

import {RestUrls} from './../../api/config';

import WorkerConfirm from './WorkerConfirm';
import { toast } from 'react-toastify' //npm i react-toastify

import { Form } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

export default class WorkerLinkMain extends Component {

    constructor(props) {
        super(props)

        let orderData = this.props.orderData;
        let nurseData = orderData.nurseData && orderData.nurseData.name ? orderData.nurseData : false;

        this.state = {

            page: 0,
            startPage: 0,
            limit: this.props.limit ? this.props.limit : 3,

            response: false,
            loading: false,

            selectedNurse: nurseData,

            orderField: 'priorityId',
            orderAscDesc: 'DESC',

            newOrderNurseId: false,

            priority: '',
            district: '',
            freeSearch: '',

            workerBonus: false,

            editNurseData: false

        }
    }


    getNurses = () => {


        let orderData = this.props.orderData;

        //console.log(orderData);

        let sendObj = {

            orderField: this.state.orderField,
            orderAscDesc: this.state.orderAscDesc,

            priority: this.state.priority,
            district: this.state.district,
            freeSearch: this.state.freeSearch,

            page: this.state.page,
            startPage: this.state.startPage,
            limit: this.state.limit,

            orderId: orderData ? orderData.id : ''

        };

        //console.log('get workers', sendObj);
        this.sendtoAdmin('getNurses', 'nurses', sendObj, 'response');

    }



    updateSearchValues = (values) => {

        this.setState(values, () => {

            values.page = 0;
            this.getNurses();

        });


    }

    componentDidMount() {

        this.getNurses();

    }


    changePage = (setPage) => {

        this.setState({ page: setPage }, function () {

            this.getNurses();

        })
    }

    changeStartPage = (action) => {

        let changePage;

        if (action === '+') {

            changePage = this.state.startPage + 10 <= this.state.response.totalPages - 1 ? this.state.startPage + 10 : this.state.response.totalPages - 1;

        } else if (action === '-') {
            changePage = this.state.startPage - 10 < 0 ? 0 : this.state.startPage - 10;
        }



        this.setState({ startPage: changePage }, function () {

            this.getNurses();

        })
    }

    editNurse = (data) => {


        //this.props.editNurse(id);
        this.setState({ editNurseData: data })

    }

    selectNurse = (id,smsMessage = false) => {

        //console.log(smsMessage)

        let orderData = this.props.orderData;

        let sendObj = {

            orderId: orderData.id,
            nurseId: id,
            smsMessage : smsMessage,

            workerBonus : this.state?.workerBonus,
            hospitalId : orderData?.hospitalId,
            hospitalDivisionId : orderData?.hospitalDivisionId,
            targetDay : orderData?.targetDay

        };

        this.setState({newOrderNurseId: id}, () => {

            this.sendtoAdmin('assignNurseToOrder', 'nurses', sendObj, 'responseAssignNurse');

        })

        //console.log('orderData', orderData);
        //console.log('sendObj', sendObj);

    }

    changeOrder = (order) => {

        //console.log(order);

        this.setState({
            orderField: order.name,
            orderAscDesc: order.order
        }, () => {

            this.getNurses();

        })

    }

    changeSelectedNurse = (nurseData) => {

        if (nurseData) {
            this.setState({ selectedNurse: nurseData });
        } else {
            this.setState({ selectedNurse: false });
        }


    }

    goBack = (states) => {

        this.setState(states, () => {

            //console.log(this.state);

            if (states.reloadOrders) {
                this.getNurses();
            }
        })

    }

    confirmOrderLink = () => { 


        const nurseId = this.state.newOrderNurseId ? this.state.newOrderNurseId : this.props.orderData.nurseData.id;

        //console.log('hospitalshiftId', this.props.orderData.hospitalshiftId);
        //console.log('nurseData.id', nurseId);

        const md5 = require('md5'); //npm install md5
        const token = md5(this.props.orderData.hospitalshiftId + '|' + nurseId);
        const orderId = this.props.orderData.id;
        const endUrl = "?token=" + token + '&orderId=' + orderId;
        const url =   RestUrls.appUrl + '/confirm/' + endUrl;

        window.open(url, '_blank');

        console.log(url);

     }

    render() {

        let orderData = this.props.orderData ? this.props.orderData : false;

        let currentSearchStatus = {

            orderField: this.state.orderField,
            orderAscDesc: this.state.orderAscDesc,

            priority: this.state.priority,
            district: this.state.district,
            freeSearch: this.state.freeSearch

        };

        //console.log(orderData);

        const pages = [];
        let page = this.state.startPage;

        if (this.state.response.nurses && this.state.response.nurses[0]) {


            pages.push(<button key="btnStart" onClick={() => this.changeStartPage('-')} >&laquo;</button>);

            for (let i = 1; i <= 10; i++) {

                let pageNumber = page + i;

                if (pageNumber <= parseInt(this.state.response.totalPages)) {
                    let setPage = parseInt(pageNumber) - 1;
                    pages.push(<button className={this.state.page + 1 === pageNumber ? 'active' : ''} key={pageNumber} onClick={() => this.changePage(setPage)} >{pageNumber}</button>);
                }

            };

            pages.push(<button key="btnEnd" onClick={() => this.changeStartPage('+')} >&raquo;</button>);

        }


        let user = JSON.parse(localStorage.getItem('userData'));


        return (
            <div>

                <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

                { (orderData?.hospitalId === '9' && orderData?.hospitalDivisionId === '2' && user?.userCredential === '1' ) && <div className='bonusWorker'>

                    <Form.Check 
                        className={`holydayShitch ${this.state.workerBonus ? 'active' : ''}`}
                        checked={this.state.workerBonus}
                        onChange={ () => this.setState({ workerBonus : !this.state.workerBonus })}
                        type="switch"
                        id="custom-switch"
                        label="הוספת בונוס לעובד:"
                     />

                </div> }

                

                {orderData ? <ul className='showOutdated addPadd'>
                    
                    { user.userCredential === '1'  && <li>
                        <button className='reloadBtn' onClick={() => this.getNurses()} >
                            <i className="fas fa-sync-alt"></i>
                        </button>
                    </li> }
                    <li><span>תאריך משמרת: </span><strong className='numbers'>{orderData.targetDayShow}</strong></li>
                    <li><span>בית חולים: </span><strong className=''>{orderData.hospital}</strong></li>
                    <li><span>סוג משמרת: </span><strong>{orderData.hospitalShift}</strong></li>

                    {orderData.hospitalshiftId === '6' ? <li>
                        <span>בין השעות: </span><strong className='numbers'>{orderData.emergencyFrom} ל- {orderData.emergencyTo}</strong>
                    </li> : false}
                </ul> : false}

                {this.state.selectedNurse && this.state.selectedNurse.id ? <div className='showselectedNurse  animate__animated animate__fadeIn' >
                    <ul>
                        <li>{this.state.selectedNurse.name}</li>
                        <li>{this.state.selectedNurse.phone}</li>


                        {(user.hospital && user.hospital.length === 0) && <>
                            <li>{this.state.selectedNurse.email}</li>
                            <li>{this.state.selectedNurse.district}</li>
                            <li><div className={`showPriority colorId${this.state.selectedNurse.priorityId}`}> {this.state.selectedNurse.priority} </div> </li>
                        </>}

                        <WorkerConfirm orderData={orderData} showConfirm={true} output='li' />

                        {!orderData || orderData?.is_retroOrder || (!orderData.is_outdated && orderData.orderStatus !== '2' && orderData.orderStatus !== '3') ? <li>
                            {(user.hospital && user.hospital.length === 0) && <>
                            
                                <button className='btnChange' onClick={() => this.changeSelectedNurse()} >
                                    <img src={changeIcon} alt='החלף' />
                                    <span>החלפה</span>
                                </button>

                                <button className='btnChange' style={{margin: '0 5px 0 0' }} onClick={() => this.confirmOrderLink()} >
                                    <span>אישור הזמנה ידני</span>
                                </button>
                            
                            </>}
                        </li> : false}
                    </ul>

                </div> :

                    <div>

                        {this.state.editNurseData ?

                            <WorkerEdit nurseData={this.state.editNurseData} is_newNurse={false} goBack={(send) => this.goBack(send)} currentSearchStatus={currentSearchStatus} /> :

                            <div className='showListAndSearchWorkers'>

                                {orderData.nurseData && orderData.nurseData.id ? <div className="goBackBtnCont addPadd"><button onClick={() => this.changeSelectedNurse(orderData.nurseData)} className="goBackBtn bounce2">« חזור</button></div> : false}


                                {(user.hospital && user.hospital.length === 0) ? <><WorkerLinkSearch currentSearchStatus={currentSearchStatus} updateValues={(values) => this.updateSearchValues(values)} />

                                    {this.state.response.nurses && this.state.response.nurses.length > 0 ?

                                        <div className='addPadd' style={{ minHeight: '310px' }} >


                                            <WorkersList

                                                editNurse={(nurseData) => this.editNurse(nurseData)}
                                                selectNurse={(nurseId,smsMessage) => this.selectNurse(nurseId,smsMessage)}

                                                is_select={this.props.is_4selectWorker}

                                                nurses={this.state.response.nurses}
                                                changeOrder={(order) => this.changeOrder(order)}
                                                currentOrder={{ name: this.state.orderField, order: this.state.orderAscDesc }}
                                            /></div>
                                        : <h2 className='errorH2 addPadd' >אין עובדים להציג על פי החיפוש.</h2>}

                                    {this.state.response.nurses && this.state.response.nurses[0] ? <div className="pagesNumbers">

                                        {pages}
                                    </div> : false}

                                </> : <>
                                    <div className='addPadd' >
                                        <br />
                                        <h3 style={{ fontSize: '20px', color: 'red' }}>לא קיים עובד</h3><br />
                                    </div>
                                </>}
                            </div>}
                    </div>}
            </div>
        )
    }


    sendtoAdmin = (url, controller, objectToSend, setStateName = 'data', auth = 'all') => {


        this.setState({ loading: true });

        const getData = getDataFromApi(url, objectToSend, controller, auth);

        getData.then(getData => this.setState({
            [setStateName]: getData, loading: false,

        }, function () {

            //console.log(getData);

            if (url === 'assignNurseToOrder') {

                if (getData && getData.ok) {

                    this.setState({
                        selectedNurse: getData.ok
                    })

                    this.props.reloadOrders();

                }

                if (getData && getData.smsError) {

                    toast.error(getData.smsError);

                }

                if (getData && getData?.addBonus) {

                    toast.success(getData.addBonus);

                }

                if (getData && getData.smsWarning) {

                    toast.warning(getData.smsWarning);

                }


            }

            /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */


        }))
    }


}
