import React, {useState} from 'react'

import SystemHospitals from './SystemHospitals';
import SystemEditHospital from './SystemEditHospital';

export default function SystemHospitalsIndex() {

    const [editHospitalItem, setEditHospitalItem] = useState(false);

    const editHospital = (item) => {
        
        setEditHospitalItem(item);
    }
    

    return (
        <div>

            {!editHospitalItem ? <SystemHospitals changeHospital = { (item)=>editHospital(item) } /> :
            
                <div className='SystemEditHospital accordeonContent' >

                    <div className="goBackBtnCont">
                        <button className="goBackBtn bounce2" onClick={ ()=>setEditHospitalItem(false) }>« חזור</button>
                    </div>
                    
                    <SystemEditHospital item={editHospitalItem} />
                    
                </div> }
        </div>
    )
}
