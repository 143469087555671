const md5 = require('md5'); //npm install md5
let code = 'seb-webProject!nursesApp!wd+=111@$%+';

//console.log(md5(today));

//const baseApiUrl = 'https://otzarhaaretz.wdev.co.il/api/';
const baseApiUrl = 'https://nurses.nathan.co.il/api/';

export const RestUrls = {
    Code: md5(code),
    baseApiUrl : baseApiUrl,
    pictures : baseApiUrl + 'assets/img/pictures/',
    pagesPictures : baseApiUrl + 'assets/img/pages/',
    img : baseApiUrl + 'assets/img/',
    appUrl : 'https://nurses.nathan.co.il'
} 