import React, { useState } from 'react'

import addIco from './../../../../img/nathan/svg/add.svg'
import Grid from '@mui/material/Grid';
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import { Button, ProgressBar, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { useDropzone } from 'react-dropzone' /* https://react-dropzone.js.org/ */
import NoPic from './../../../../img/pic.png'
import loader from "./../../../../img/preLoader.gif";
import { toast } from 'react-toastify';

import { RestUrls } from "./../../../api/config";

export default function InsertFile(props) {

    const {showAddFiles, setShowAddFiles} = props

  return (
    <div>

        {!showAddFiles ? <div className='buttonsBar'>

            <button
                    className={`defaultBtnPic violet`}
                    onClick = { ()=> setShowAddFiles(!showAddFiles) }
                    //disabled={formData.contractId ? false : true}
                    >

                <img src={addIco} alt='הוספה'/>
                <span>הוספת קובץ</span>
            </button>

        </div> : <div className='insertFiles'>

            <h3>הוספת מסמך jpg/png/pdf עד 15 מגה</h3>
            <FormData2 {...props} />

        </div>}

        
    </div>
  )
}

function FormData2(props) {
    
    const { nurseData, realoadData } = props

    const [fileComments, setFileComments] = useState('');

    const [loading, ] = useState(false);

    const [picUrl, setPicUrl] = useState(false);
    const [isPicture, setIsPicture] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(null);
    const [, setUploaded] = useState(false);

    const [showErrorClass, setShowErrorClass] =  useState(false);
    const [fileSend, setFileSend] = useState(false);

    const onDrop = (acceptedFiles) => {
        
        let file = acceptedFiles[0];
        let filename = file?.name;

        //setFilenameState(filename);

        //console.log(file);

        //const text = file?.type + ' | ' + file?.size + ' | ';
        //setFilenameState2 (text);

        setPicUrl(URL.createObjectURL(file));

        if( !filename.includes('.pdf') && (filename.toLowerCase().includes('.jpg') || filename.toLowerCase().includes('.jpeg') || filename.toLowerCase().includes('.png') ) ) {
            setIsPicture(true);
        } else {
            //console.log(filename);
            setIsPicture(false);
        }
        
        uploadImage(file);
        
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        //accept: 'pdf,image/jpeg, image/png',
        noKeyboard: true,
        //maxFiles:1,
        //maxSize:200000,
        multiple: false,
        onDrop,
    })

    const uploadImage = (file) => {
       //console.log(file)
       fileUploadHandler(file);
    }

    const  fileUploadHandler = (file) => {

        setFileSend(file);
            
    }


    const submit = (data) => {

        //sendtoApi('updateSeniorContact', 'siud', data, setLoading)
        
        if(!fileComments) {
        
            toast.error('יש להוסיף שם קובץ');
            setShowErrorClass(true);
            return false;
            
        }

        const fd = new FormData();

        let userJ = JSON.parse(localStorage.getItem('userData'));

        //console.log('userJ', userJ);
        fd.append('userId', userJ.id);
        fd.append('userToken', userJ.token);
        fd.append('userCredential', userJ.userCredential);

        fd.append('auth', 'all');

        fd.append('token', RestUrls.Code);
        fd.append('siteCode', RestUrls.Code);

        fd.append('nurseId', nurseData.id);
        fd.append('fileComments', fileComments);

        if(fileSend) {
            
            //console.log('fileSend', fileSend);
            fd.append('fileToUpload', fileSend, fileSend.name);
            

        } else {

            toast.error('יש להוסיף קובץ');
            setShowErrorClass(true);
            return false;

        }

        //console.log('submiting axios....', data);
        //console.log('nurseData', nurseData);

        //return;
        const url =  RestUrls.baseApiUrl + 'nurses/uploadFile';

        //console.log('url', url);
        
        axios.post(url, fd, {

            headers: {
                "Content-Type" : 'multipart/form-data',
                "Accept" : 'application/json'
                },

            onUploadProgress: progressEvent => {

                setUploadProgress(Math.round(progressEvent.loaded / progressEvent.total * 100 ));

            }
        })
            .then( res => {

                
                //console.log('data',res.data);
                setUploadProgress(null);

                if(res.data?.ok) {

                    toast.success(res.data.ok);
                    setUploaded(true);
                    realoadData(res.data.filesData);

                } else {

                    if(res.data?.error) {

                        setPicUrl(false);
                        setIsPicture(false);
                        toast.error(res.data.error);
                        
                        //const errors = JSON.stringify(res.data.errors);
                        //toast.error(errors);

                    } else {

                        toast.error('שגיאה');

                    }
                    
                }
               
            })
            
            .catch((error) => {
                toast.error('שגיאה בהעלאת הקובץ');
                setUploadProgress(null);
                console.log(error)
              })
            
            

    }

    return(
        <div className='formsInputs'>

            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

            <form>

            <Grid className='lineForm newFormLine' container spacing={4}  alignItems="center"  direction="row"  > {/* justifyContent="flex-end" */}

                <Grid item md={6} >

                    <FloatingLabel
                        label={'שם קובץ / הערות'}
                        /* className="mb-3" */
                        >
                        <Form.Control
                            className={"customFloatInput"}
                            isInvalid={ showErrorClass && !fileComments }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type={'text'}
                            //name="inputValue"
                            //disabled={this.props.disabled ? true : false}
                            placeholder={'שם קובץ / הערות'}
                            onChange={ (e)=> setFileComments(e.target.value) }
                            value={fileComments}
                        />
                        
                    </FloatingLabel>

                </Grid>
                
                <Grid item md={3} style={{textAlign: 'left'}}>
                    
                    <div className="user-file" {...getRootProps()}>
                
                        
                        <input {...getInputProps()} />
                        {isDragActive ? (
                            <img className={`drag `} src={NoPic} alt='alt' />
                        ) : (
                            <img className={`noDrag ${!picUrl && 'noPic'} ${showErrorClass && !fileSend ? 'errorNoFile' : '' } `} src={ picUrl && isPicture ? picUrl : NoPic } alt=''/>
                        )}

                    </div>

                </Grid>

                <Grid item md={3} style={{textAlign: 'left'}}>
                    
                    <Button type='button' variant='primary' size="lg" onClick={ submit }>
                        {loading ? <Spinner size="" animation="border" variant="light" /> : <span>הוספת מסמך</span> }
                    </Button> 
                    
                </Grid>

                <Grid item md={12} style={{textAlign: 'left'}}>
                    {uploadProgress && <ProgressBar now={uploadProgress} /> }
                </Grid>

                
            </Grid>

            </form>
        </div>
    )



}