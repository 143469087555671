import React, { useEffect, useState } from 'react'
import InsertFile from './InsertFile';
import { isEmpty, map } from 'lodash';

import { sendtoAdmin } from '../../../api/getDataFromApi';
import loader from "./../../../../img/preLoader.gif";
import { RestUrls } from '../../../api/config';

export default function FilesWorkerIndex(props) {

    const { nurseData } = props
    const [showAddFiles, setShowAddFiles] = useState(false);
    const [ myFiles, setMyFiles ] = useState([]);

    //console.log('nurseData >', nurseData );

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const send = {
            nurseId : nurseData.id
           };

        sendtoAdmin('getWorkersFilesFront','nurses', send ,setMyFiles,setLoading);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    //console.log('myFiles', myFiles);

    const realoadData = (files) => { 

        setShowAddFiles(false);
        setMyFiles({ filesData : files});
        //console.log('realoadData...');

    }

  return (
    <div className='filesWorkerIndex'>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        <div className='uploadFile'>
            <InsertFile realoadData = { realoadData } nurseData = {nurseData} showAddFiles = {showAddFiles} setShowAddFiles = {setShowAddFiles} />
        </div>

        { !isEmpty(myFiles?.filesData) && <div className='showAllFiles'>
            < ShowFiles myFiles = {myFiles.filesData}  setLoading = { setLoading } setMyFiles = {setMyFiles}  nurseData = {nurseData} />
        </div> }

    </div>
  )
}


function ShowFiles(props) {

    const { myFiles,  setLoading, setMyFiles, nurseData } = props

    const statusOff = (item) => { 

        //console.log('item', item);

        const send = {
            nurseId : nurseData.id,
            rowId : item.id
        };

        sendtoAdmin('updateStatusOffFile','nurses', send ,setMyFiles,setLoading);

     }

    return(
        <div className='showFiles'>
            <h3>קבצים קיימים: </h3>

            <ul>
                
                {    map(myFiles, item => {

                    const url =  RestUrls.baseApiUrl + 'assets/files/' + item.upload_path + item.file_name;
                    
                    return(<li key={item?.id}>
                        <span>{item.created_atShow}</span> | 
                        <span className='statusOff' onClick={ ()=> statusOff(item)} ><i className="far fa-trash-alt"></i></span> | 
                        <span>{item.fileComments}</span> | 
                        <a href={url} target='_blank' rel="noreferrer">פתיחת הקובץ &raquo;</a>
                    </li>)
                    
                    }) 
                }

                <li></li>
            </ul>

        </div>
    )


}