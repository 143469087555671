import React, {useState} from 'react'

import { Form, Col, Row, Button } from "react-bootstrap";
import {RestUrls} from './../../../api/config'

import { sendtoAdmin } from '../../../api/getDataFromApi';
import loader from "./../../../../img/preLoader.gif";
import ShowDatePicker from './ShowDatePicker';
import { convertDate } from '../../../models/ordersFuncNathan';

export default function HospitalReport() {

    const [responseData, setResponseData] = useState(false);


    const [loading, setLoading] = useState(false);

    if(!loading && !responseData) {

        const send = {
             id : ''
            };

        sendtoAdmin('getHospitalsList','app', send ,setResponseData,setLoading);

    }

   //console.log(responseData);
    
   const dates = getDates();

   const [selectedDates, setSelectedDates] = useState({
        year: new Date().getFullYear(),
        month: '',
        hospitalId: '',
        hospitalDivisionId: '',
        showCancel : ''
   })

   const onChange = (e) => { 

        setSelectedDates({
            ...selectedDates,
            [e.target.name]: e.target.value,
        })

    }

    const onSubmit = () => { 

        //console.log(selectedDates);

        let queryLine = '?year=' + selectedDates.year + '&month=' + selectedDates.month + '&hospitalId=' + selectedDates.hospitalId + '&hospitalDivisionId=' + selectedDates.hospitalDivisionId;
        
        queryLine += '&showCancel=' + selectedDates.showCancel;

        queryLine = queryLine + '&token=' + RestUrls.Code;

        //queryLine = queryLine + '&showArray=1';
        let controllerReport = 'hospitalReport';

        if( selectedDates.hospitalId === '1' || selectedDates.hospitalId === '16' || selectedDates.hospitalId === '17' ) {
            controllerReport = 'hospitalReportWolfson'
        }

        const url = RestUrls.baseApiUrl + 'ReportsExcel/' + controllerReport + queryLine + '&';
        window.open(url, '_blank').focus();

    }

    //console.log('hello');

    const hadleDatePeriod = (date,nameField) => {

        if(nameField === 'start') { setDateStart(date);}
        else if (nameField === 'end') { setDateEnd(date); }

        //console.log('date > ', date);
        //console.log('nameField > ', nameField);
    
    }

    
    const [dateStart, setDateStart] = useState(false);
    const [dateEnd, setDateEnd] = useState(false);


        const onSubmitPeriod = () => { 

            let dateStartSend = dateStart;
            let dateEndSend = dateEnd;

            const today = new Date();
            const current_datetime = new Date(today.toString().replace(/-/g, '/'));

            if(!dateStartSend)  {dateStartSend = convertDate(current_datetime)}
            if(!dateEndSend)  {dateEndSend = convertDate(current_datetime)}


        let queryLine = '?dateStart=' + dateStartSend + '&dateEnd=' + dateEndSend + '&hospitalId=' + selectedDates.hospitalId + '&hospitalDivisionId=' + selectedDates.hospitalDivisionId;
        queryLine += '&showCancel=' + selectedDates.showCancel;
        queryLine = queryLine + '&token=' + RestUrls.Code;

        //queryLine = queryLine + '&showArray=1';
        let controllerReport = 'hospitalReport';

        if( selectedDates.hospitalId === '1' || selectedDates.hospitalId === '16' || selectedDates.hospitalId === '17' ) {
            controllerReport = 'hospitalReportWolfson'
        }

        const url = RestUrls.baseApiUrl + 'ReportsExcel/' + controllerReport + queryLine + '&';
        window.open(url, '_blank').focus();
        

    }


    const flagSend = selectedDates.hospitalDivisionId && selectedDates.year && selectedDates.month && selectedDates.hospitalId;

    const flagSendPeriod = selectedDates.hospitalDivisionId && selectedDates.hospitalId; //dateStart && dateEnd
    
    //console.log(selectedDates.hospitalDivisionId,selectedDates.hospitalId,dateStart,dateEnd); //dateStart && dateEnd

  return (

    <>
        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        {(responseData && responseData?.hospitals) && <Row >

            
            <Col md='4'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="hospitalId"
                    onChange={onChange}
                    value={selectedDates.hospitalId}
                >
                    <option value="">בחירת בית חולים</option>

                    {responseData.hospitals.map((item) => {
                    if (true) {
                        return (
                        <option value={item.id} key={item.id}>
                            {item.name}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

            </Col>

            <Col md='4'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="hospitalDivisionId"
                    onChange={onChange}
                    value={selectedDates.hospitalDivisionId}
                >
                    <option value="">בחירת מחלקה</option>

                    <option value="all">-כל המחלקות-</option>

                    {responseData.hospitalDivisions.map((item) => {
                    if (true) {  //item.name !== 'פרטי'
                        return (
                        <option value={item.id} key={item.id}>
                            {item.name}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

            </Col>

            <Col md='4'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="showCancel"
                    onChange={onChange}
                    value={selectedDates.showCancel}
                >
                    <option value="">לא להציג הזמנות מבוטלות</option>                    
                    <option value="1">להציג הזמנות מבוטלות</option>

                </Form.Control>

            </Col>
            
            <Col md='3'>
            
            <Form.Control
                as="select"
                className="customSelect"
                disabled={false}
                type='select'
                name="month"
                onChange={onChange}
                value={selectedDates.month}
            >
                <option value="">בחירת חודש</option>

                {dates.month.map((item) => {
                if (true) {
                    return (
                    <option value={item} key={item}>
                        {item}
                    </option>
                    );
                } else return false;
                })}
            </Form.Control>

            </Col>

            <Col md='2'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="year"
                    onChange={onChange}
                    value={selectedDates.year}
                >
                    <option value="">בחירת שנה</option>

                    {dates.years.map((item) => {
                    if (true) {
                        return (
                        <option value={item} key={item}>
                            {item}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

            </Col>

            <Col md='2'>
                <Button onClick={ onSubmit } disabled={(flagSend) ? false : true } variant={ flagSend ? "primary" : "secondary" } >שליחה</Button>
            </Col>

        </Row> }

        {(responseData && responseData?.hospitals) && <div className='periodReport'>

            <h3>הפקת דו"ח לפי תקופה</h3>
            <Row >
            
                <Col md='3'>

                    <Form.Control
                        as="select"
                        className="customSelect"
                        disabled={false}
                        type='select'
                        name="hospitalId"
                        onChange={onChange}
                        value={selectedDates.hospitalId}
                    >
                        <option value="">בחירת בית חולים</option>

                        {responseData.hospitals.map((item) => {
                        if (true) {
                            return (
                            <option value={item.id} key={item.id}>
                                {item.name}
                            </option>
                            );
                        } else return false;
                        })}
                    </Form.Control>

                </Col>

                <Col md='3'>

                    <Form.Control
                        as="select"
                        className="customSelect"
                        disabled={false}
                        type='select'
                        name="hospitalDivisionId"
                        onChange={onChange}
                        value={selectedDates.hospitalDivisionId}
                    >
                        <option value="">בחירת מחלקה</option>

                        <option value="all">-כל המחלקות-</option>

                        {responseData.hospitalDivisions.map((item) => {
                        if (true) {  //item.name !== 'פרטי'
                            return (
                            <option value={item.id} key={item.id}>
                                {item.name}
                            </option>
                            );
                        } else return false;
                        })}
                    </Form.Control>

                </Col>

                <Col md='2'>
                    <h4>תאריך התחלה:</h4>
                    <ShowDatePicker hadleDateRetro = { (date)=>hadleDatePeriod(date,'start') } />

                </Col>

                <Col md='2'>
                    <h4>תאריך סיום:</h4>
                    <ShowDatePicker hadleDateRetro = { (date)=>hadleDatePeriod(date,'end') } />

                </Col>

            </Row>

            <Row style={{marginTop : '10px'}}>

                <Col md='4'>

                    <Form.Control
                        as="select"
                        className="customSelect"
                        disabled={false}
                        type='select'
                        name="showCancel"
                        onChange={onChange}
                        value={selectedDates.showCancel}
                    >
                        <option value="">לא להציג הזמנות מבוטלות</option>                    
                        <option value="1">להציג הזמנות מבוטלות</option>

                    </Form.Control>

                </Col>

                <Col md='2'>
                    <Button onClick={ onSubmitPeriod } disabled={ !flagSendPeriod } variant={ flagSendPeriod ? "primary" : "secondary" } >שליחה</Button>
                </Col>

            </Row> 
        
        </div>}

    </>

  )
}

function getDates() {

    const month = [
        '01','02','03','04','05','06','07','08','09','10','11','12'
    ]

    const year = new Date().getFullYear();
    //const year = 2023;
    let years = [];

    for (let index = 2022; index <= year; index++) {
        years.push(index);
    }

    //console.log('years', years);

    return { month : month, years : years }
    
}