
export function orderSatus(status) {

    let btnStatus = '';

    switch (status) {

        case '1':

            btnStatus = {
                text : 'פתוח',
                nameClass : 'green'
            }

            return btnStatus;
            
        
        case '0':

            btnStatus = {
                text : 'אין שיוך עובד',
                nameClass : 'red'
            }

            return btnStatus;

        case '2':

            btnStatus = {
                text : 'לא שולם',
                nameClass : 'yellow'
            }

            return btnStatus;

        case '3':

            btnStatus = {
                text : 'סגור',
                nameClass : 'cyan'
            }

            return btnStatus;

        case '4':

            btnStatus = {
                text : 'בוטל',
                nameClass : 'grey'
            }

            return btnStatus;


        default:

            btnStatus = {
                text : 'noText',
                nameClass : 'noClass'
            };

            return btnStatus;
    }

    

}

export function getDays() {

    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    const yy = yyyy.toString().substring(2);
    today = dd + '/' + mm + '/' + yy;


    let nextdays = new Date();
    nextdays.setDate(nextdays.getDate()+6);

    const dd1 = String(nextdays.getDate()).padStart(2, '0');
    const mm1 = String(nextdays.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy1 = nextdays.getFullYear();

    const yy1 = yyyy1.toString().substring(2);
    nextdays = dd1 + '/' + mm1 + '/' + yy1;


    const days = {
        today : today,
        nextdays : nextdays
    }


    return days

}


export function convertDate (dateValue) {

    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX
 
    
 
    /* 2021-03-25 */
 
    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
 }

 export function convertDate2 (dateValue) {

    let current_datetime = new Date(dateValue); // IPHONE FIX
 
    
 
    /* 2021-03-25 */
 
    let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate();
                        
    return formatted_date;
 }
 
 
 export function convertDateToDisplay (dateValue) {
 
    let current_datetime = new Date(dateValue.toString().replace(/-/g, '/')); // IPHONE FIX
    /* 2021-03-25 */
 
 /*     let formatted_date = current_datetime.getFullYear() + '-' +
                        (current_datetime.getMonth() + 1) + "-" +
                        current_datetime.getDate(); */
 
    let formatted_date = 
 
          ('0' + (current_datetime.getDate())).slice(-2) + '/' +
 
           ('0' + (current_datetime.getMonth()+1)).slice(-2) + '/'
 
           
         //(current_datetime.getMonth() + 1) + "/" +
            + current_datetime.getFullYear();
                        
    return formatted_date;
 }
 
 
 export function convertDBDate(dbdate) {
 
    const returnDate = new Date(dbdate.toString().replace(/-/g, '/'));  
 
    return returnDate;
 
 }
 
 export function daysBetweenTwoDates(date1,date2) {
 
    //https://www.geeksforgeeks.org/how-to-calculate-the-number-of-days-between-two-dates-in-javascript/
    
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
 
    //console.log(date1,date2);
 
    return parseInt(Difference_In_Days)
 
 }
 
 
 export function addDaysToDate(date1,days) {
 
    //console.log(date1);
    //console.log( days);
 
    var result = new Date();
    result.setDate(result.getDate() + parseInt(days) );
    return result;
 
 }

 export function replaceText(value) {

    let newValue = value;
    const user = JSON.parse(localStorage.getItem('userData'));
  
  
    if(user?.hospital?.id && value === 'בוטל') {
  
      newValue = 'לא סופק';
  
    }
  
    return newValue;
  
  }

  export function showCancelStatus(orderData) {

    //console.log('orderData', orderData);

    if( orderData?.cancelReason && orderData?.orderStatus === '4' ) {
        return '(על ידי ' + orderData?.cancelReason + ')';
    }

  }