import React, {useState} from 'react'
import { Form, Col, Row, Button } from "react-bootstrap";

import {RestUrls} from './../../../api/config'

import AddSymbolToWorker from './AddSymbolToWorker'

export default function NavMonewReport() {

    
   const dates = getDates();

   const [selectedDates, setSelectedDates] = useState({
        year: new Date().getFullYear(),
        month: '',
   })

   const onChange = (e) => { 

        setSelectedDates({
            ...selectedDates,
            [e.target.name]: e.target.value,
        })

    }

    const onSubmit = () => { 

        console.log(selectedDates);

        let queryLine = '?year=' + selectedDates.year + '&month=' + selectedDates.month;

        queryLine = queryLine + '&token=' + RestUrls.Code;

        const url = RestUrls.baseApiUrl + 'money/moneyReport' + queryLine + '&download=1';

        window.open(url, '_blank').focus();

     }

     const onSubmitExcel = () => { 

        console.log(selectedDates);

        let queryLine = '?year=' + selectedDates.year + '&month=' + selectedDates.month;

        queryLine = queryLine + '&token=' + RestUrls.Code;

        const url = RestUrls.baseApiUrl + 'money/moneyReportExcell' + queryLine + '&download=1';

        window.open(url, '_blank').focus();

     }

     
  return (

    <>
        <Row >

            <Col md='3'>
            
            <Form.Control
                as="select"
                className="customSelect"
                disabled={false}
                type='select'
                name="month"
                onChange={onChange}
                value={selectedDates.month}
            >
                <option value="">בחירת חודש</option>

                {dates.month.map((item) => {
                if (true) {
                    return (
                    <option value={item} key={item}>
                        {item}
                    </option>
                    );
                } else return false;
                })}
            </Form.Control>

            </Col>

            <Col md='3'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="year"
                    onChange={onChange}
                    value={selectedDates.year}
                >
                    <option value="">בחירת שנה</option>

                    {dates.years.map((item) => {
                    if (true) {
                        return (
                        <option value={item} key={item}>
                            {item}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

            </Col>

            <Col md='3'>
                <Button onClick={ onSubmit } disabled={selectedDates.year && selectedDates.month ? false : true } variant="primary">שליחה</Button>
                <Button style={{marginRight : '10px'}} onClick={ onSubmitExcel } disabled={selectedDates.year && selectedDates.month ? false : true } variant="secondary">EXCEL</Button>
            </Col>

        </Row>

        { (selectedDates.year && selectedDates.month) && <Row >
            <Col md='12'>
                <AddSymbolToWorker selectedDates={selectedDates} />
            </Col>
        </Row> }

    </>

  )
}

function getDates() {

    const month = [
        '01','02','03','04','05','06','07','08','09','10','11','12'
    ]

    const year = new Date().getFullYear();
    //const year = 2023;
    let years = [];

    for (let index = 2022; index <= year; index++) {
        years.push(index);
    }

    //console.log('years', years);

    return { month : month, years : years }
    
}