import React, {useState} from 'react';

import sendSms from './../../../img/nathan/svg/sendSms.svg'

import { InputGroup, FormControl } from "react-bootstrap";

import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'


export default function OrderSms() {

    const [openInputs, setOpenInputs] = useState(false);
    const [loading, setLoading] = useState(false)
    
    let user = JSON.parse(localStorage.getItem("userData"));

    const [formData, setFormData] = useState(
        {
            name: user?.name ? user.name : '',
            phone: '' }
    );

    const open = () => {

        setOpenInputs(true);
        //console.log('Send SMS');
    };

    const send = () => {

        //console.log(formData);
        sendtoAdmin('smsOrder', 'orders', formData, setLoading, setOpenInputs)

    };

    
    if(user.userCredential === '1') {
        return null;
    }
    

  return <div className='OrderSms'>

        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />
  
        {!openInputs ? <button className="defaultBtnPic grey" onClick={ open } >
            <img src={sendSms} alt='sendSms'/>
            <span >פתיחת הזמנה טלפונית</span>
        </button> : <button className="defaultBtnPic grey" onClick={ ()=>setOpenInputs(false)  } >
            <span >X</span>
        </button> }

        {openInputs && <div className='inputsCont animate__animated animate__fadeIn'>
            
            <InputGroup className="inputGroup">
                <FormControl
                    className="customInput"
                    placeholder="שם לחזרה"
                    onChange={e=>setFormData({ ...formData, name : e.target.value })}
                    value={formData.name}
                    name="name"
                ></FormControl>
            </InputGroup>

            <InputGroup className="inputGroup">
                <FormControl
                    className="customInput"
                    placeholder="נייד לחזרה"
                    onChange={e=>setFormData({ ...formData, phone : e.target.value })}
                    value={formData.phone}
                    name="phone"
                ></FormControl>
            </InputGroup>
            <button className="defaultBtnPic noPic noPadd" onClick={ send }>שליחה</button>
        </div> }

  </div>;
}

function sendtoAdmin(url, controller, objectToSend, setLoading, setOpenInputs) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

      if (getData.ok) {
        //setRequest(getData.ok)
        toast.success('נשלח בהצלחה');
        setOpenInputs(false);

      } else if (getData.error) {

        toast.error(getData.error);

      }
    })

    getData.catch((error) => {
      console.log(error)
    })

    getData.finally(() => {
      setLoading(false)
    })
  }