import React from 'react'

import mail from './../../../img/nathan/svg/config/mail.svg'
import mobile from './../../../img/nathan/svg/config/mobile.svg'
import place from './../../../img/nathan/svg/config/place.svg'

export default function ConfigUserData(props) {

    const { userData } = props;


    //console.log(userData);

    return (

        <div className='userData'>

            <header className='nameHeader'>
                <h2> { userData.name } </h2>
                <p> { userData.hospitalDivision } </p>
            </header>

            <ul>
                <li>
                    <img src={mobile} alt='mobile' />
                    <strong>נייד: </strong>
                    <span>{userData.phone}</span>
                </li>
                <li>
                    <img src={mail} alt='mail' />
                    <strong>אימייל: </strong>
                    <span>{userData.email}</span>
                </li>
                <li>
                    <img src={place} alt='place' />
                    <strong>כתובת: </strong>
                    <span>{userData.address}</span>
                </li>
            </ul>

            <ul className='moreData'>
                <li>
                    <strong>עדיפות למחוז: </strong>
                    <span>{checkIsEmpty(userData.district)}</span>
                </li>
                <li>
                    <h3>פרטי בנק</h3>
                </li>
                <li>
                    <strong>מספר חשבון: </strong>
                    <span>{checkIsEmpty(userData.accountNumber)}</span>
                </li>
                <li>
                    <strong>בנק: </strong>
                    <span>{checkIsEmpty(userData.bank)}</span>
                </li>
                <li>
                    <strong>סניף: </strong>
                    <span>{checkIsEmpty(userData.bankBranch)}</span>
                </li>
            </ul>

            
            
        </div>
    )
}

function checkIsEmpty(value) {

    return value ? value : '---';
    
}
