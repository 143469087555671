import React, { useState } from 'react'
import { toast } from 'react-toastify' //npm i react-toastify

import play from './../../../img/nathan/svg/play.svg'
import stop from './../../../img/nathan/svg/stop.svg'

import callendar from './../../../img/nathan/svg/callendar.svg'

import getDataFromApi from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
import {RestUrls} from '../../api/config'
const md5 = require('md5'); //npm install md5

export default function FirstStartShift(props) {

    const { order } = props;

    const is_nurseStartDb = order.nurseStart ? true : false;

    const [isPlay, setIsPlay] = useState(is_nurseStartDb);
    const [loading, setLoading] = useState(false);
    
    const starValues = {
        start : order.nurseStart ? order.nurseStartShow : '',
        stop : order.nurseStop ? order.nurseStopShow : ''
    }

    const [playStopTime, setPlayStopTime] = useState(starValues);

    
    const user = JSON.parse(localStorage.getItem("userData"));
    const nurseId = user.id.replace('-','')
    const token = md5(order.hospitalshiftId + '|' + nurseId);
    const urlConfirm = RestUrls.appUrl + '/confirm?token=' + token + '&orderId=' + order.id;

    function handlerPlayStop() {

        if(!loading) {

            const send = {
                orderId : order.id
            }

            //console.log(send);

            sendtoAdmin('playStopHandler','NurseEnv', send , setLoading, setPlayStopTime );
        }

    }

    return (
        <div className='FirstStartShift'>
            
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            <div className='FirstStartShiftCont'>

                {order.nurseConfirm === '0' && <a className="defaultBtnPic noPic noPadd confirmBtn bounce3" href={urlConfirm} target="_blank" rel='noopener noreferrer' >לאישור הגעה למשמרת &raquo;</a>}

                <div className={`showStartStopCont ${ order.is_todayOrder ? '' : 'disabled' }`} 
                    onClick={ () => !order.is_todayOrder ? toast.error('ניתן להתחיל בזמן קצר במועד המשמרת') : false } >

                    {getShowTime(playStopTime,order)}

                    {!playStopTime.start || !playStopTime.stop ? <button disabled={ order.is_todayOrder ? false : true  } className='playStop' onClick={ () => handlerPlayStop() } >
                        <img src={ isPlay ? stop : play } alt='play and stop' />
                    </button> : <button style={{ marginTop: '20px'}} className='reloadBtn' onClick={ ()=> window.open('/','_self') } >
                        <i className="fas fa-sync-alt"></i>
                    </button> }

                </div>

                <div className='downCont'>

                    {order.nurseConfirm === '1' && <h3 className='orderConfirmOk'>הגעה למשמרת אושרה</h3> }
                    {order.nurseConfirm === '2' && <h3 className='orderConfirmNO'>סירוב להגעה למשמרת</h3> }

                    <ul>
                        <li>
                            <img src={ callendar } alt='callendar' />
                        </li>
                        <li className='number'>{ props.orderCounts }</li>
                        <li>משמרות השבוע</li>
                    </ul>
                </div>

            </div>
            
        </div>
    )

    function sendtoAdmin(url,controller,objectToSend, setLoading, setPlayStopTime  ) {

        setLoading(true);
        const getData = getDataFromApi(url,objectToSend,controller,'all');
    
        getData.then( (getData) => {
    
            if(getData.ok) {

                const values = {
                    start : getData.start,
                    stop : getData.stop
                }

                setPlayStopTime(values);

                if(getData.action === 'start') {

                    setIsPlay(true);

                } else if(getData.action === 'stop') {

                    setIsPlay(false);

                }

                toast.success(getData.ok)

                

            } else if(getData.error)  {

                toast.error(getData.error)

            }
            
            //console.log(getData);
            
        })
    
        getData.catch((error) => {
            console.log(error);
        })
    
        getData.finally(() => {
            setLoading(false);
        })
    
    }


    function getShowTime(playStopTime) {

        const stop = playStopTime.stop ? playStopTime.stop : '__:__';

        if(!playStopTime.start) {

                return(
                    <h3>התחלת משמרת</h3>
                );

        } else {

            return (
                <ul className='showTime'>
                    <li>התחלה: <span>{playStopTime.start}</span></li>
                    <li>סיום: <span>{stop}</span></li>
                </ul>
            )

        }


    }


}
