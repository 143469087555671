import React from 'react'

import FirstScreen from './FirstScreen';
import ConfigIndex from './ConfigIndex';
import Confirm from './Confirm'

export default function NurseIndex(props) {

    switch (props.page) {
        
        case "config":
          return (
            <React.Fragment>
              <ConfigIndex {...props} />
            </React.Fragment>
          );

          case "confirm":
          return (
            <React.Fragment>
              <Confirm {...props} />
            </React.Fragment>
          );


          

        /* case "archive":
          return (
            <React.Fragment>
              <h1>Archive</h1>
            </React.Fragment>
          ); */
  
        default:
          return (
            <React.Fragment>
              <FirstScreen {...props} />
            </React.Fragment>
          );
    }
    
    
}
