import React, {useEffect, useState} from 'react'

//import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'
import ShowExtraMoney from './Money/ShowExtraMoney';
import OrderPayAddPayItems from './OrderPayAddPayItems'

export default function WorkerPaysIndex(props) {

    const {orderData, payData, reloadResponse, responsePayData, reloadOrder, notShowItems } = props;

    //console.log('orderData :>> ', orderData);

    const [response, setRequest] = useState(reloadResponse)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
      
      setRequest(false);

    }, [ reloadResponse ])


    if(!response && !loading) {

        const send = {
            orderId: orderData.id,
          }
    
        sendtoApi('getNurseMoney', 'NursesPays', send, setLoading, setRequest);

    }

    return (
    <div className='WorkerPaysIndex'>
        
        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />
        
        

        { (response && !notShowItems) && <OrderPayAddPayItems showTravelExpenses = {true} reloadData={ () => reloadOrder() } payData={responsePayData} orderData={orderData} /> }


        <h2>תשלום לפי משמרת:</h2>

        {response && <div className='nursePaysResultCont'>

            {/* {console.log('responseMoney', response.money)} */}

            {!response?.ok ? <div className='errorMessage'>
                    <span>{ response?.error ? response?.error : 'שגיאה' }</span>
            </div> : <div className='okCont'>
                
                { (response?.money && response.money.text && response.money.text.length > 0 ) && <ul>
                    
                    <li className='contractName'><span>שם החוזה:</span> <strong>{response.money.contractName}</strong></li>

                    { response.money.text.map(item => { if (true) {
                        /* currentState={this.state} */
                        return  <li key={item.from} >{item.from} - {item.to} - {item.price} ({item.timeTotal}) - <strong><small>₪</small>{item.money}</strong></li>
                    } else return false}) }

                    {response?.money?.extraHours?.result && <ShowExtraMoney extraHours={ response.money.extraHours } />}

                    <li className='totalAmount'>לתשלום: <strong>
                      <small>₪</small>{response.money.resultPrice}</strong>
                      
                      {response?.money?.extraHours?.result && <>
                        <span> + </span><small>₪</small>{response.money.extraHours.result}
                        <small> (שעות נוספות)</small>
                      </>}

                      {payData?.payItems.count > 0 && <>
                        <span> + </span><small>₪</small>{payData.payItems.count}
                        <small> (תוספות)</small>
                      </>}
                      
                    </li>
                </ul> }

            </div>}
            

        </div>}

        
    </div>
  )
}

function sendtoApi(url, controller, objectToSend, setLoading, setRequest) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {

        //console.log(getData);
        setRequest(getData);
      /* if (getData.ok) {

        //setRequest(getData)
        //toast.success(getData.ok)

      } else if (getData.error) {

        //toast.error(getData.error)
      } */
    })
  
    getData.catch((error) => {
      console.log(error)
      setRequest(getData);
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }