import React, {useState, useEffect } from 'react'

import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify' //npm i react-toastify

import { Table, Form } from 'react-bootstrap';
import edit from '../../../img/nathan/svg/config/edit.svg'

import addIco from './../../../img/nathan/svg/add.svg'

export default function SystemContracts(props) {

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInsertHospital, setShowInsertHospital] = useState(false);

    const [formDataNewContract, setformDataNewContract] = useState(defaultValueForm())
    
    //const [emergency, setEmergency] = useState(is_visible)

    useEffect(() => {
       
        getContracts();

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])

    const getContracts = () => {

        if(!loading && !responseData) {

            sendtoAdmin('getContracts','contracts', {id: ''} ,setResponseData,setLoading);
    
        }
        
    }
    
    const editHospital = (item) => {
        
        props.changeHospital(item);

    }
    

    const changeVisible = (item) => {
        
        console.log(item);

        const send = {
            rowId : item.id, 
            changeTo : item.notShowNewOrders === '0' ? '1' : '0',
        }

        //console.log(send);

        changeStatusApi('changeOnOffContract','contracts', send , setLoading, setResponseData);
        

    }

    /* InsertHospital */

    const onChange = (e) => {

        setformDataNewContract({
          ...formDataNewContract,
          [e.target.name]: e.target.value,
        })

        //console.log('in')
      }
    
      let flagNewHospital = formDataNewContract.contractName &&  formDataNewContract.hospitalId && formDataNewContract.hospitalDivisionId ? true : false;
      
      const  onSubmitContract = () => {

        //console.log(formDataNewContract);

        insertHospitalApi('insertContract','contracts', formDataNewContract , setLoading, setResponseData, setformDataNewContract, setShowInsertHospital);

        
      }

    /*   let uniqueHospital = [];

      if(responseData?.hospitals) {

        uniqueHospital = [...new Map(responseData.hospitals.map(item => [item['hospitalName'], item])).values()];

      } */
      

    return (
        <div className='SystemHospitals accordeonContent SystemContracts'>
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
            
            <div className='buttonCont'>

                <button className="defaultBtnPic violet" onClick={()=>setShowInsertHospital(!showInsertHospital)}>
                    <img src={addIco} alt="הוספה"/>
                    <span>הוספת חוזה עבודה</span>
                </button>

            </div>
            
            

            {(showInsertHospital && responseData) && <div className='addHospitaCont clear'>


                    <Form onChange={onChange} >

                        <Form.Control
                            className=""
                            placeholder='שם חוזה'
                            //isInvalid={this.state.isError}
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="text"
                            name="contractName"
                        />

                        <Form.Control
                            as="select"
                            className="customSelectInput "
                            type='select'
                            name="hospitalId"
                        >
                            <option value="">בחירת בית חולים...</option>

                            {responseData.hospitalsAll.map((item) => {
                            if (true) {
                                
                                return (
                                <option value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                                );
                            } else return false;
                            })}
                        </Form.Control>
                        
                        <Form.Control
                            as="select"
                            className="customSelectInput "
                            type='select'
                            name="hospitalDivisionId"
                        >
                            <option value="">בחירת מחלקה...</option>

                            {responseData.hospitalDivisions.map((item) => {
                            if (true) {
                                
                                return (
                                <option value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                                );
                            } else return false;
                            })}
                        </Form.Control>
                        

                        <button  type='button' onClick={onSubmitContract} className={`defaultBtnPic noPic ${!flagNewHospital ? 'disabledBtn' : 'animate__animated animate__bounceIn'}`} disabled={!flagNewHospital}>הוספת חוזה עבודה</button>

                    </Form>
            </div>}

            <div className='tableHospital'>
                <Table striped >
                    <thead>
                        <tr>
                            <th>שם חוזה</th>
                            <th>בית חולים</th>
                            <th>סוג שירות</th>
                            <th></th>
                        </tr>
                    </thead>

                    {responseData.hospitals && responseData.hospitals[0]?.id ? <tbody>
                        
                        {responseData.hospitals.map((item) => {

                            let is_visible = item.notShowNewOrders === '0' ? true : false;

                            if (true) { //orders[0].id !== item.id

                                return (
                                    <tr key={item.id}>
                                        <td>{item.contractName}</td>
                                        <td>{item.hospitalName}</td>
                                        <td>{item.hospitalDivisionName}</td>
                                        <td>
                                            <Switch

                                                checked={is_visible}
                                                onChange={ () => changeVisible(item) }
                                                name={`showMe${item.id}`}
                                                color="primary"
                                            />
                                            <button className='editBtnHospital' onClick={()=>editHospital(item)}>
                                                <img src={edit} alt='עריכה' />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            } else return false;
                        })}
                        
                    </tbody> : <tbody>
                        <tr><td colSpan={4} className='noResults'>אין חוזים</td></tr>
                    </tbody> }

                </Table>
            </div>
        </div>
    )


    function changeStatusApi(url, controller, objectToSend, setLoading, setResponseData) {

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        getData.then((getData) => {

          if (getData.ok) {
                
                sendtoAdmin('getContracts','contracts', {id: ''} ,setResponseData,setLoading);
                toast.success(getData.ok);

          } else if (getData.error) {

            toast.error(getData.error)

          }

        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }



      function insertHospitalApi(url, controller, objectToSend, setLoading, setResponseData, setformDataNewContract, setShowInsertHospital) {

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        getData.then((getData) => {

          if (getData.ok) {
                
                toast.success(getData.ok);
                setformDataNewContract(defaultValueForm());
                setShowInsertHospital(false);
                
                sendtoAdmin('getContracts','contracts', {id: ''} ,setResponseData,setLoading);

          } else if (getData.error) {

            toast.error(getData.error)

          }

        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }
}


function defaultValueForm() {
    return {
      contractName: null,
      hospitalDivisionId: null,
      hospitalId: null
    }
  }


  


