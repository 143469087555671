import React from 'react'

import NurseIndex from '../pages/NurseEnv/NurseIndex'
import NavMobile from '../pages/NurseEnv/NavMobile'
import '../pages/NurseEnv/nurseEnv.scss'

export default function NurseViewComposer(props) {

    //console.log(props);
    
    //if(props.isMobile) {

        return (<div className="body-container NurseEnv Mobile">
            < NavMobile  {...props} />
            
            <div className="mobileCont">

                < NurseIndex {...props} />

            </div>
            

            <div className='credit'>
                <a href="https://www.waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                    <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                </a>
            </div>
            
        </div>)

    //}

    /* else {

        return (<div className="body-container NurseEnv Desktop" >

            <h1 style={{textAlign: 'center', padding: '20px 0'}}>!MOBILE ONLY</h1>
                
        </div>)
    
    } */
    
}
