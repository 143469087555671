import React, { useState } from 'react'
import Switch from '@mui/material/Switch';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

export default function FirstUpdataEmergency(props) {

    const nurseData = props.nurseData;

    //console.log(nurseData);

    const is_emergencyService = nurseData?.is_emergencyService === '1' ? true : false;
    const [emergency, setEmergency] = useState(is_emergencyService)
    
    const [loading, setLoading] = useState(false);
    

    const changeEmergency = () => {
        
        if(!loading) {

            const send = {
                nurseId :  nurseData.id
            }

            sendtoAdmin('emergencyHandler','NurseEnv', send , setLoading, setEmergency );
        }


    }
    

    return (
        <div className='FirstUpdataEmergency'>
            
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            <span>אני זמין/ה עכשיו</span>

            <Switch
                    checked={emergency}
                    onChange={ changeEmergency }
                    name="loading"
                    color="primary"
                />

        </div>
    )


    function sendtoAdmin(url,controller,objectToSend, setLoading, setEmergency  ) {

        setLoading(true);
        const getData = getDataFromApi(url,objectToSend,controller,'all');

        console.log(getData)

        getData.then( (getData) => {
    
            if(getData.ok) {

                setEmergency(getData.flag);
                toast.success(getData.ok);

            } else if(getData.error)  {

                toast.error(getData.error)

            }
            
        })
    
        getData.catch((error) => {
            console.log(error);
        })
    
        getData.finally(() => {
            setLoading(false);
        })
    
    }
}
