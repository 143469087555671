import React, { useState } from 'react'
import { checkDay, defaultValues, checkActiveDay,  checkActiveClass } from './../../models/checkDay'
import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
import { toast } from 'react-toastify' //npm i react-toastify

import { Arr } from 'array-helpers'; //npm i array-helpers //https://www.npmjs.com/package/array-helpers

//import { Button } from 'react-bootstrap';

export default function WorkerWeeklySchedule(props) {

    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);

    const [responseHospitalShifts, setResponseShifts] = useState(false);
    const [responseUpdateShifts, setResponseUpdateShifts] = useState(false);
    const [loading, setLoading] = useState(false);
    const [updatedFields, setUpdatedFields] = useState(false)

    //const selectedShifts = defaultValues();

    const [selectedShifts, setSelectedShifts] = useState(defaultValues())
    const mainArray = new Arr(selectedShifts);


    const handlerClicks = (values, allDays = false ) => {

        const shiftsDayArr = mainArray.multifilter('day',values.day);
        let idsShift = shiftsDayArr[0].shifts;

        if(allDays && idsShift.includes('1')) {
            idsShift.splice(0, 10);
        }
        else if(allDays) {

            //console.log(values.dayNumber);

            for (let i = 1; i <= 5; i++) { 

                if(
                    ( values.dayNumber < 6 && ( i !==  4 && i !==  5 ) ) ||
                    ( values.dayNumber === 6 && ( i !==  5 ) ) ||
                    ( values.dayNumber === 7 && ( i !==  4  ) )
                ) {

                    if(!idsShift.includes( String(i) )) {
                        idsShift.push( String(i) );
                    }

                }
                
                
            }
            

        } else {

            if(!idsShift.includes(values.idShift)) {
                
                idsShift.push(values.idShift);

            } else {

                const index = idsShift.indexOf(values.idShift);

                if (index > -1) {
                    idsShift.splice(index, 1);
                }

            }
        }

        forceUpdate();

    }

    const saveData = () => {

        let sendObj = {
            nurseId : props.nurseData.id,
            shiftsData : selectedShifts
        }
        
        //console.log(sendObj);
        sendtoAdmin('updateShifts','nurses',sendObj,setResponseUpdateShifts,setLoading);

    }
    

    if(!loading && !responseHospitalShifts) {

        const send = {nurseId : props.nurseData.id ? props.nurseData.id : '' };

        sendtoAdmin('getShifts','app', send ,setResponseShifts,setLoading);
    }

    if(responseHospitalShifts?.nurseShitfts && !updatedFields ) {
        
        if(responseHospitalShifts.nurseShitfts[0]) {
            setSelectedShifts(responseHospitalShifts.nurseShitfts);
            setUpdatedFields(true);
        }
        
    }


    if(responseUpdateShifts?.ok) {

        toast.success(responseUpdateShifts.ok);
        setResponseUpdateShifts(false);

    } else if(responseUpdateShifts?.error) {
        toast.error(responseUpdateShifts?.error);
        setResponseUpdateShifts(false);
    }

    let buttons = [];
    if(responseHospitalShifts?.hospitalShifts) {

        for (let i = 1; i <= 7; i++) {

        
            buttons.push(
    
                <ul className={`line${i}`} key={'lineBtn' + i } >
                    <li   key={'li1' + i } onClick={ ()=> handlerClicks({ day: checkDay(i).engDay, idShift: '', dayNumber: i }, true) } className={`dayName ${checkActiveDay(checkDay(i).engDay,mainArray)}`}>{ checkDay(i).hebrewDay }</li>
                    {responseHospitalShifts.hospitalShifts.map((item) => {

                        let objButton = { day: checkDay(i).engDay, idShift: item.id };

                        if (
                            
                            ( i < 6 && ( item.id !==  '4' &&  item.id !==  '5' ) ) ||
                            ( i === 6 && ( item.id !==  '5' ) ) ||
                            ( i === 7 && ( item.id !==  '4'  ) )  

                        
                        ){

                            
                            return (
                                <li  key={ item.id } className={`btnCont ${checkActiveClass(objButton,mainArray)}`} >
                                    <button onClick={ ()=> {handlerClicks(objButton)} } >{item.value}</button>
                                </li>
                            );
                        } else return false;
                    })}

                </ul>
            
            );
            
    
        };


    }

    

    return (
        <div className='WorkerWeeklySchedule'>
          
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            <h3>בחירת ימים ושעות</h3>

            {responseHospitalShifts ? <div className='WorkerWeeklySchedule__buttonsCont animate__animated animate__fadeIn'>
                { buttons }
            </div> : false }

            <div className='WorkerWeeklySchedule__saveData'>
                <button className="defaultBtnPic noPic noPadd" onClick={ saveData } >שמירת שינוים</button>
            </div>

          
        </div>
    )

       
}






