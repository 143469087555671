import React, { Component } from 'react'
import OrderTD from './OrderTD'

export default class OrdersList extends Component {

    

    constructor(props) {

        super(props)
    
        this.state = {
            
            currentOrder : { name: this.props.currentOrder.name, order : this.props.currentOrder.order },

            targetDay :        { name: 'targetDay', order : 'ASC' },
            hospital :          { name: 'hospital', order : 'ASC' },
            hospitalDivision : { name: 'hospitalDivision', order : 'ASC' },
            hospitalshift :     { name: 'hospitalshift', order : 'ASC' },
            contactName :           { name: 'contactName', order : 'ASC' },
            //patientPhone :      { name: 'patientPhone', order : 'ASC' },
            orderStatus :       { name: 'orderStatus', order : 'ASC' },
            nurseName :  { name: 'nurseName', order : 'ASC' }

        }

    }

    sendOrderId(orderId) {

        this.props.editOrder(orderId);

    }

    changeOrder= (field) => {
        
        let order = '';

        if( field.order === 'DESC' ) {
            order = 'ASC';
        } else { order = 'DESC'; }

        let changeOrder = {
            name : field.name,
            order : order
        }

        this.setState({
            [field.name] : changeOrder,
            currentOrder : changeOrder
        }, () => {

            //console.log(this.state.currentOrder);
            this.props.changeOrder(this.state.currentOrder);

        });

    }
    

    render() {

        let menuTable = [
            { fieldName : 'targetDay', text : 'ת.משמרת' },
            { fieldName : 'hospitalshift', text : 'משמרת' },

            { fieldName : 'orderStatus', text : 'סטטוס הזמנה' },
            { fieldName : 'hospital', text : 'בית חולים' },
            { fieldName : 'hospitalDivision', text : 'מחלקה' },
            { fieldName : 'nurseName', text : 'עובד' }, 
            { fieldName : 'contactName', text : 'איש הקשר' },
            /* { fieldName : 'patientPhone', text : 'טלפון' } */
        ]

        let orders = this.props.orders;

        //console.log(this.props);

        return (
            <div>
                <div className="defaultTable">
                    <div className="tableTR titleRow">
                        
                        <div className='tableTD'>#</div>
                        {menuTable.map(item => { if (true) {
                            /* currentState={this.state} */
                            return  <div key={ item.fieldName } className={this.state.currentOrder.name === item.fieldName ? 'tableTD active' : 'tableTD' } ><button onClick={ () => this.changeOrder(this.state[item.fieldName]) } >{ item.text } <i className={`fas fa-chevron-${ this.state[item.fieldName].order === 'DESC' ? 'up' : 'down' }`}></i></button></div> 
                        } else return false}) }
                        
                    </div>
                        
                    {   orders.map(item => { if (true) {
                        /* currentState={this.state} */
                        return  <OrderTD   key={ item.id } item={ item }   sendOrderId = { (orderId) => this.sendOrderId(orderId) } />
                    } else return false}) } 
                    
                </div>
            </div>
        )
    }
}
