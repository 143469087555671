import React, {useState} from 'react';
import OrderInvoiceForm from './OrderInvoiceForm'
import btnFileDown from './../../../img/nathan/pdfDownFile/btnFileDown.png'
import {RestUrls} from '../../api/config'

export default function OrderInvoiceIndex(props) {

    const {orderData, payment} = props;
    const [isInvoice, setIsInvoice] = useState(payment.pdfTranzilaFile);
    const md5 = require('md5'); //npm install md5

    const token = md5(orderData.id + '|' + orderData.created_at);

  return <div className='OrderInvoiceIndex'>

    {!isInvoice ? < OrderInvoiceForm orderData = {orderData} payment = {payment} setIsInvoice = {setIsInvoice} /> : 
    <div className='downloadPdf animate__animated animate__bounceIn'>

        <a href={RestUrls.appUrl + '/api/pay/getInvoice/?token=' + token + '&orderId=' + orderData.id} target="_blank" rel="noopener noreferrer">
          <img src={btnFileDown} alt='להורדת חשבונית'/>
        </a>
      
      </div>}

  </div>;
}
