export const cancelOrderOptions = [
    
    {
        id : '1',
        text: 'מוקד - לא סופק'
    },

    {
        id : '2',
        text: 'מוקד - טעות'
    },

    {
        id : '3',
        text: 'בי"ח - אין צורך בשמירה'
    },

    {
        id : '4',
        text: 'בי"ח - טעות בהזמנה'
    }

]