import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //npm install react-router-dom
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import SiteConnector  from './SiteConnector';
import {getAllUrlParams} from "./../../Components/models/UrlParameters";

export default class getPage extends Component {
  
  render() {

    let urlParams = getAllUrlParams(window.location.search);

    if ( localStorage.getItem('userData') && localStorage.getItem('userData').length > 0 )  {

      let user = JSON.parse(localStorage.getItem('userData'));

      //console.log(user);

      if( user?.userCredential && (parseInt(user.userCredential) > 0 ) ) {
      
        return <React.Fragment>

            <Router>

              <Routes>

                  <Route path="/logout" element={ <SiteConnector search={ urlParams } router="logout"  page="" isMobile = {isMobile ? true : false} />} />
                  
                  {(user.hospital && user.hospital.length === 0) && <>
                    {/* Send Router Value by Environment - open pages on UserIndex */}
                    
                    <Route path="/confirm" element={ <SiteConnector search={ urlParams } router="router2"  page="confirm" isMobile = {isMobile ? true : false} />} />
                    <Route path="/money" element={ <SiteConnector search={ urlParams } router="userPages" page="money" isMobile = {isMobile ? true : false} />} />
                    <Route path="/config" element={ <SiteConnector search={ urlParams } router="userPages" page="config" isMobile = {isMobile ? true : false} />} />
                    <Route path="/workers" element={ <SiteConnector search={ urlParams } router="userPages" page="workers" isMobile = {isMobile ? true : false} />} />
                    <Route path="/worker" element={ <SiteConnector search={ urlParams } router="userPages" page="worker" isMobile = {isMobile ? true : false} />} />
                  </> }

                  <Route path="/order" element={ <SiteConnector search={ urlParams } router="userPages" page="order" isMobile = {isMobile ? true : false} />} />
                  <Route path="*" element={ <SiteConnector search={ urlParams } router="userPages" page="orders" isMobile = {isMobile ? true : false} />} />
                  
              </Routes>
            </Router>
            
        </React.Fragment> }

        else { return <React.Fragment>

            <Router>

              <Routes>

                <Route path="/confirm" element={ <SiteConnector search={ urlParams } router="router2"  page="confirm" isMobile = {isMobile ? true : false} />} />

                <Route path="/logout" element={ <SiteConnector search={ urlParams } router="logout"  page="" isMobile = {isMobile ? true : false} />} />
                
                <Route path="/archive" element={ <SiteConnector search={ urlParams } router="router2" page="archive" isMobile = {isMobile ? true : false} />} />
                <Route path="/config" element={ <SiteConnector search={ urlParams } router="router2" page="config" isMobile = {isMobile ? true : false} />} />

                <Route path="*" element={ <SiteConnector search={ urlParams } router="router2" page="index" isMobile = {isMobile ? true : false} />} />
              </Routes>
              
            </Router>
            
            </React.Fragment>
        }

    }

    /* else if (this.props.location.search !== '?logout=1') {

      return <React.Fragment>

            <Router>

              <Switch>

                  <Route path="/register" component={() => <SiteConnector  page="register" isMobile = {isMobile ? true : false} />} />
                  <Route path="/login" component={() => <SiteConnector  page="login" isMobile = {isMobile ? true : false} />} />
                  <Route path="/reSendPass" component={() => <SiteConnector  page="reSendPass" isMobile = {isMobile ? true : false} />} />

                  <Route path="/sherutPlaces" component={() => <SiteConnector search={ this.props.location.search } page="mekomotSherutPages" isMobile = {isMobile ? true : false} />} />

                  <Route path="/" component={() => <SiteConnector  page="login" isMobile = {isMobile ? true : false} />} />


              </Switch>
            </Router>
            
      </React.Fragment>

    } */

    
  }


}
