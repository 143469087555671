import React, {useState} from 'react';
import { Table, Form } from 'react-bootstrap';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

import validator from "validator";

export default function SystemHospitalsShowUsers(props) {

    const {users, setResponseHospitals} = props;
    const [loading, setLoading] = useState(false)

    return <div className='SystemHospitalsShowUsers'>
        <h2>משתמשים קיימים</h2>
        <img src={loader} alt="loader" className={!loading ? 'loader' : 'loader active animate__animated animate__fadeIn'} />

        <div className='tableHospital'>
            <Table striped >
                <thead>
                    <tr>
                        <th>שם מלא</th>
                        <th>שם משתמש</th>
                        <th>בית חולים</th>
                        <th>טלפון נייד להתראות</th>
                        <th>איפוס סיסמה</th>
                        <th>מחיקה</th>
                    </tr>
                </thead>

                {users[0]?.id ? <tbody>
                    {users.map((item) => 

                        <Item item ={item} key={item.id} loading = { loading } setLoading= {setLoading} setResponseHospitals = { setResponseHospitals } />

                    )}
                </tbody>  : <tbody>
                    <tr><td colSpan={4} className='noResults'>אין תוצאות</td></tr>
                </tbody> }
            </Table>
        </div>
        

  </div>;
}

function Item(props) {

    const { item,  loading, setLoading, setResponseHospitals } = props;

    

    const [newPassword, setNewPassword] = useState(false);
    const [phone, setPhone] = useState(item.phone ? {phone: item.phone, id: item.id} : {phone: '', id: item.id} );
    const [showPhone, setShowPhone] = useState(false);
    

    const editHospital = (item) => {

      if(!loading && item.id) {
            deleteUpdateUser('deleteHospitalUser', 'users',{ id: item.id }, setLoading, setResponseHospitals);
      }

    };

    const changePass = () => {

        if(!newPassword.pass) {
            toast.error('נא לרשום סיסמה');
        } 
        else if(!validator.isLength(newPassword.pass,4)) {
            toast.error('נא לרשום סיסמה מעל 4 תווים');
        } 

        else if(!validator.isAlphanumeric(newPassword.pass)) {
            toast.error('נא לרשום סיסמה עם מספרים או אותיות באנגלית');
        } 

        else {
            if(!loading && newPassword.id) {
                deleteUpdateUser('updateHospitalPasswordUser', 'users',newPassword, setLoading, setResponseHospitals);
            }
            //toast.success('OK');
        }
        
        //console.log(newPassword);

    };

    const changePhone = (e) => { 
        
        setShowPhone(true);
        setPhone({ phone: e.target.value, id: item.id })
        
    }

    const changePhoneApi = () => { 

        deleteUpdateUser('updateHospitalPhoneUser', 'users', phone, setLoading, setResponseHospitals);
        console.log('phone > ',phone);

     }

    //console.log(phone);

    return(
        <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.username}</td>
            <td>{item.hospital}</td>
            <td>
                <Form.Control
                    className="customFloatInput"
                    placeholder='טלפון נייד להתראות'
                    type="text"
                    value={phone.phone}
                    onChange={e => changePhone(e) }
                />

                { showPhone && <button className='changePass animate__animated animate__bounceIn' onClick={ changePhoneApi }>
                    <span>החלף טלפון</span>
                </button>}

            </td>
            <td>
                <Form.Control
                    className="customFloatInput"
                    placeholder='סיסמה חדשה'
                    //isInvalid={ (e) => checkNewPass(item.id , e.target.value, showErrors) }
                    /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                    type="text"
                    onChange={e => setNewPassword({ pass: e.target.value, id: item.id })}
                />

                { (newPassword && ( newPassword.id === item.id && newPassword.pass ) ) && <button className='changePass animate__animated animate__bounceIn' onClick={()=>changePass()}>
                    <span>החלפת סיסמה</span>
                </button>}

            </td>
            <td className='lastTd'>
                <button className='editBtnHospital' onClick={()=>editHospital(item)}>
                    <i className="far fa-trash-alt"></i>
                </button>
            </td>
        </tr>
    )


}


function deleteUpdateUser(url, controller, objectToSend, setLoading, setResponseHospitals) {
    
    //console.log(objectToSend);

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        //console.log(getData);

        if (getData.ok) {
            
            toast.success(getData.ok);
            setResponseHospitals(false);

        } else if (getData.error) {
            toast.error(getData.error)
            setLoading(false)
        }
    })

    getData.catch((error) => {
        console.log(error)
        setLoading(false)
    })

   
}

