import React, {useEffect, useState } from 'react'
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

//import { Arr } from 'array-helpers'; //npm i array-helpers //https://www.npmjs.com/package/array-helpers

export default function SystemEditHospitalInputs(props) {

    const {hospitalPrices} = props;
    let prices = getPrices(hospitalPrices);

    const [formData, setFormData] = useState(defaultValueForm(prices))
    


    useEffect(() => {


        //console.log(formData);
        props.changePrices(formData);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    //console.log(pricesShiftsArr);
    //console.log(prices);



    const handleChange = (e) => {
        
        
        setFormData({
        
            ...formData,

            [e.target.name]: e.target.value,

        })
        

    }
    

    return (
        <div className='hospitalEditInputs'>

            <FloatingLabel label='שעת כניסה' className={!formData.timeStart || formData.timeStart === '00:00' ? 'empty' : ''}> 

                <Form.Control
                    className={"timeInput"}
                    //isInvalid={this.state.isError}
                    /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                    type="time"
                    name="timeStart"
                    onChange={handleChange}
                    value={formData.timeStart}
                />

            </FloatingLabel>

            <FloatingLabel label='שעת יציאה' className={!formData.timeEnd || formData.timeEnd === '00:00' ? 'empty' : ''}> 

                <Form.Control
                    className={"timeInput"}
                    //isInvalid={this.state.isError}
                    /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                    type="time"
                    name="timeEnd"
                    onChange={handleChange}
                    value={formData.timeEnd}
                />

            </FloatingLabel>

            <FloatingLabel label='תעריף חיוב' className={!formData.price ? 'empty' : ''}> 

                <Form.Control
                  className={"price"}
                  //isInvalid={this.state.isError}
                  /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                  type="number"
                  name="price"
                  onChange={handleChange}
                  value={formData.price}
              />

            </FloatingLabel>
        </div>
    )


}

function defaultValueForm(prices) {

    return {
        timeStart: prices.timeStart,
        timeEnd: prices.timeEnd,
        price: prices.price,
      }
    
}

function getPrices(hospitalPrices) {

    //const mainArray = new Arr(hospitalPrices);
    //const pricesShiftsArr = mainArray.multifilter('hospitalShiftId',hospitalShiftId);


    //console.log(hospitalPrices);

    let prices =  hospitalPrices;


    if(!prices) {

        prices = {
            timeStart: '00:00',
            timeEnd: '00:00',
            price: ''
        }

    }

    return prices;
    
}
