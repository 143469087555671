import React, {useState} from 'react'

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi';
import loader from './../../../img/preLoader.gif'
import { map,isEmpty } from "lodash" //https://lodash.com/docs/4.17.15#map  //find
import { Button } from 'react-bootstrap';
import { Form, FloatingLabel  } from 'react-bootstrap';

export default function UnificateOrders(props) {


    const {orderData, reloadData} = props

    const [loading, setLoading] = useState(false)
    const [responseData, setResponseData] = useState(false);

    const [noSendOrdersSMS, setNoSendOrdersSMS] = useState(false);
    
    const [formData, setFormData] = useState({
        smsPhone : '',
        smsText : ''
    })

    //console.log(orderData);
    
    if(!loading && !responseData && orderData.is_prati === '1') {

        const send = {
                patientTz : orderData.patientTz,
                targetDay :  orderData.targetDay
            };

        sendtoAdmin('get4unificateOrders','orders', send,  setResponseData, setLoading);

    }

    //console.log(responseData);

    if(orderData.is_prati !== '1') {return false;}
    if(responseData && isEmpty(responseData?.orders) ) {return false;}

    const notUnificateMe = (orderData) => { 

        const send = {
            orderId: orderData.id,
            changeTo:orderData.is_uniquePrati === '1' ? '0' : '1'
          };

        //console.log(item);
        
        sendtoApi('notUnificateMe', 'orders', send, setLoading, reloadData );

    }

    const doSubmit = () => {

        const send = {
            orderId: orderData.id,
            smsPhone: formData.smsPhone,
            smsText: formData.smsText,
            noSendOrdersSMS: noSendOrdersSMS
        };

        //console.log(item);
        
        sendtoApi('sendSMSUnifacateOrders', 'orders', send, setLoading );

        //console.log(send);

    }

    const doSubmitMail = () => { 

        const send = {
            orderId: orderData.id
        };

        //console.log(item);
        
        sendtoApi('sendMAILUnifacateOrders', 'orders', send, setLoading );

     }


    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }

  return (
    <div className='unificateOrders'>   
        <img src={loader} alt="loader" className={!loading ? 'loader' : 'loader active animate__animated animate__fadeIn'} />
        <h2>ריכוז הזמנות</h2>

        <div style={{margin : '0px 0 20px 0'}}>
            <Button type="button" onClick={doSubmitMail} variant='secondary' >
                <span>שליחת משמרות במייל</span>
            </Button>
        </div>


        <ul>
            {map(responseData.orders?.allOrders, item => {
              
                

              return(<li key={item.id} className={item.is_uniquePrati === '1' ? 'uniquePrati' : ''}>
                    
                    
                    <Button  onClick={ ()=> notUnificateMe(item) } variant='secondary' size="sm"  >
                        {/* {loading ? <Spinner size="" animation="border" variant="light" /> : <span>x</span> } */}
                        <span>x</span>
                    </Button>

                    <span>(#{item.id}) </span>
                    <strong className='dateOrder'>{item.targetDayShow} </strong>
                    <span>משמרת: {item.hospitalShift} </span>
                    {item.paidData ? <>
                        <span>מ-{item.paidData.nurseStart} ל-{item.paidData.nurseStop}</span>
                        <span> | סה"כ: </span> <small>₪</small>{item.paidData.resultPrice}
                        {item.ordersMoneyshift ? <span> | תשלום לקוח: <strong><small>₪</small>{item.ordersMoneyshift}</strong></span> : <span style={{color: 'red'}}> אין מידע</span>}
                    </> : <span style={{color: 'red'}}>אין מידע על תשלום המשמרת</span>}
                </li>)
            })}
            {responseData?.orders?.sum && <li>סה"כ: <strong><small>₪</small>{responseData.orders.sum}</strong></li> }
        </ul>

        

        <div className='sendSms'>

            <div className='input text'>
                <FloatingLabel label='טלפון נייד לשליחת SMS' >


                    <Form.Control
                        className="customFloatInput"
                        placeholder= 'טלפון נייד לשליחת SMS' 
                        /* isInvalid={ !formData.smsPhone } */
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        /* disabled = { orderData.hospitalId !== '8' ? true : false } */
                        type="text"
                        name="smsPhone"
                        value={formData.smsPhone}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <div className='input text' style={{width: '440px'}}>
                <FloatingLabel label='מלל חופשי' >


                    <Form.Control
                        className="customFloatInput"
                        placeholder= 'מלל חופשי' 
                        /* isInvalid={ !formData.smsPhone } */
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        /* disabled = { orderData.hospitalId !== '8' ? true : false } */
                        type="text"
                        name="smsText"
                        value={formData.smsText}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <button type="button" disabled={!formData.smsPhone} className={`defaultBtnPic noPic ${!formData.smsPhone ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} >
                <span>שליחת SMS</span>
            </button>
        </div>

        <div className="line checkboxes">
            <Form.Group
            className="checkBoxCont"
            style={{width : '100%'}}
            controlId="formBasicCheckbox1"
            >
                <Form.Check.Input
                    type={"checkbox"}
                    checked={noSendOrdersSMS}
                    onChange={() => {
                        setNoSendOrdersSMS(!noSendOrdersSMS)
                    }}
                />

                <Form.Check.Label >
                    <span>לא לצרף הזמנות בהודעת SMS</span>
                </Form.Check.Label>
            </Form.Group>
        </div>

        

    </div>
  )
}


function sendtoApi(url, controller, objectToSend, setLoading, reloadData = false ) {
  
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {
      if (getData.ok) {
  
        
        toast.success(getData.ok);

        if(reloadData) {
            reloadData();
        }
  
      } else if (getData.error) {
        toast.warning(getData.error)
      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }
