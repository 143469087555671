import React, { Component } from 'react'
import { Form } from "react-bootstrap";
import CustomFloatInput from "../../models/forms/CustomFloatInput";
import getDataFromApi from "../../api/getDataFromApi";
import { toast } from 'react-toastify' //npm i react-toastify
import loader from "./../../../img/preLoader.gif";
import Switch from '@mui/material/Switch';

import WorkerEditHospitalsLinks from './WorkerEditHospitalsLinks';
import ShowDatePicker from './Money/ShowDatePicker';

export default class WorkerEditform extends Component {


    constructor(props) {

        super(props);

        //console.log(this.props);
    
        let nurseData = this.props.nurseData ? this.props.nurseData : false;
    
        this.state = {
    
          is_newNurse : this.props.is_newNurse ? this.props.is_newNurse : false,
    
          nurseData: nurseData,
    
          name: "",
          tzNurse: "",
          phone: "",
          email: "",

          startDate: '',
          endDate: '',

          showStartDate: true,
          showEndDate: true,

    
          priorityId: "",
          districtId: "",
    
          //from function sendtoAdmin
    
          hospitalId: "",
          hospitalDivisionId: "",
          address: "",
          comments: "",
    
          accountNumber: "",
          bank: "",
          bankBranch: "",

          is_emergencyService: "0",

          password : '',
          sendSms : false,
    
          loading: false,
    
          responseFieldsAndNurseMoreData: false,
          updatedNurseData: false,
    
          responseUpdateNurse: false,
          responseNewNurse: false,
          responseSendSmsToUser: false,
          loadWorkerSchedule: false,
          loadWorkerShifts: false,
    
          checkInputs: false,
          btnSendClass: ""
    
        };
      }

      sendForm = (sendRules) => {
        this.setState({
          checkInputs: true,
        });
    
        if (sendRules) {
          const sendObj = {
            name: this.state.name,
            tzNurse: this.state.tzNurse,
            phone: this.state.phone,
            email: this.state.email,

            startDate: this.state.startDate,
            endDate: this.state.endDate,

            priorityId: this.state.priorityId,
            districtId: this.state.districtId,
            hospitalId: this.state.hospitalId,
            hospitalDivisionId: this.state.hospitalDivisionId,
            address: this.state.address,
            comments: this.state.comments,
            accountNumber: this.state.accountNumber,
            bank: this.state.bank,
            bankBranch: this.state.bankBranch,
            sendSms: this.state.sendSms,
            password: this.state.password.length >= 4 ? this.state.password : '',
            nurseId: this.state.nurseData.id ? this.state.nurseData.id : "",
            is_emergencyService: this.state.nurseData.id ? this.state.is_emergencyService : ""
          };
    
          if (this.state.nurseData) {

            //console.log('sendObj: ', sendObj);
            this.sendtoAdmin("updateNurse","Nurses",sendObj,"responseUpdateNurse");
            
          } else {
    
            this.sendtoAdmin('insertNurse','Nurses',sendObj,'responseNewNurse');
    
          }
        }
      };
    
      getFields = () => {
    
        let sendObj = {
          nurseId: this.props.nurseData ? this.props.nurseData.id : false,
        };
    
        this.sendtoAdmin("fieldsAndNurseMoreData","app",sendObj,"responseFieldsAndNurseMoreData");
      };
    
      componentDidMount() {
        this.getFields();
      }
    
      
    
      sendSmsToWorker = () => {
        
        console.log('sendSms');
    
        let sendObj = {
    
          nurseId: this.state.nurseData.id ? this.state.nurseData.id : ""
        }
    
        this.sendtoAdmin('sendSmsToUser','Nurses',sendObj,'responseSendSmsToUser');
      }



      updateValue = (newValue) => {
        this.setState(newValue, () => {
          //console.log(this.state);
        });
      };
    
      changeEmergency = () => {
        
        if(this.state.is_emergencyService === '0' || !this.state.is_emergencyService) {
          this.setState({ is_emergencyService : '1' })
        } else {
          this.setState({ is_emergencyService : '0' })
        }
          


      };

      hadleDatePeriod = (date, action) => { 

        if(action === 'start') {
          
          this.setState({ startDate : date});

        } else if(action === 'end') {
          
          this.setState({ endDate : date});

        }

        //console.log('date', date);
        //console.log(action);

      }



    render() {


      const isConfigNurse = this.props.configNurse ? true : false;

        //console.log(this.props);

      let selectOptionsDb = this.state.responseFieldsAndNurseMoreData ? this.state.responseFieldsAndNurseMoreData : '';
      const sendRules = this.state.name && this.state.phone && this.state.tzNurse;

      //console.log('data', this.state?.responseFieldsAndNurseMoreData);
      //console.log('this state', this.state);

        return (
            <div className="NurseDataCont">

                {this.state.responseFieldsAndNurseMoreData ? <div>

                    <img src={loader}  alt="loader" className={!this.state.loading ? "loader": "loader active animate__animated animate__fadeIn" } />

                    {this.state.nurseData && !isConfigNurse ? <div className="headerEditWorkers">
                        <button className="sendSmsBtn" onClick={ this.sendSmsToWorker } >שליחת פרטי משתמש בSMS לעובד&raquo;</button>
                    </div>: false }

                    {(this.state.responseFieldsAndNurseMoreData.nurseAllData && this.state.updatedNurseData ) ||
                        this.state.responseNewNurse ||
                        !this.state.nurseData ? (
                        <div className="inputs">
                        <h3>פרטי העובד {!this.props.is_newNurse && <div className='username'> - שם משתמש: <small>{this.state.responseFieldsAndNurseMoreData.nurseAllData.username}</small></div>} </h3>
                        <div className="clear line section1">
                            <CustomFloatInput
                            name="name"
                            updateValue={this.updateValue}
                            value={this.state.name}
                            placeholder="שם מלא"
                            cssClass=""
                            validationRules={{ required: true }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            />
                            <CustomFloatInput
                            name="tzNurse"
                            updateValue={this.updateValue}
                            value={this.state.tzNurse}
                            placeholder="תעודת זהות"
                            cssClass=""
                            validationRules={{ required: true, tz: true }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            />
                            <CustomFloatInput
                            name="phone"
                            updateValue={this.updateValue}
                            value={this.state.phone}
                            placeholder="נייד"
                            cssClass="numberClass"
                            validationRules={{ required: true, numbers: true, minlength : 10 }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            />
                            <CustomFloatInput
                            name="email"
                            updateValue={this.updateValue}
                            value={this.state.email}
                            placeholder="מייל"
                            cssClass="numberClass"
                            validationRules={{ required: false, email : true }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            />
                            { !isConfigNurse ? <CustomFloatInput
                            name="priorityId"
                            disabled={false}
                            selectOptions={selectOptionsDb.workerPriorities}
                            updateValue={this.updateValue}
                            value={this.state.priorityId}
                            placeholder="הגדרת תעדוף..."
                            cssClass=""
                            validationRules={{ required: false }}
                            typeInput="select"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            /> : false }
                        </div>

                        <div className={`clear line section2 ${!this.state.is_newNurse ? 'widthPass' : ''}`}>
                            <CustomFloatInput
                            name="address"
                            updateValue={this.updateValue}
                            value={this.state.address}
                            placeholder="כתובת"
                            cssClass=""
                            validationRules={{ required: false }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            /> 

                            {!this.state.is_newNurse ? 
                            <CustomFloatInput
                            name="password"
                            updateValue={this.updateValue}
                            value={this.state.password}
                            placeholder="יצירת סיסמה חדשה"
                            cssClass="numberClass"
                            validationRules={{ required: false, minlength: 4 }}
                            typeInput="text"
                            checkInputs={this.state.checkInputs}
                            checked={() => this.setState({ checkInputs: false })}
                            />  : false }
                        </div>

                        {!this.state.is_newNurse && <div className={`clear line section2 ${!this.state.is_newNurse ? 'widthPass' : ''}`}>

                              <div className='datesCont'>

                                {this.state.showStartDate ? <div className='dateItem'>

                                  <h4>תאריך התחלה:</h4>
                                  <ShowDatePicker hadleDateRetro = { (date)=>this.hadleDatePeriod(date,'start') } dateProp = {this.state?.startDate ? new Date(this.state?.startDate) : ''} />
                                  <button onClick={()=> this.setState({startDate : '', showStartDate : false })}>X</button>
                                </div> : <span onClick={()=> this.setState({showStartDate : true })} >הוספת תאריך התחלה</span>}

                                {this.state.showEndDate ? <div className='dateItem'>

                                  <h4>תאריך סיום:</h4>
                                  <ShowDatePicker hadleDateRetro = { (date)=>this.hadleDatePeriod(date,'end') } dateProp = {this.state?.endDate ? new Date(this.state?.endDate) : ''} />
                                  <button onClick={()=> this.setState({endDate : '',showEndDate : false})}>X</button>
                                </div> : <span onClick={()=> this.setState({showEndDate : true })} >הוספת תאריך סיום</span> }

                              </div>

                        </div>}

                       

                        {!isConfigNurse ? <div>

                          {!this.state.is_newNurse && <>
                            <h3 style={{ marginTop: "30px" }}>שיבוץ בתי חולים</h3>
                            <WorkerEditHospitalsLinks nurseData = {this.state.nurseData} selectOptionsDb = {selectOptionsDb} />
                          </> }

                          <div className="clear line section3">
                              {/* <CustomFloatInput
                              name="hospitalId"
                              disabled={false}
                              selectOptions={selectOptionsDb.hospitals}
                              updateValue={this.updateValue}
                              value={this.state.hospitalId}
                              placeholder="עדיפות לבית חולים..."
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="select"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              />
                              <CustomFloatInput
                              name="hospitalDivisionId"
                              disabled={false}
                              selectOptions={selectOptionsDb.hospitalDivisions}
                              updateValue={this.updateValue}
                              value={this.state.hospitalDivisionId}
                              placeholder="עדיפות למחלקה..."
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="select"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              /> */}
                              
                          
                              <CustomFloatInput
                              name="comments"
                              updateValue={this.updateValue}
                              value={this.state.comments}
                              placeholder="הערות"
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="text"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              />
                          </div>

                          <h3 style={{ marginTop: "30px" }}>פרטי בנק/מחוז</h3>

                          <div className="clear line section5">
                            <CustomFloatInput
                                name="districtId"
                                disabled={false}
                                selectOptions={selectOptionsDb.districts}
                                updateValue={this.updateValue}
                                value={this.state.districtId}
                                placeholder="עדיפות למחוז..."
                                cssClass=""
                                validationRules={{ required: false }}
                                typeInput="select"
                                checkInputs={this.state.checkInputs}
                                checked={() => this.setState({ checkInputs: false })}
                                />
                              <CustomFloatInput
                              name="accountNumber"
                              updateValue={this.updateValue}
                              value={this.state.accountNumber}
                              placeholder="מספר חשבון"
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="text"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              />

                              <CustomFloatInput
                              name="bank"
                              updateValue={this.updateValue}
                              value={this.state.bank}
                              placeholder="בנק"
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="text"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              />

                              <CustomFloatInput
                              name="bankBranch"
                              updateValue={this.updateValue}
                              value={this.state.bankBranch}
                              placeholder="סניף"
                              cssClass=""
                              validationRules={{ required: false }}
                              typeInput="text"
                              checkInputs={this.state.checkInputs}
                              checked={() => this.setState({ checkInputs: false })}
                              />
                          </div>

                          {!this.state.is_newNurse && <div className="line emergency" style={{margin: '20px 0'}}>
                            

                                  <span>העובד/ת זמין/ה עכשיו</span>
                                  <Switch
                                          checked={this.state.is_emergencyService === '1' ? true : false}
                                          onChange={ this.changeEmergency }
                                          name="is_emergencyService"
                                          color="primary"
                                      />
                            
                            </div>}

                          {this.state.is_newNurse ? <div className="line checkboxes">
                          
                              <Form.Group className="checkBoxCont" controlId="formBasicCheckbox">
                              {/* isInvalid={!this.state.takanon} */}
                              <Form.Check.Input
                                  type={"checkbox"}
                                  checked={this.state.sendSms}
                                  onChange={() => {
                                  this.setState({ sendSms: !this.state.sendSms });
                                  }}
                              />

                              <Form.Check.Label>
                                  <span>שליחת פרטי משתמש בSMS לעובד</span>
                              </Form.Check.Label>
                              </Form.Group>

                          </div> : false }

                        </div> :  false } {/* NO CONFIG */}

                        </div>
                    ) : (
                        false
                    )}

                    <div className="downBtn">
                        <button
                        
                        disabled={ this.state.loading ? this.state.loading : false }

                        className={
                            sendRules
                            ? "defaultBtnPic noPic animate__animated animate__bounceIn"
                            : "defaultBtnPic noPic disabledBtn"
                        }
                        onClick={() => this.sendForm(sendRules)}
                        >
                        {this.state.is_newNurse ? (
                            <span>הוספת עובד</span>
                        ) : (
                            <span>עידכון שינוים</span>
                        )}
                        </button>
                    </div>
                </div> : false } {/* Loader */}
    </div>
        )
    }



    sendtoAdmin = (url,controller,objectToSend,setStateName = "data",auth = "all") => {
        //console.log('sending...');
    
        this.setState({ loading: true });
    
        const getData = getDataFromApi(url, objectToSend, controller, auth);
    
        getData.then((getData) =>
          this.setState({ [setStateName]: getData, loading: false }, function () {
            //console.log(getData);
    
            if ( url === 'fieldsAndNurseMoreData' && getData.nurseAllData ) {
    
              let newData = getData.nurseAllData;
              
              newData.updatedNurseData =  true;
    
              this.setState(newData,
                () => {
                  //console.log(this.state);
                }
              );
    
          } else if (url === "updateNurse" ) {
    
            if(getData.error) {
              toast.error(getData.error);
            } else {
              toast.success(getData.ok);
            }
            
            
          } else if( url === 'insertNurse' ) {
    
              if(getData.ok) {
    
                toast.success('נוסף בהצלחה');
                
                this.setState({
    
                  nurseData : { id : getData.ok },
                  is_newNurse : false
    
    
                }, ()=> {
                  this.getFields();
                })
    
              } else {
    
                toast.error(getData.error);
                
              }
            }  else if( url === 'sendSmsToUser' ) {
    
              if(getData.ok) {
    
                toast.success(getData.ok);
    
              } else {
    
                toast.error(getData.error);
    
              }
              
            }
    
    
          })
        );
      };
}
