import React, { Component } from 'react'
import UserIndex from '../pages/UserEnv/UserIndex'

import NavRight from '../pages/UserEnv/NavLink';

export default class UserViewComposer extends Component {

    render() {

        if(this.props.isMobile) {

            return (<div className="body-container UserConsole Mobile">
                {/* <NavMobile  {...this.props} changeSayarot={this.state.changeSayarot} /> */}
                < UserIndex {...this.props} />
    
            </div>)
    
        }
    
        else {
    
            return (<div className="body-container SystemPages Desktop" >
    
                <div className="content-wrapper twoColumns">

                    <NavRight {...this.props}  />

                    <div className="mainContent">
                        < UserIndex {...this.props} />
                    </div>

                </div>
                    
            </div>)
        
        }
    }
}
