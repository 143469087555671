import React, { Component } from "react";
import CustomFloatInput from "../../models/forms/CustomFloatInput";

import getDataFromApi from "../../api/getDataFromApi";
import loader from "./../../../img/preLoader.gif";

import { Form } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import { toast } from 'react-toastify' //npm i react-toastify

import SingleOrderHospitalSelection from './SingleOrderHospitalSelection'
import ShowDayPickerRetro from "./ShowDayPickerRetro";
import { times } from "lodash";

export default class SingleOrderAcordOrderDetails extends Component {
  constructor(props) {
    super(props);

    let user = JSON.parse(localStorage.getItem('userData'));

    let today = new Date();
    let current_datetime = new Date(today.toString().replace(/-/g, "/")); // IPHONE FIX

    //console.log(current_datetime.getHours());

    let orderData =
      this.props.orderData && this.props.orderData.order
        ? this.props.orderData.order
        : false;

    let referrerId = orderData ? orderData.referrerId : "10";

    if (!orderData && (user.hospital && user.hospital.length !== 0)) {

      referrerId = '26';

    }

    this.state = {
      response: false,
      responseNewOrder: false,
      responseUpdateOrder: false,
      orderData: this.props.orderData ? this.props.orderData : false,

      loading: false,
      checkInputs: false,
      hospitalCheckInputs: false,

      isPrati: orderData && orderData.is_prati === '1' ?  true : false, //FOR SHOW HIDE METUPAL - TASHLUM

      
      isHoliday: orderData && orderData.is_holiday === '1' ?  true : false,

      isRetroOrder: false,
      cancelRetro: false,

      hospitalShiftText :  orderData ? orderData.hospitalShiftText : "",

      hospital: orderData ? orderData.hospitalWithDivision : "",
      referrer: referrerId,
      targetDay: orderData ? orderData.targetDay_edit : "",
      
      targetDayName: orderData ? orderData.hebrewNameDay : "",

      hospitalshiftId: orderData ? orderData.hospitalshiftId : "",


      emergencyFrom: orderData
        ? orderData.emergencyFrom
        : current_datetime.getHours() + ":00",
      emergencyTo: orderData
        ? orderData.emergencyTo
        : current_datetime.getHours() + ":00",

      patientName: orderData ? orderData.patientName : "",
      patientPhone: orderData ? orderData.patientPhone : "",
      patientTz: orderData ? orderData.patientTz : "",

      patientHabas: orderData ? orderData.patientTz : false,
      patientOz: orderData ? orderData.patientOz : false,
      patientBidud: orderData ? orderData.patientBidud : false,

      contactName: orderData ? orderData.contactName : "",
      contactPhone: orderData ? orderData.contactPhone : "",
      contactAddress: orderData ? orderData.contactAddress : "",

      comments: orderData ? orderData?.comments : "",

      orderCopies : 1
    };
  }

  getFields = () => {
    let sendObj = {};
    this.sendtoAdmin("orderFields", "app", sendObj, "response");
  };

  componentDidMount() {
    this.getFields();
  }

  updateValue = (newValue) => {
    this.setState(newValue, () => {
      //console.log(this.state);
    });
  };

  sendForm = (sendRules) => {
    this.setState({
      checkInputs: true,
      hospitalCheckInputs: true,
    });

    if (sendRules) {
      const sendObj = this.state;
      //delete sendObj.response;
      //console.log(sendObj);

      sendObj.is_holiday = this.state.isHoliday ? '1' : '0';

      if (this.state.orderData) {
        sendObj.orderId = this.state.orderData.order.id;

        this.sendtoAdmin(
          "updateOrder",
          "orders",
          sendObj,
          "responseUpdateOrder"
        );
        this.props.reloadOrders();
      } else {

        sendObj.is_retroOrder = this.state.isRetroOrder ? '1' : '0';        

        //console.log(sendObj);
        this.sendtoAdmin("insertOrder", "orders", sendObj, "responseNewOrder");
      }
    }
  };

  changeTargetDay = (item) => {

    //console.log(this.state.targetDay);

    this.setState({
      targetDay: item.dayDate,
    });

  };

  hadleDateRetro = (date,dayName) => {

    //console.log(date);
    if(this.state.targetDay !== date) {

      this.setState({targetDay: date, targetDayName: dayName, isRetroOrder: true});

    }
    

  }

  handleCancelRetro = () => {

    this.setState({cancelRetro:true}, () => {

        this.setState({ isRetroOrder : false, targetDay : '', targetDayName: '' }) 

    })

  }

  changeHospital = (value,hospitalId, is_OrderPrati = false) => {

    //console.log(value);

    if(this.state.hospital !== value) {

        const isPrati = is_OrderPrati;

        this.setState({ hospital : value, isPrati : isPrati });

    }
    

  };
  

  render() {

    //console.log(this.state.response);

    const sendRules =
      this.state.hospital &&
      this.state.targetDay &&
      this.state.hospitalshiftId &&
      /* this.state.patientName &&
      this.state.patientPhone &&
      this.state.patientTz && */
      this.state.contactName; 
      //&& this.state.contactPhone;

    //let user = JSON.parse(localStorage.getItem('userData'));
    //let openOrderUser = user.name;

    let flagEditOrder = true;
    let orderData =
      this.state.orderData && this.state.orderData.order
        ? this.state.orderData.order
        : false;


    let flagSpecialDays = false;

    if(this.state.hospital.includes("1|")) {

        if( this.state.targetDayName === 'שישי' || this.state.targetDayName === 'שבת'  ) {
            flagSpecialDays = true;
        } 

    } else {

      flagSpecialDays = false;

    }

    let user = JSON.parse(localStorage.getItem('userData'));

    return (
      <div className={orderData ? "editHazmana" : ""}>
        <img
          src={loader}
          alt="loader"
          className={
            !this.state.loading
              ? "loader"
              : "loader active animate__animated animate__fadeIn"
          }
        />

        {this.state.response && flagEditOrder ? (
          <div>
            {/* {console.log(orderData)} */}

            <div className="acordCont colorDown">
              <h2>פרטי בית החולים</h2>

              <div className="inputs">

                <div className={`smallInputs clear line orderDetails ${(this.state.checkInputs && !this.state.hospital) ? 'errors' : '1'}`}>
                  
                  <SingleOrderHospitalSelection changeHospital = { (value,hospitalId,is_OrderPrati) => this.changeHospital(value,hospitalId,is_OrderPrati) } showErrorsP = {this.state.hospitalCheckInputs} orderData = {this.state.orderData?.order} dataApi = {this.state.response} />

                  <CustomFloatInput
                      name="hospitalShiftText"
                      updateValue={this.updateValue}
                      value={this.state.hospitalShiftText}
                      placeholder="מחלקה"
                      cssClass=""
                      validationRules={false}
                      typeInput="text"
                      checkInputs={this.state.checkInputs}
                      checked={() => this.setState({ checkInputs: false })}
                  />  


                  {/* for moked */}
                  <CustomFloatInput
                    name="referrer"
                    selectOptions={this.state.response.referrers}
                    updateValue={this.updateValue}
                    value={this.state.referrer}
                    placeholder="גורם מפנה..."
                    cssClass=""
                    validationRules={{ required: false }}
                    typeInput="select"
                    checkInputs={this.state.checkInputs}
                    checked={() => this.setState({ checkInputs: false })}
                  />
                </div>
              </div>
            </div>

            <div
              className={`acordCont colorDown scheduleOrder ${orderData.is_outdated ? "is_outdated" : ""
                }`}
            >
              <h2>תזמון הזמנה</h2>

              {orderData.is_outdated && false ? (  //cancelled!!
                <div>
                  <div className="daySelects BtnSelectCont">
                    <ul className="showOutdated">
                      <li>
                        <span>תאריך משמרת: </span>
                        <strong className="numbers">
                          {orderData.targetDayShow}
                        </strong>
                      </li>
                      <li>
                        <span>סוג משמרת: </span>
                        <strong>{orderData.hospitalShift}</strong>
                      </li>

                      {this.state.hospitalshiftId === "6" ? (
                        <li>
                          <span>בין השעות: </span>
                          <strong className="numbers">
                            {orderData.emergencyFrom} ל- {orderData.emergencyTo}
                          </strong>
                        </li>
                      ) : (
                        false
                      )}
                    </ul>

                  </div>

                  <ShowHoursHospitalUser user = {user} orderData={this.state.orderData} />

                </div>
              ) : (
                <div>
                  {!this.state.isRetroOrder && <div className="daySelects BtnSelectCont">
                    
                    {this.state.response.days.map((item) => {
                      if (true) {
                        return (
                          <button
                            key={item.shortDay}
                            onClick={() =>
                              this.setState({ targetDay: item.dayDate, targetDayName: item.name })
                              //, ()=> console.log(item.dayDate)
                            }
                            className={`customBtnSelect ${this.state.targetDay === item.dayDate
                                ? "active"
                                : ""
                              }`}
                          >
                            {item.name} <small>({item.shortDay})</small>
                          </button>
                        );
                      } else return false;
                    })}
                  </div> }

                  {(!this.state.cancelRetro ) && <div className="retroOrder">
                      
                      <h3>הוספת הזמנת רטרו</h3>
                      <ShowDayPickerRetro hadleDateRetro = { (date,dayName)=> this.hadleDateRetro(date,dayName) } />
                      
                      {this.state.isRetroOrder && <button style={{marginRight: '40px'}} className='cancelBtn' onClick={ ()=> this.handleCancelRetro() }>ביטול</button> }

                      <Form.Check 
                        className={`holydayShitch ${this.state.isHoliday ? 'active' : ''}`}
                        checked={this.state.isHoliday}
                        onChange={ () => this.setState({ isHoliday : !this.state.isHoliday })}
                        type="switch"
                        id="custom-switch"
                        label="משמרת חג:"
                      />

                  </div>}


                  <div className="hospitalShiftsSelects BtnSelectCont">

                    

                    { flagSpecialDays ? <>

                                
                                { ( this.state.targetDayName === 'שישי'  ) && <>
                                <button
                                  onClick={() =>this.setState({ hospitalshiftId: '1' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '1' && !orderData?.hospitalShiftSpecial ? "active": ""}`}
                                >בוקר</button>

                                <button //from boker
                                  onClick={() =>this.setState({ hospitalshiftId: 'shishiErevWolfson' })}
                                  className={`customBtnSelect ${ (this.state.hospitalshiftId === 'shishiErevWolfson' || (orderData && orderData?.hospitalShiftSpecial === 'shishiErevWolfson')) ? "active": ""}`}
                                >ערב שישי</button>

                                <button //from erev
                                  onClick={() =>this.setState({ hospitalshiftId: '2' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '2' ? "active": ""}`}
                                >כניסת שבת</button>

                                <button //from shabat
                                  onClick={() =>this.setState({ hospitalshiftId: '4' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '4' ? "active": ""}`}
                                >לילה שישי</button>
                                </> }



                                {/* //SHABAT!!! */}
                                { ( this.state.targetDayName === 'שבת'  ) && <>
                                <button
                                  onClick={() =>this.setState({ hospitalshiftId: '1' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '1' && !orderData?.hospitalShiftSpecial ? "active": ""}`}
                                >בוקר</button>

                                <button //from boker
                                  onClick={() =>this.setState({ hospitalshiftId: 'shabatErevWolfson' })}
                                  className={`customBtnSelect ${ (this.state.hospitalshiftId === 'shabatErevWolfson' || (orderData && orderData?.hospitalShiftSpecial === 'shabatErevWolfson')) ? "active": ""}`}
                                >ערב שבת</button>

                                <button //from erev
                                  onClick={() =>this.setState({ hospitalshiftId: '5' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '5' ? "active": ""}`}
                                >צאת שבת</button>

                                <button //from shabat
                                  onClick={() =>this.setState({ hospitalshiftId: '3' })}
                                  className={`customBtnSelect ${this.state.hospitalshiftId === '3' ? "active": ""}`}
                                >לילה מוצ"ש</button>
                                </> }

                    </> : <>

                      {this.state.response.hospitalShifts.map((item) => {
                        if (
                          /* targetDayName */
                          (item.name !== 'מכניסת שבת' || this.state.targetDayName === 'שישי' ) &&
                          (item.name !== 'לילה' || this.state.targetDayName !== 'שישי' ) &&
                          (item.name !== 'עד יציאת שבת' || this.state.targetDayName === 'שבת' ) &&
                          (item.name !== 'ערב' || this.state.targetDayName !== 'שבת' )

                        ) {
                          return (
                            < React.Fragment key={item.id} >
                              
                              <button
                                
                                onClick={() =>
                                  this.setState({ hospitalshiftId: item.id })
                                }
                                className={`customBtnSelect ${this.state.hospitalshiftId === item.id
                                    ? "active"
                                    : ""
                                  }`}
                              >
                                {item.name}{" "}
                              </button>
                            </React.Fragment>
                          );
                        } else return false;
                      })}
                    </> }
                  </div>

                  {this.state.hospitalshiftId === "6" ? (
                    <div className="emergencyService animate__animated animate__fadeInDown">
                      <div className="inputs">
                        <div className="smallInputs clear line timeInputs">
                          <CustomFloatInput
                            name="emergencyFrom"
                            updateValue={this.updateValue}
                            value={this.state.emergencyFrom}
                            showValue={orderData ? true : false}
                            dateText="משעה"
                            placeholder=""
                            cssClass=""
                            validationRules={{ required: false }}
                            typeInput="time"
                            checkInputs={this.state.checkInputs}
                            checked={() =>
                              this.setState({ checkInputs: false })
                            }
                          />
                          <CustomFloatInput
                            name="emergencyTo"
                            updateValue={this.updateValue}
                            value={this.state.emergencyTo}
                            showValue={orderData ? true : false}
                            dateText="עד שעה"
                            placeholder=""
                            cssClass=""
                            validationRules={{ required: false }}
                            typeInput="time"
                            checkInputs={this.state.checkInputs}
                            checked={() =>
                              this.setState({ checkInputs: false })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ) : (
                    false
                  )}

                  <ShowHoursHospitalUser user = {user} orderData={this.state.orderData} />

                </div>
              )}
            </div>

            {this.state.isPrati && <div className="acordCont colorDown">

              <h2>המטופל</h2>

                <div className="inputs">

                  <div className="smallInputs clear line patientData">
                    <CustomFloatInput
                      name="patientName"
                      updateValue={this.updateValue}
                      value={this.state.patientName}
                      placeholder="שם המטופל"
                      cssClass=""
                      validationRules={{ required: true }}
                      typeInput="text"
                      checkInputs={this.state.checkInputs}
                      checked={() => this.setState({ checkInputs: false })}
                    />
                    <CustomFloatInput
                      name="patientTz"
                      updateValue={this.updateValue}
                      value={this.state.patientTz}
                      placeholder="ת.ז המטופל"
                      cssClass="numberClass"
                      validationRules={{ }}
                      typeInput="text"
                      checkInputs={this.state.checkInputs}
                      checked={() => this.setState({ checkInputs: false })}
                    />
                    <CustomFloatInput
                      name="patientPhone"
                      updateValue={this.updateValue}
                      value={this.state.patientPhone}
                      placeholder="נייד"
                      cssClass="numberClass"
                      validationRules={{  }}
                      typeInput="text"
                      checkInputs={this.state.checkInputs}
                      checked={() => this.setState({ checkInputs: false })}
                    />
              
                </div>
              

                <div className="line checkboxes">
                  <Form.Group
                    className="checkBoxCont"
                    controlId="formBasicCheckbox1"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      checked={this.state.patientHabas}
                      onChange={() => {
                        this.setState({
                          patientHabas: !this.state.patientHabas,
                        });
                      }}
                    />

                    <Form.Check.Label>
                      <span>חבס</span>
                    </Form.Check.Label>
                  </Form.Group>

                  <Form.Group
                    className="checkBoxCont"
                    controlId="formBasicCheckbox2"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      checked={this.state.patientOz}
                      onChange={() => {
                        this.setState({ patientOz: !this.state.patientOz });
                      }}
                    />

                    <Form.Check.Label>
                      <span>עוז</span>
                    </Form.Check.Label>
                  </Form.Group>

                  <Form.Group
                    className="checkBoxCont"
                    controlId="formBasicCheckbox3"
                  >
                    <Form.Check.Input
                      type={"checkbox"}
                      checked={this.state.patientBidud}
                      onChange={() => {
                        this.setState({
                          patientBidud: !this.state.patientBidud,
                        });
                      }}
                    />

                    <Form.Check.Label>
                      <span>בידוד</span>
                    </Form.Check.Label>
                  </Form.Group>
                </div>
              </div>
            </div> }

            <div className="acordCont colorDown">
              <h2>איש הקשר</h2>

              <div className="inputs">
                <div className="smallInputs clear line contactSection">
                  <CustomFloatInput
                    name="contactName"
                    updateValue={this.updateValue}
                    value={this.state.contactName}
                    placeholder="שם איש הקשר"
                    cssClass=""
                    validationRules={{ required: true }}
                    typeInput="text"
                    checkInputs={this.state.checkInputs}
                    checked={() => this.setState({ checkInputs: false })}
                  />
                  <CustomFloatInput
                    name="contactPhone"
                    updateValue={this.updateValue}
                    value={this.state.contactPhone}
                    placeholder="נייד איש הקשר"
                    cssClass="numberClass"
                    validationRules={{  }}
                    typeInput="text"
                    checkInputs={this.state.checkInputs}
                    checked={() => this.setState({ checkInputs: false })}
                  />
                  <CustomFloatInput
                    name="contactAddress"
                    updateValue={this.updateValue}
                    value={this.state.contactAddress}
                    placeholder="כתובת"
                    cssClass=""
                    validationRules={{ required: false }}
                    typeInput="text"
                    checkInputs={this.state.checkInputs}
                    checked={() => this.setState({ checkInputs: false })}
                  />
                </div>

                < div className="clear line" style={{ marginTop: '20px' }} >
                    <CustomFloatInput
                      name="comments"
                      updateValue={this.updateValue}
                      value={this.state.comments}
                      placeholder="הערות"
                      cssClass=""
                      validationRules={{ required: false }}
                      typeInput="text"
                      checkInputs={this.state.checkInputs}
                      checked={() => this.setState({ checkInputs: false })}
                    />
                </div>

                <div className="sendNewOrder">
                  {orderData ? (
                    <button
                      className="defaultBtnPic noPic greyBack"
                      onClick={() => this.props.goBack()}
                    >
                      <span>&laquo; חזרה לכל ההזמנות</span>
                    </button>
                  ) : (
                    false
                  )}

                  {!orderData && <div className='input inputTypeselect orderCopiesSelect'>
                      {/* <FloatingLabel label='בחר עותקים' > */}
                          <Form.Control
                              as="select"
                              className="customSelectInput " 
                              disabled={false}
                              //isInvalid={showErrors && !formData.hospitalId}
                              type='select'
                              //name="orderCopies"
                              onChange={(e)=>this.setState({ orderCopies : e.target.value })}
                              value={ this.state.orderCopies }
                          >
                              
                             { times(8, (i) => {

                                if(i > 0) return <option value={i} key={i} >עותקים: {i}</option>

                              })}

                          </Form.Control>
                      {/* </FloatingLabel> */}
                  </div> }

                  <button

                    disabled={this.state.loading ? this.state.loading : false}

                    className={
                      sendRules
                        ? "defaultBtnPic noPic animate__animated animate__bounceIn"
                        : "defaultBtnPic noPic disabledBtn"
                    }
                    onClick={() => this.sendForm(sendRules)}
                  >
                    {orderData ? "עידכון שינוים" : "פתיחת הזמנה"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          false
        )}


      </div>
    );
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    //console.log('sending...');

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, function () {
        //console.log(getData);

        if (getData.days && getData.days[0] && !this.props.orderData) {
          this.setState(
            {
              targetDay: getData.days[0].dayDate,
              hospitalshiftId: getData.hospitalShifts[0].id,
            },
            () => {
              //console.log(this.state);
            }
          );

        } else if (url === "updateOrder") {

          if (getData.error) {
            toast.error(getData.error);
          } else {
            toast.success(getData.ok);
          }


        } else if (url === "insertOrder") {

          if (getData.ok) {

            toast.success('ההזמנה נוצרה בהצלחה');
            this.props.newOrderId(getData.ok, this.state.isRetroOrder);

          } else {

            toast.error(getData.error);

          }


        }


      })
    );
  };
}

function ShowHoursHospitalUser(props) {

  const {user, orderData } = props;

  if(user.userCredential === '1' || !orderData?.order?.id) {
    return false;
  }

  return(
    <div className="showHoursHospitalUser">
                      
        { (orderData.order.nurseStartShow && orderData.order.nurseStopShow) && <>
          <h3>שעות משמרת: </h3>
          <span>
            <strong>התחלה: </strong>{orderData.order.nurseStartShow}
          </span>
          <span>
            <strong>סוף: </strong>{orderData.order.nurseStopShow}
          </span>
        </>}
        
    </div> 
  )


}


                  