import React, { useState } from 'react'

import { Form, Button, Modal } from 'react-bootstrap';
import loader from "./../../../img/preLoader.gif";
import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi'
import CancelOrderDropdown from './CancelOrderDropdown';

export default function OrderStatusChange(props) {

    const { orderData, setReloadWorkerPayIndex, reloadOrder, responseData, setResponseData } = props;

    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);

    const [formData, setFormData] = useState({
        nurseStart: '',
        nurseStop: ''
    });


    
  const closeOrder = (statusAction, closeId = false) => {

    const send = {
      orderId: orderData.id,
      action: statusAction
    };

    if(closeId) {

      send.closeId = closeId;      

    }

    sendtoApi('updateOrderStatus', 'orders', send, setLoading, reloadOrder);

  };


    const updateWorkedHours = () => {

        setShowModal(true);
    
    };

    const saveNewTime = () => {

        //console.log('formData :>> ', formData);
    
        const send = {
          orderId: orderData.id,
          nurseStart: formData.nurseStart,
          nurseStop: formData.nurseStop
        };
    
        sendtoApiHours('updateOrderHours', 'orders', send, setLoading, setResponseData, setShowModal, orderData, setReloadWorkerPayIndex);
      };

  return (
    <>
        <div className='buttonsCont'>

            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

            {orderData.orderStatus !== '3' && <button className='defaultBtnPic noPic yellow noPadd' onClick={updateWorkedHours}>עידכון שעות</button> }
            {(orderData.orderStatus === '3' || orderData.orderStatus === '4')  && <button className='defaultBtnPic noPic red noPadd' onClick={() => closeOrder('פתיחת הזמנה')}>פתיחת הזמנה</button> }

            {orderData.orderStatus !== '3' && <button className='defaultBtnPic noPic' onClick={() => closeOrder('סגירת הזמנה')}>סגירת הזמנה</button>}
            {orderData.orderStatus !== '4' && < CancelOrderDropdown closeOrder = {closeOrder} />}

        </div>



        {responseData && <Modal
        className={'animate__animated animate__fadeInDown animate__faster modalSite timeModal'}
        animation={false}
        onHide={() => setShowModal(false)}
        /* size="sm" // בגודל קטן!!! */
        //size="lg"
        size="sm" // גדול!! */
        // backdrop="static" // חייב ללחוץ על כפתור!
        /* centered // VERTICA CENTER */
        show={showModal}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        >

        <Modal.Body>

        <Button className="closeBtn" onClick={() => setShowModal(false)} variant="secondary" >X</Button>

        <div className="Message">
            <h2>עידכון שעות עבודה</h2>

            <div className="smallInputs clear line timeInputs">


            <div className='input'>
                <label>משעה</label>

                <Form.Control

                className={"customFloatInput "}
                isInvalid={false}
                type="time"
                defaultValue={convertDateTimetoTime(responseData.timeData.start)}
                onChange={e => setFormData({ ...formData, nurseStart: e.target.value })}

                name="nurseStart"

                />

            </div>

            <div className='input'>
                <label>לשעה</label>

                <Form.Control

                className={"customFloatInput "}
                isInvalid={false}
                type="time"

                defaultValue={convertDateTimetoTime(responseData.timeData.stop)}
                onChange={e => setFormData({ ...formData, nurseStop: e.target.value })}

                name="nurseStop"

                />

            </div>


            </div>
        </div>


        <div className="btnCont">
            <Button variant="success" size="" onClick={saveNewTime} >שמירה</Button>
            <Button variant="secondary" size="" onClick={() => setShowModal(false)} >סגירה</Button>
        </div>

        </Modal.Body>
        </Modal>}
    </>
  )
}


function convertDateTimetoTime(dateValue) {

    const myTime = dateValue.substr(9, 5);
  
    return myTime;
  
  
  }
  
  function sendtoApiHours(url, controller, objectToSend, setLoading, setResponseData, setShowModal, orderData, setReloadWorkerPayIndex) {
  
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {
      if (getData.ok) {
  
        toast.success(getData.ok);
        setShowModal(false);
  
        setReloadWorkerPayIndex((prevState) => !prevState);
  
        const send = { orderId: orderData.id };
        sendtoAdmin('getPayData', 'Pay', send, setResponseData, setLoading);
  
      } else if (getData.error) {
        toast.error(getData.error)
      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }

  

function sendtoApi(url, controller, objectToSend, setLoading, reloadOrder) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {
        
      setLoading(false)

      if (getData.ok) {
  
        toast.success('עודכן בהצלחה');
        reloadOrder();
        //console.log(orderData);
        //window.open('/order?orderId=' + orderData.id, '_self');
  
      } else if (getData.error) {
        toast.error(getData.error)
      }
    })
  
    getData.catch((error) => {
        setLoading(false)
        console.log(error)
    })
  
    getData.finally(() => {
      //setLoading(false)
    })
  }
  
  