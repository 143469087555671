import React, { useState, useEffect } from 'react'

import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import OrderPayShowTotals from './OrderPayShowTotals';
import OrderPayCreditCard from './OrderPayCreditCard';
import OrderPayAddPayItems from './OrderPayAddPayItems'
import OrderStatusChange from './OrderStatusChange';
import NursePayPrati from './NursePayPrati';

export default function OrderPayIndex(props) {

    const { orderData, reloadOrder } = props;
    //console.log(orderData);

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        if(!responseData && !loading) {

            const send = {
                orderId: orderData.id
            };
    
            sendtoAdmin('getPayData', 'Pay', send, setResponseData, setLoading);

        }

    }, [responseData, loading, orderData])




    //console.log(object)
    //timeData?.shift?.price ? timeData.shift.price : false

    const reloadData = () => {

        setResponseData(false)
    }


    return (
        <div className='OrderPayIndex animate__animated animate__fadeIn'>
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn"} />

            {responseData && <OrderPayShowTotals orderData={orderData} payData={responseData} />}
            
            {responseData && <NursePayPrati orderData={orderData} payData={responseData} /> }

            {responseData && <OrderPayAddPayItems reloadData={() => reloadData()} payData={responseData} orderData={orderData} />}
            {responseData && <OrderPayCreditCard orderData={orderData} payData={responseData} reloadData={() => reloadData()} reloadOrders={() => props.reloadOrders()} />}

            {responseData && <OrderStatusChange responseData = {responseData} setResponseData = {setResponseData} orderData = {orderData} reloadOrder = {reloadOrder} setReloadWorkerPayIndex = {()=>{console.log('Reload')}} /> }
        </div>
    )



}
