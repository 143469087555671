import React, { Component } from 'react'
import CountUp from 'react-countup';  //npm i react-countup;

import ic1 from './../../../img/nathan/topCardsIcons/ic1.svg';
import ic2 from './../../../img/nathan/topCardsIcons/ic2.svg';
import ic3 from './../../../img/nathan/topCardsIcons/ic3.svg';

import getDataFromApi from '../../api/getDataFromApi';

export default class OrdersTopCards extends Component {


    constructor(props) {
        super(props)
    
        this.state = {
            response : false
        }
    }
    

    getSatusOrders = () => {

        let sendObj = {}
        this.sendtoAdmin('orderSatusCards','orders',sendObj,'response');

    }

    componentDidMount() {
        
        this.getSatusOrders();

    }
    
    changeFilterStatus = (status) => {
        
        this.props.changeFilterStatus(status);

    }
    



    render() {

        return (
            <div className='OrdersTopCards'>

                {this.state.response ? <ul className='animate__animated animate__fadeIn'>

                    <li className={ this.props.filteredStatus === '1' ? 'active' : '' } >

                        <div className="icon">
                            <img src={ ic1 } alt='' />
                        </div>

                        <div className="text">
                            <h3>הזמנות פתוחות</h3>
                            <p><CountUp start={1} duration={4} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(this.state.response.numbers.open)} /></p>
                            { this.props.filteredStatus === '1' ?
                                <button onClick={ ()=> this.changeFilterStatus('') } >ביטול</button> :
                                <button onClick={ ()=> this.changeFilterStatus('1') } >הצג הכל</button>
                            }
                        </div>

                    </li>

                    <li className={ this.props.filteredStatus === '2' ? 'active' : '' } >

                        <div className="icon">
                            <img src={ ic2 } alt='' />
                        </div>

                        <div className="text">
                            <h3>ממתינים לתשלום</h3>
                            <p><CountUp start={1} duration={4} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(this.state.response.numbers.notPaid)} /></p>
                            
                            { this.props.filteredStatus === '2' ?
                                <button onClick={ ()=> this.changeFilterStatus('') } >ביטול</button> :
                                <button onClick={ ()=> this.changeFilterStatus('2') } >הצג הכל</button>
                            }
                        </div>

                    </li>

                    <li className={ this.props.filteredStatus === '0' ? 'active' : '' } >

                        <div className="icon">
                            <img src={ ic3 } alt='' />
                        </div>

                        <div className="text">
                            <h3>ללא שיוך עובד</h3>
                            <p><CountUp start={1} duration={4} separator=',' useEasing={true} startOnMount={true}  decimals={0} end={parseFloat(this.state.response.numbers.notLinked)} /></p>
                            { this.props.filteredStatus === '0' ?
                                <button onClick={ ()=> this.changeFilterStatus('') } >ביטול</button> :
                                <button onClick={ ()=> this.changeFilterStatus('0') } >הצג הכל</button>
                            }
                        </div>

                    </li>


                </ul> : false }
                
            </div>
        )
    }


    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        //console.log('sending...');

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false,
        
        }, function(){

            //console.log(getData);

            /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */
            
            
        }))
    }

}
