import React, { useState, useEffect } from 'react'

import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

import { Form } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import { RestUrls } from '../../api/config';
import {Button} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0

export default function NurseOrdersSearch(props) {

    const { nurseData } = props;

    //const [response, setRequest] = useState('')
    const [loading, setLoading] = useState(false)
    const [dates, setDates] = useState(false)

    const [selectedDates, setSelectedDates] = useState(defaultValueForm())

    const onChange = (e) => {

        setSelectedDates({
            ...selectedDates,
            [e.target.name]: e.target.value,
        })

    }

    /* https://reactjs.org/docs/hooks-reference.html#useeffect */

    useEffect(() => {
        // Actualiza el título del documento usando la API del navegador
        if(selectedDates?.year || selectedDates?.month ) {
            getOrders();
            console.log(selectedDates);
            //console.log('hello');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ selectedDates]);


    function getOrders()  {

        //console.log('getOrders...', nurseData.id );

        if (!loading) {

            const send = {
                nurseId: nurseData.id,
                //dates: dates
                year:selectedDates.year,
                month:selectedDates.month
            }

            console.log(send);

            sendtoAdmin('getNurseArchiveOrders', 'NurseEnv', send, setLoading, props) //, setRequest
        }

        //console.log('getOrders...', nurseData.id );
    }

    const onSubmitExcel = () => { 

        console.log(selectedDates);

        let queryLine = '?year=' + selectedDates.year + '&month=' + selectedDates.month + '&nurseId=' + nurseData.id;

        queryLine = queryLine + '&token=' + RestUrls.Code;

        const url = RestUrls.baseApiUrl + 'NurseEnv/getNurseArchiveOrdersExcel' + queryLine + '&download=1';

        window.open(url, '_blank').focus();

     }

    if(!loading && !dates && dates !== 'noOrders' ) {

        const send = {
            nurseId: nurseData.id,
        }

        getDatesFromAdmin('getArchiveOptionsDates','NurseEnv', send ,setDates,setLoading);

    }

    return (
        <div className='NurseOrdersSearch'>

            <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

            <div className='goBackBtnCont'>
                <button className="goBackBtn bounce2" onClick={ () => props.goBack() }>« חזור</button>
            </div>

            {dates && dates !== 'noOrders' ? <div className='filterCont'>

                <h2>ארכיון משמרות</h2>

                <Form  onChange={onChange}>

                    <Form.Control
                        as="select"
                        className="customSelectInput"
                        disabled={false}
                        type='select'
                        name="month"
                        onChange={onChange}
                        value={selectedDates.month}
                    >
                        <option value="">בחירת חודש</option>

                        {dates.month.map((item) => {
                        if (true) {
                            return (
                            <option value={item} key={item}>
                                {item}
                            </option>
                            );
                        } else return false;
                        })}
                    </Form.Control>

                    <Form.Control
                        as="select"
                        className="customSelectInput"
                        disabled={false}
                        type='select'
                        name="year"
                        onChange={onChange}
                        value={selectedDates.year}
                    >
                        <option value="">בחירת שנה</option>

                        {dates.years.map((item) => {
                        if (true) {
                            return (
                            <option value={item} key={item}>
                                {item}
                            </option>
                            );
                        } else return false;
                        })}
                    </Form.Control>

                    <Button style={{marginRight : '10px'}} onClick={ onSubmitExcel } disabled={ selectedDates.month ? false : true } variant="secondary">EXCEL</Button>

                </Form>

                

                {/* <button onClick={ ()=>{setse('asd')} } >test</button> */}

                </div> : false }
            
            
        </div>
    )

    function sendtoAdmin(url, controller, objectToSend, setLoading, props) { //,setRequest

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        getData.then((getData) => {

            //console.log(getData);

            if(getData.orders) {
                props.updateArchive(getData.orders);
            }

          /* if (getData.ok) {
            setRequest(getData.ok)
            toast.success('הצלחה')
          } else if (getData.error) {
            toast.error(getData.error)
          } */
        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }

      
      function  getDatesFromAdmin(url, controller, objectToSend, setDates, setLoading) {

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        
        getData.then((getData) => {

            console.log(getData);

            if(getData.dates?.years) {
                setDates(getData.dates);
            }

            else {
                setDates('noOrders');
                toast.error('אין משמרות בארכיון')
            }
         
        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }

      function defaultValueForm() {
        return {
          year: '',
          month: '',
        }
      }

}



