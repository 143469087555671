import React, {useState} from 'react'

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../../api/getDataFromApi'
import loader from './../../../../img/preLoader.gif'

import { Form, FloatingLabel, Row, Col  } from 'react-bootstrap';
import addIco from './../../../../img/nathan/svg/add.svg'

export default function AddForm(props) {


    const {selectedDates, symbols, nurses } = props;

    //const [response, setRequest] = useState('')
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState(defaultValueForm())
    const [showErrors, setshowErrors] = useState(false);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

    }

    const doSubmit = () => {

        setshowErrors(true);

        if(!formFlagError) {

            //console.log("ok");

            if (!loading) {

                const send = {
                    
                    nurseId :  formData.nurseId,
                    symbolNumber :  formData.symbolNumber,
                    valueMoney :  formData.valueMoney,
                    month: selectedDates.month,
                    year: selectedDates.year

                }
        
                //console.log(send);

                sendtoAdmin('addWorkerSymbol', 'Money', send, setLoading, props.reloadData)

            }
            //props.cardForm(formData);
        }
        
    }

    let formFlagError = (!formData.nurseId || !formData.symbolNumber || !formData.valueMoney);


  return (
    
    <div className='inputs'>
                
        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

        <Row>
            <Col md='3'>
                <div className='input select selectLabel'>
                    
                    <FloatingLabel label='עובד' >


                        <Form.Control
                            as="select"
                            className="customSelectInput"
                            placeholder= 'עובד' 
                            isInvalid={showErrors && (!formData.nurseId) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            /* disabled = { orderData.hospitalId !== '8' ? true : false } */
                            type="select"
                            name="nurseId"
                            value={formData.nurseId}
                            onChange={onChange} >

                            <option>בחירת עובד</option>
                            
                            {nurses.map((item) => {

                            if (true) {

                                return (
                                    <option value={item.id} key={item.id}>
                                        {item.name} - {item.tzNurse}
                                    </option>
                                );
                            } else return false;
                            })}
                        
                        </Form.Control>
                    </FloatingLabel>
                </div>
            </Col>

            <Col md='3'>

                <div className='input select'>
                    
                    <FloatingLabel label='בחירת סימול' >


                        <Form.Control
                            as="select"
                            className="customSelectInput"
                            placeholder= 'בחירת סימול' 
                            isInvalid={showErrors && (!formData.symbolNumber) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            /* disabled = { orderData.hospitalId !== '8' ? true : false } */
                            type="select"
                            name="symbolNumber"
                            value={formData.symbolNumber}
                            onChange={onChange} >

                            <option>בחירת סימול</option>

                            {symbols.map((item) => {

                            if (true) {

                                return (
                                    <option value={item.number} key={item.number}>
                                        {item.name}
                                    </option>
                                );
                            } else return false;
                            })}

                        </Form.Control>
                    </FloatingLabel>
                </div>
            </Col>

            <Col md='3'>

                <div className='input text'>

                    <FloatingLabel label='סכום' >


                        <Form.Control
                            className="customFloatInput"
                            placeholder= 'סכום' 
                            isInvalid={showErrors && (!formData.valueMoney) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            /* disabled = { orderData.hospitalId !== '8' ? true : false } */
                            type="number"
                            name="valueMoney"
                            value={formData.valueMoney}
                            onChange={onChange}
                        />
                    </FloatingLabel>
                </div>
            </Col>

            <Col md='3'>

                <button type="button" className={`defaultBtnPic ${formFlagError ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} >
                    <img src={addIco} alt='הוספה'/>
                    <span>הוספה</span>
                </button>

            </Col>
        
        </Row>

    </div>)
}

function defaultValueForm() {

    //console.log(pay);

    return {
        nurseId : '',
        symbolNumber : '',
        valueMoney : ''
    }

}

function sendtoAdmin(url, controller, objectToSend, setLoading, reloadData) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        //console.log(getData);

      if (getData.ok) {
        toast.success(getData.ok)
        setLoading(false)
        reloadData();
        //setRequest(getData.ok)
        

      } else if (getData.error) {
        setLoading(false)
        toast.error(getData.error)
      }
    })

    getData.catch((error) => {
        setLoading(false);
        console.log(error)
    })

    getData.finally(() => {
      //setLoading(false)
    })
}
