import React from 'react'
import FirstStartShift from './FirstStartShift';

export default function FirstScreenMainOrder(props) {

    const { nurseAndOrdersData } = props;

    const shiftData = nurseAndOrdersData.shiftData ? nurseAndOrdersData.shiftData : false;

    const firstOrder = nurseAndOrdersData.orders[0];

    console.log('shiftData', shiftData);
    //console.log(nurseAndOrdersData)

    return (
        <div className='FirstScreenMainOrder'>

            <header className='infoHeader'>
                <h2>המשמרת הבאה:</h2>
                <ul>
                    <li>
                        ליום <span>{firstOrder.targetDayName} {firstOrder.targetDayShow}</span>
                    </li>
                    <li>
                        משמרת - {firstOrder.hospitalShift}
                    </li>

                    {shiftData && firstOrder.hospitalshiftId !== "6" && <li>
                        <span>({shiftData.timeStart} - {shiftData.timeEnd})</span>
                    </li>}

                    {firstOrder.hospitalshiftId === "6" ? <li className='emergencyLine'>
                        משעה: {firstOrder.emergencyFrom} - לשעה: {firstOrder.emergencyTo}
                    </li> : false}
                </ul>
            </header>

            <ul className='hospitalData'>
                <li>{firstOrder.hospital}</li>

                <li>{firstOrder.hospitalShiftText}</li>
                <li>{firstOrder.hospitalDivision}</li>
                <li>פקיד/ה: {firstOrder.openUserName}</li>
            </ul>

            <FirstStartShift order={firstOrder} shiftData={nurseAndOrdersData?.shiftData} orderCounts={nurseAndOrdersData.totalOrders} />


        </div>
    )
}
