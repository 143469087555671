import React, { useState } from 'react';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'
import validator from "validator";

import { Form, FloatingLabel } from 'react-bootstrap';


export default function OrderInvoiceForm(props) {

    const { orderData, payment, setIsInvoice } = props;

    //console.log('orderData :>> ', orderData);
    //console.log('payment :>> ', payment);

    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState(defaultValueForm(orderData))
    const [showErrors, setshowErrors] = useState(false);

    const onChange = (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    const doSubmit = () => {

        setshowErrors(true);

        if (!formFlagError) {

            const send = {

                orderId: orderData.id,
                price: payment.SumValue,
                paymentId: payment.id,

                name: formData.name,
                company: formData.company,
                client_address_line_1: formData.client_address_line_1,
                email: formData.email

            }


            if (!loading) {

                sendtoAdmin('buildMyInvoice', 'pay', send, setLoading, setIsInvoice)

            }


        }

    }

    let formFlagError = (!formData.name ||
        (!formData.email || !validator.isEmail(formData.email)));

    return <div className='OrderInvoiceForm'>

        <h2>יצירת חשבוניות</h2>
        <img src={loader} alt="loader" className={!loading ? 'loader' : 'loader active animate__animated animate__fadeIn'} />


        <div className='inputs'>
            <div className='input text'>
                <FloatingLabel label="שם מלא" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='שם מלא'
                        isInvalid={showErrors && (!formData.name)}
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={onChange}
                    />
                </FloatingLabel>


            </div>

            <div className='input text'>
                <FloatingLabel label="שם חברה" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='שם חברה'
                        //isInvalid={showErrors && (!formData.company) }
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="company"
                        value={formData.company}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <div className='input adress'>
                <FloatingLabel label="כתובת מייל לשליחת החשבונית" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='כתובת מייל לשליחת החשבונית'
                        isInvalid={showErrors && (!formData.email || !validator.isEmail(formData.email))}
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <div className='input adress'>
                <FloatingLabel label="כתובת" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='כתובת'
                        //isInvalid={showErrors && (!formData.client_address_line_1) }
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="client_address_line_1"
                        value={formData.client_address_line_1}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <div className='btnCont'>
                <button type="button" disabled={loading} className={`defaultBtnPic ${formFlagError ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} >
                    <span>יצירת חשבונית</span>
                </button>
            </div>

        </div>



    </div>;
}


function defaultValueForm(orderData) {

    //console.log(pay);

    return {

        name: orderData.patientName,
        company: '',
        client_address_line_1: orderData.contactAddress,
        email: ''
    }

}

function sendtoAdmin(url, controller, objectToSend, setLoading, setIsInvoice) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        //console.log(getData);

        if (getData.ok) {

            setIsInvoice(getData.ok);
            //setRequest(getData.ok)
            //toast.success(getData.ok)
        } else if (getData.error) {
            toast.error(getData.error)
        }
    })

    getData.catch((error) => {
        console.log(error)
    })

    getData.finally(() => {
        setLoading(false)
    })
}

