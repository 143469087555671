import React, { Component } from "react";

import { InputGroup, FormControl } from "react-bootstrap";
import getDataFromApi from "../../api/getDataFromApi";
import { replaceText } from "../../models/ordersFuncNathan";

export default class OrdersSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterStatus: this.props.currentSearchStatus.filterStatus,
      hospitalId: this.props.currentSearchStatus.hospitalId,
      freeSearch: this.props.currentSearchStatus.freeSearch,
      response: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);
      let updateValues = {
        freeSearch: this.state.freeSearch,
      };

      this.props.updateValues(updateValues);
    });
  };

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);

      let updateValues = {
        filterStatus: this.state.filterStatus,
        hospitalId: this.state.hospitalId,
      };

      this.props.updateValues(updateValues);
    });
  };

  componentDidMount() {
    let sendObj = {};
    this.sendtoAdmin("getAvailableSearchData", "orders", sendObj, "response");
  }

  render() {

    const user = JSON.parse(localStorage.getItem('userData'));

    return (
      <div className="OrdersSearch animate__animated animate__fadeIn">
        <h2>חיפוש מתקדם</h2>

        {this.state.response ? (
          <div className="inputs">
            <InputGroup className="inputGroup">
              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="customSelectInput  noLabel"
                onChange={this.handleSelect}
                value={this.state.filterStatus}
                name="filterStatus"
              >
                <option value="">בחירת הסטטוסים...</option>
                <option value="all">כל הסטטוסים</option>
                <option value="confirm">ללא אישור עובד</option>

                {this.state.response.orderStatus.map((item) => {
                  if (true) {
                    /* currentState={this.state} */
                    return (
                      <option value={item.code} key={item.code}>
                        { replaceText(item.name) }
                      </option>
                    );
                  } else return false;
                })}
              </FormControl>
            </InputGroup>

            <InputGroup className="inputGroup">
              <FormControl
                as="select"
                /* 'inputPrepend customSelect' */
                className="customSelectInput noLabel"
                onChange={this.handleSelect}
                value={this.state.hospitalId}
                name="hospitalId"
              >
                { (user.hospital && user.hospital.length === 0) ? <>
                  <option value="">כל בתי החולים...</option>

                  {this.state.response.hospitals.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item.id} key={item.id}>
                          {item.hospital}
                        </option>
                      );
                    } else return false;
                  })}
                </> : <option value={user.hospital.id}>{user.hospital.name}</option> }
              </FormControl>
            </InputGroup>

            <InputGroup className="inputGroup">
              <FormControl
                className="customInput searchIco"
                placeholder="חיפוש חופשי"
                onChange={this.handleChange}
                value={this.state.freeSearch}
                name="freeSearch"
              ></FormControl>
            </InputGroup>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    //console.log('sending...');

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, function () {
        //console.log(getData);
        /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */
      })
    );
  };
}


