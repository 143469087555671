import React from 'react'
import { Table } from 'react-bootstrap';

export default function NurseArchive(props) {

    const { orders } = props;

    //console.log(orders);

    return (
        <div className='tableNurseOrders'>
            <Table >
                <thead>
                    <tr>
                        <th>תאריך</th>
                        <th>יום</th>
                        <th>שעות עבודה</th>
                        <th>בית חולים</th>
                    </tr>
                </thead>

                {orders[0]?.id ? <tbody>

                    {orders.map((item) => {
                        if (true) { //orders[0].id !== item.id

                            return (
                                <tr key={item.id}>
                                    <td>{item.targetDayShow}</td>
                                    <td>{item.targetDayName}</td>
                                    <td>{item.nurseStartShow1} - {item.nurseStopShow1} </td>
                                    <td>{item.hospital}</td>
                                </tr>
                            );
                        } else return false;
                    })}

                </tbody> : <tbody>
                    <tr>
                        <td colSpan={4} className='noResults'>אין משמרות</td>
                    </tr>
                </tbody>}

            </Table>
        </div>
    )
}
