import React, {useState, useEffect} from 'react'

import  getDataFromApi, { sendtoAdmin } from '../../../api/getDataFromApi';
import { toast } from 'react-toastify' //npm i react-toastify
import loader from "./../../../../img/preLoader.gif";

import AddForm from './AddForm'

export default function AddSymbolToWorker(props) {

    const { selectedDates } = props;

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        setResponseData(false);

    }, [selectedDates])
    

    if(!loading && !responseData) {

        const send = {

            month : selectedDates.month,
            year : selectedDates.year
            
        };

        sendtoAdmin('getMonthSymbolAdds','money', send ,setResponseData,setLoading);

    }

    const deletteMe = (id) => { 
        if (!loading) {
            const send = {
                deletteId: id
            }
            //console.log(send);
            sendtoApi('deletteWorkerSymbol', 'Money', send, setLoading, setResponseData)
        }
    }

    
    const symbols = [
        
        {id : 1, number: '0102', name: 'נסיעות'},
        // CHANGED - from 0122 to 199
        {id : 7, number: '0122', name: 'בונוס'},
        // {id : 7, number: '0199', name: 'מענק בידוד'},
        {id : 3, number: '0199', name: 'מענק בידוד'},
        {id : 4, number: '0216', name: 'חג'},
        {id : 5, number: '0178', name: 'מחלה'},
        {id : 6, number: '0172', name: 'מחלה המשך'},
        {id : 2, number: '0106', name: 'מענק חד פעמי'},
        /* {id : 7, number: '0325', name: 'שעות נוספות 125%'},
        {id : 8, number: '0350', name: 'שעות נוספות 150%'} */
    ]

    const nameFromId = (id) => { 

        const symbol =  symbols.filter((item) =>  {
            return item.number === id;
        }); 

        return symbol[0].name

    }

  return (

    <div className='addSymbolWorker '>

        <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

        <h2>הוספת סימולים נוספים לחודש:</h2>

        { responseData?.nurses &&
            <AddForm selectedDates = {selectedDates} nurses = {responseData.nurses} symbols= {symbols} reloadData = { ()=>setResponseData(false) } />
        }
        

        { (responseData?.addsDb && responseData.addsDb[0]?.symbolNumber ) && <>
        
            <h2>סימולים נוספים קיימים:</h2>

            <ul>
                
                { responseData.addsDb.map((item) => {

                        if (true) {

                            return (
                                <li value={item.symbolNumber} key={item.id}>

                                    <button onClick={()=>deletteMe(item.id)}>
                                        <i className="far fa-trash-alt"></i>
                                    </button>

                                    <strong>{item.nurseName} <small>({item.tzNurse})</small> </strong> <span>{ nameFromId(item?.symbolNumber) } - <small>₪</small>{item.valueMoney} </span>
                                    
                                </li>
                            );
                            } else return false;
                    })
                } 

            </ul>
            
        </>}

        

    </div>

  )
}



function sendtoApi(url, controller, objectToSend, setLoading, setResponseData) {

    setLoading(true)

    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        setLoading(false);
        //console.log(getData);

      if (getData.ok) {

        toast.success(getData.ok)
        setResponseData(false);
        //setRequest(getData.ok)
        

      } else if (getData.error) {
        
        toast.error(getData.error)
      }
    })

    getData.catch((error) => {
        setLoading(false);
        console.log(error)
    })

    getData.finally(() => {
      //setLoading(false)
    })
}
