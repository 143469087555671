import React, {useState, useEffect } from 'react'

import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
import Switch from '@mui/material/Switch';
import { toast } from 'react-toastify' //npm i react-toastify

import { Table, Form } from 'react-bootstrap';
import edit from '../../../img/nathan/svg/config/edit.svg'

import addIco from './../../../img/nathan/svg/add.svg'

export default function SystemHospitals(props) {

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInsertHospital, setShowInsertHospital] = useState(false);

    const [formDataNewHospital, setFormDataNewHospital] = useState(defaultValueForm())
    
    //const [emergency, setEmergency] = useState(is_visible)

    useEffect(() => {
       
        getHospitals();

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])

    const getHospitals = () => {

        if(!loading && !responseData) {

            sendtoAdmin('getHospitals','hospitals', {id: ''} ,setResponseData,setLoading);
    
        }
        
    }
    
    const editHospital = (item) => {
        
        props.changeHospital(item);

    }
    

    const changeVisible = (item) => {
        
        

        const send = {
            hospitalId : item.hospitalId, 
            hospitalDivisionId : item.hospitalDivisionId,
            changeTo : item.notShowNewOrders === '0' ? '1' : '0',
        }

        console.log(send);

        changeStatusApi('changeOnOffHospital','hospitals', send , setLoading, setResponseData);
        

    }

    /* InsertHospital */

    const onChange = (e) => {

        setFormDataNewHospital({
          ...formDataNewHospital,
          [e.target.name]: e.target.value,
        })

        console.log('in')
      }
    
      let flagNewHospital = formDataNewHospital.hospitalName && formDataNewHospital.hospitalDivisionId ? true : false;
      
      const  onSubmitHospital = () => {

        console.log(formDataNewHospital);

        insertHospitalApi('insertHospital','hospitals', formDataNewHospital , setLoading, setResponseData);

        
      }
      

    return (
        <div className='SystemHospitals accordeonContent'>
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
            
            <div className='buttonCont'>

                <button className="defaultBtnPic violet" onClick={()=>setShowInsertHospital(!showInsertHospital)}>
                    <img src={addIco} alt="הוספה"/>
                    <span>הוספת בית חולים</span>
                </button>

            </div>
            
            

            {(showInsertHospital && responseData) && <div className='addHospitaCont clear'>

                    
                    <Form onChange={onChange} >

                        <Form.Control
                            className=""
                            placeholder='שם בית החולים'
                            //isInvalid={this.state.isError}
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="text"
                            name="hospitalName"
                        />

                        
                        <Form.Control
                            as="select"
                            className="customSelectInput "
                            type='select'
                            name="hospitalDivisionId"
                        >
                            <option value="">בחירת מחלקה...</option>

                            {responseData.hospitalDivisions.map((item) => {
                            if (true) {
                                
                                return (
                                <option value={item.id} key={item.id}>
                                    {item.name}
                                </option>
                                );
                            } else return false;
                            })}
                        </Form.Control>
                        

                        <button  type='button' onClick={onSubmitHospital} className={`defaultBtnPic noPic ${!flagNewHospital ? 'disabledBtn' : 'animate__animated animate__bounceIn'}`} disabled={!flagNewHospital}>הוספת בית חולים</button>

                    </Form>
            </div>}

            <div className='tableHospital'>
                <Table striped >
                    <thead>
                        <tr>
                            <th>בית חולים</th>
                            <th>סוג שירות</th>
                            <th></th>
                        </tr>
                    </thead>

                    {responseData.hospitals && responseData.hospitals[0]?.id ? <tbody>
                        
                        {responseData.hospitals.map((item) => {

                            let is_visible = item.notShowNewOrders === '0' ? true : false;

                            if (true) { //orders[0].id !== item.id

                                return (
                                    <tr key={item.id}>
                                        <td>{item.hospitalName}</td>
                                        <td>{item.hospitalDivisionName}</td>
                                        <td>
                                            <Switch

                                                checked={is_visible}
                                                onChange={ () => changeVisible(item) }
                                                name={`showMe${item.id}`}
                                                color="primary"
                                            />
                                            <button className='editBtnHospital' onClick={()=>editHospital(item)}>
                                                <img src={edit} alt='עריכה' />
                                            </button>
                                        </td>
                                    </tr>
                                );
                            } else return false;
                        })}
                        
                    </tbody> : <tbody>
                        <tr><td colSpan={4} className='noResults'>אין בתי חולים</td></tr>
                    </tbody> }

                </Table>
            </div>
        </div>
    )


    function changeStatusApi(url, controller, objectToSend, setLoading, setResponseData) {

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        getData.then((getData) => {

          if (getData.ok) {
                
                sendtoAdmin('getHospitals','hospitals', {id: ''} ,setResponseData,setLoading);
                toast.success(getData.ok);

          } else if (getData.error) {

            toast.error(getData.error)

          }

        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }



      function insertHospitalApi(url, controller, objectToSend, setLoading, setResponseData) {

        setLoading(true)
        const getData = getDataFromApi(url, objectToSend, controller, 'all')
    
        getData.then((getData) => {

          if (getData.ok) {
                
                toast.success(getData.ok);
                sendtoAdmin('getHospitals','hospitals', {id: ''} ,setResponseData,setLoading);

          } else if (getData.error) {

            toast.error(getData.error)

          }

        })
    
        getData.catch((error) => {
          console.log(error)
        })
    
        getData.finally(() => {
          setLoading(false)
        })
      }
}


function defaultValueForm() {
    return {
      hospitalName: null,
      hospitalDivisionId: null,
    }
  }


  


