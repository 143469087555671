import React, { Component } from 'react'
import { RestUrls } from "../../api/config"
import logoNathan from './../../../img/nathan/logo.png';
import { NavLink } from 'react-router-dom'; 
import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */

import ic1 from '../../../img/nathan/menuIcons/ic1.svg'; //orders
import ic2 from '../../../img/nathan/menuIcons/ic2.svg'; //workers

import money from '../../../img/nathan/menuIcons/money.svg'; 
import config from '../../../img/nathan/menuIcons/config.svg'; 

import logOut from '../../../img/nathan/menuIcons/logOut.svg';

export default class NavRight extends Component {


    render() {

        let user = JSON.parse(localStorage.getItem('userData'));
        let currentPage = this.props.page;

        //console.log(user);

        //let picTopDesktop = RestUrls.pagesPictures  + 'login/rLogin.jpg?v=1';

        //console.log(this.props);

        return <div className='site-menu'>

        <div className="mainMenuTable" >

            <div className="fixedPosition">
            
            {/*  <div className="clear">
                {isUser ? <Button href='?logout=1' style={{margin: '10px,10px,0,0'}} className="logOutBtn" variant="secondary" size="sm"><i className="fas fa-sign-out-alt" /></Button> : '' }
            </div> */}

            <header>

                <NavLink
                    className="logOut" 
                    to='/logout' >
                    <img src={ logOut } alt="" />
                    <span>התנתקות</span>
                </NavLink>

                <a href={ RestUrls.appUrl }  target="_self" rel="noopener noreferrer" >
                    <img className="logoClient" src={logoNathan} alt="נתן - רפואה שיקום וסיעוד" />
                </a>
                

               
                
                
            </header>

            <nav className="mainMenuNav">
                  
                    <NavLink
                        className={currentPage === 'orders' ? 'is-active' : ''} 
                        role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                        to='/userConsole' >
                            <img className='bulletMainMenu' src={ ic1 } alt='בולט' />
                            <span>הזמנות</span>
                    </NavLink>

                    { (user.hospital && user.hospital.length === 0) && <>
                    <NavLink
                        className={currentPage === 'workers' ? 'is-active' : ''} 
                        role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                        to='/workers' >
                            <img className='bulletMainMenu' src={ ic2 } alt='בולט' />
                            <span>עובדים</span>
                    </NavLink>

                    <NavLink
                        className={currentPage === 'config' ? 'is-active' : ''} 
                        role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                        to='/config' >
                            <img className='bulletMainMenu' src={ config } alt='בולט' />
                            <span>הגדרות מערכת</span>
                    </NavLink>

                    <NavLink
                        className={currentPage === 'money' ? 'is-active' : ''} 
                        role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                        to='/money' >
                            <img className='bulletMainMenu' src={ money } alt='בולט' />
                            <span>כספים</span>
                    </NavLink>
                    </> }

            </nav>

            <div className="credit">

                <div className="userData clear">
                    <div className="circleGrey"> { user.name.charAt(0) } </div>
                    <span>{ user.name }</span>
                </div>

                <a  href="https://waveproject.co.il/" target="_blank" rel="noopener noreferrer">
                    <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                </a>
            </div>
            </div>
        </div>

        
        
        </div>
    
    }
}
