import React, {useState} from 'react'
import { Form, Col, Row, Button } from "react-bootstrap";

import {RestUrls} from './../../../api/config'

import AddSymbolToWorker from './AddSymbolToWorker'

const PaymentNav = (props) => {

    
   const dates = getDates();

   const [selectedDates, setSelectedDates] = useState({
        year: new Date().getFullYear(),
        month: '',
   })

   const onChange = (e) => { 

        setSelectedDates({
            ...selectedDates,
            [e.target.name]: e.target.value,
        })

    }

    const onSubmit = () => { 

        props.makeCsv(selectedDates.month, selectedDates.year);

     }


     
  return (

    <>
        <Row >

            <Col md='3'>
            
            <Form.Control
                as="select"
                className="customSelect"
                disabled={false}
                type='select'
                name="month"
                onChange={onChange}
                value={selectedDates.month}
            >
                <option value="">בחירת חודש</option>

                {dates.month.map((item) => {
                if (true) {
                    return (
                    <option value={item} key={item}>
                        {item}
                    </option>
                    );
                } else return false;
                })}
            </Form.Control>

            </Col>

            <Col md='3'>

                <Form.Control
                    as="select"
                    className="customSelect"
                    disabled={false}
                    type='select'
                    name="year"
                    onChange={onChange}
                    value={selectedDates.year}
                >
                    <option value="">בחירת שנה</option>

                    {dates.years.map((item) => {
                    if (true) {
                        return (
                        <option value={item} key={item}>
                            {item}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

            </Col>

            <Col md='3'>
                <Button onClick={ onSubmit } disabled={props.ready && selectedDates.year && selectedDates.month ? false : true } variant="primary">ייצוא</Button>

            </Col>

        </Row>


    </>

  )
}

function getDates() {

    const month = [
        '01','02','03','04','05','06','07','08','09','10','11','12'
    ]

    const year = new Date().getFullYear();
    //const year = 2023;
    let years = [];

    for (let index = 2022; index <= year; index++) {
        years.push(index);
    }

    //console.log('years', years);

    return { month : month, years : years }
    
}


export default PaymentNav;