import React, {useState, useEffect} from 'react';
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import {find} from 'lodash';

export default function SingleOrderHospitalSelection(props) {

    const {dataApi, orderData,showErrorsP, changeHospital } = props;

    //console.log(orderData);

    const [formData, setFormData] = useState(defaultValueForm(orderData))
    const [showErrors, setShowErrors] = useState(false);

    useEffect(() => {

        if(!showErrors) {
            setShowErrors(showErrorsP);
        }
      
    }, [showErrorsP,showErrors]);
    

    const hospitalOptions  =  orderData ? dataApi.hospitals : dataApi.hospitalsOnOff;
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const uniqueHospital = [...new Map(hospitalOptions.map(item => [item['name'], item])).values()];

    const onChange = (e) => {

        if(e.target.name === 'hospitalId') {

            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
                divisionId: '',
            })

        } else {

            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
              })

        }

        

    }

    //console.log(uniqueHospital);

    useEffect(() => {

        if(formData.hospitalId && formData.divisionId) {


            let is_OrderPrati = find(uniqueHospital, {hospitalId : formData.hospitalId});
            is_OrderPrati = is_OrderPrati.is_OrderPrati === '0' ? false : true;

            const change = formData.hospitalId + '|' + formData.divisionId;
            changeHospital(change,formData.hospitalId,is_OrderPrati);

            //console.log('formData :>> ', formData);
        }
        
     
    }, [formData,changeHospital,uniqueHospital]);
    


  return <>

        <div className='input inputTypeselect '>
            <FloatingLabel label='בחר בית חולים' >
                <Form.Control
                    as="select"
                    className="customSelectInput " 
                    disabled={false}
                    isInvalid={showErrors && !formData.hospitalId}
                    type='select'
                    name="hospitalId"
                    onChange={onChange}
                    value={formData.hospitalId}
                >
                    <option value="">בחר בית חולים</option>

                    {uniqueHospital.map((item) => {
                    if (true) {
                        return (
                        <option value={item.hospitalId} key={item.id}>
                            {item.name}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>
            </FloatingLabel>
        </div>

        <div className='input inputTypeselect '>
            <FloatingLabel label='בחר סוג שירות' >
                <Form.Control
                    as="select"
                    className="customSelectInput " 
                    disabled={formData.hospitalId ? false : true}
                    isInvalid={showErrors && !formData.divisionId}
                    type='select'
                    name="divisionId"
                    onChange={onChange}
                    value={formData.divisionId}
                >
                    <option value="">בחר סוג שירות</option>

                    {hospitalOptions.map((item) => {
                    if ( item.hospitalId === formData.hospitalId) {
                        return (
                        <option value={item.divisionId} key={item.id}>
                            {item.nameDivision}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>
            </FloatingLabel>
        </div>
      
  </>;
}

function defaultValueForm(orderData) {
    return {
        
        hospitalId: orderData?.hospitalId ? orderData.hospitalId : '',
        divisionId: orderData?.hospitalDivisionId ? orderData.hospitalDivisionId : ''        
    }
  }