import React, { Component } from "react";
import Orders from "./Orders";

import SingleOrder from "./SingleOrder";
import WorkerEdit from "./WorkerEdit";

import SystemConfigIndex from "./SystemConfigIndex";
import MoneyIndex from "./Money/MoneyIndex"
import WorkersPage from "./WorkersPage";

export default class UserIndex extends Component {
  render() {
    //console.log(this.props.page);

    switch (this.props.page) {
      case "order":
        return (
          <React.Fragment>
            <SingleOrder {...this.props} />
          </React.Fragment>
        );

      case "config":
        return (
          <React.Fragment>
            <SystemConfigIndex {...this.props} />
          </React.Fragment>
        );

      case "money":
        return (
          <React.Fragment>
            <MoneyIndex {...this.props} />
          </React.Fragment>
        );
        
      case "worker":
        return (
          <React.Fragment>
            <WorkerEdit {...this.props} is_newNurse={true} />
          </React.Fragment>
        );

      case "workers":
        return (
          <React.Fragment>
            <WorkersPage {...this.props} />
          </React.Fragment>
        );

      default:
        return (
          <React.Fragment>
            <Orders {...this.props} />
          </React.Fragment>
        );
    }
  }
}
