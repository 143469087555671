import React, {useState} from 'react'

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

export default function OrderPayAddPayItemsShow(props) {

    const {payData } = props;

    const [loading, setLoading] = useState(false)


    const deletteMe = (id) => {
        
        //console.log('id :>> ', id);

        if (!loading) {

            const send = {
                itemId: id
            }
    
            sendtoAdmin('delettePayItem', 'pay', send, setLoading, props.reloadData)

        }

    }
    
    

    return (
        <div className='OrderPayAddPayItemsShow'>

            <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />
            
            <h2>{payData.payments[0]?.id ? 'פרטים נוספים' : 'פריטים לתשלום'}</h2>
            
            <ul>

                {payData.payItems?.items.map((item) => {

                    if (true) {

                        return (
                            <li value={item.id} key={item.id}>

                                {(item.id !== -1 && !payData.payments[0])&& <button onClick={()=>deletteMe(item.id)}>
                                   <i className="far fa-trash-alt"></i>
                                </button> }

                                <strong>{item.name}</strong> <span><small>₪</small>{item.SumValue}</span>
                                
                            </li>
                        );
                        } else return false;
                })} 
            
            </ul>

        </div>
    )
}


function sendtoAdmin(url, controller, objectToSend, setLoading, reloadData) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        //console.log(getData);

      if (getData.ok) {

        reloadData();
        //setRequest(getData.ok)
        //toast.success(getData.ok)
      } else if (getData.error) {

        toast.error(getData.error)
        setLoading(false)

      }
    })

    getData.catch((error) => {
      console.log(error)
      setLoading(false)
    })

    /* getData.finally(() => {
      setLoading(false)
    }) */
}