import React, { Component } from "react";


import { Accordion } from 'react-bootstrap';

import WorkerEditform from "./WorkerEditform";
import WorkerWeeklySchedule from "./WorkerWeeklySchedule"

import WorkerShifts from "./WorkerShifts"

import WorkerContracts from "./WorkerContracts"
import WorkerEditNotes from "./WorkerEditNotes"
import FilesWorkerIndex from "./filesWorker/FilesWorkerIndex";

export default class WorkerEdit extends Component {


  constructor(props) {
    super(props);

    let nurseData = this.props.nurseData ? this.props.nurseData : false;

    this.state = {

      currentSearchStatus : this.props.currentSearchStatus ? this.props.currentSearchStatus : '',
      is_newNurse : this.props.is_newNurse ? this.props.is_newNurse : false,
      nurseData: nurseData,
      loadContracts: false,
      loadWorkerNotes: false
    };
  }

  goBack = () => {
            
    //let send = this.state;

    let send = {
      
      currentSearchStatus : this.state.currentSearchStatus,
      editNurseData : false,
      reloadOrders : true

    }

    this.props.goBack(send);

  }
  
  

  setLoad = (stateName) => {
      
      if(!this.state[stateName]) {

          this.setState({
            [stateName] : true
          })

      }

  };

  render() {
    


    return (
      <div>

        {this.state.is_newNurse || this.state.responseNewNurse ? 
      
            <div className="smallWrapper headerWorkers">
                <h1 className="titleStyle">עובדים</h1>
              </div>

        : false }
      
        

        <div className="smallWrapper noTopMarg" >

          <div className="WorkerEditPage">
            
            {this.props.goBack ? <div className="goBackBtnCont">
              <button
                onClick={() => this.goBack()}
                className="goBackBtn bounce2"
              >
                « חזור
              </button>
            </div> : false }

            
              <div className="accordionSec">
                <Accordion className="accordions acc1"  defaultActiveKey="accordion0" >
                  
                  <Accordion.Item eventKey="accordion0" >

                    <Accordion.Header>
                      {this.state.nurseData ? <span>עריכת פרטים</span> : <span>הוספת עובד</span> }
                    </Accordion.Header>

                    <Accordion.Body>

                      {/* PASAR TODO A UN COMPONENTE! */}  
                      <WorkerEditform nurseData = {this.props.nurseData ? this.props.nurseData : false } is_newNurse = { this.props.is_newNurse ? this.props.is_newNurse : false } />
                      
                    </Accordion.Body>

                  </Accordion.Item>



                  {this.state.nurseData ? <Accordion.Item eventKey="accordion3"  onClick={ () => this.setLoad('loadContracts') } >
                  
                  <Accordion.Header>
                    <span>חוזי עבודה</span>
                  </Accordion.Header>

                  <Accordion.Body>  
                      <div className="showWorkerShifts">
                        { this.state.loadContracts ? < WorkerContracts nurseData = { this.state.nurseData } /> : false }
                      </div>
                  </Accordion.Body>

                  </Accordion.Item> : false }


                  {this.state.nurseData ? <Accordion.Item eventKey="accordion2"  onClick={ () => this.setLoad('loadWorkerShifts') } >
                  
                  <Accordion.Header>
                    <span>משמרות השבוע</span>
                  </Accordion.Header>

                  <Accordion.Body>  
                      <div className="showWorkerShifts">
                        { this.state.loadWorkerShifts ? < WorkerShifts nurseData = { this.state.nurseData } /> : false }
                      </div>
                  </Accordion.Body>

                  </Accordion.Item> : false }



                  {this.state.nurseData ? <Accordion.Item eventKey="accordion1" onClick={ () => this.setLoad('loadWorkerSchedule') } >

                    <Accordion.Header>
                      <span>הגדרת לוז שבועי</span>
                    </Accordion.Header>

                    <Accordion.Body>  
                      <div className="NurseDataCont">
                        { this.state.loadWorkerSchedule ? < WorkerWeeklySchedule nurseData = { this.state.nurseData } /> : false }
                      </div>
                    </Accordion.Body>

                  </Accordion.Item> : false }

                  {this.state.nurseData ? <Accordion.Item eventKey="accordion5" onClick={ () => this.setLoad('loadFilesWorker') } >

                    <Accordion.Header>
                      <span>מסמכים</span>
                    </Accordion.Header>

                    <Accordion.Body>  
                      <div className="NurseDataCont">
                        { this.state.loadFilesWorker && <FilesWorkerIndex nurseData = {this.state.nurseData} /> }
                      </div>
                    </Accordion.Body>

                  </Accordion.Item> : false }


                  {this.state.nurseData ? <Accordion.Item eventKey="accordion6" onClick={ () => this.setLoad('loadWorkerNotes') } >

                    <Accordion.Header>
                      <span>תיק אישי / הערות</span>
                    </Accordion.Header>

                    <Accordion.Body>  
                      <div className="NurseDataCont">
                        { this.state.loadWorkerNotes ? < WorkerEditNotes referId = { this.state.nurseData.id } notesType='nurse' /> : false }
                      </div>
                    </Accordion.Body>

                  </Accordion.Item> : false }

                  

                </Accordion>

             </div>

            
            
          </div>

      </div>
    </div>
    );
    
  }

  
}
