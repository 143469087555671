import React, { useState } from 'react'
import ConfigUserData from './ConfigUserData'

import { Accordion } from 'react-bootstrap';
import WorkerWeeklySchedule from '../UserEnv/WorkerWeeklySchedule'

import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import edit from './../../../img/nathan/svg/config/edit.svg'
import WorkerEditForm from "./../UserEnv/WorkerEditform"


export default function ConfigIndex() {

    let user = JSON.parse(localStorage.getItem("userData"));

    const [responseNurseData, setResponseNurseData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadWorkerSchedule, setLoadWorkerSchedule] = useState(false);

    const [editMode, setEditMode] = useState(false)

    
    if(!loading && !responseNurseData) {

        const send = { nurseId : user.id.replace('-',''), configPage : '1' };
        sendtoAdmin('getNurseData','NurseEnv', send ,setResponseNurseData,setLoading);

    }
    
    const hadleEdit = () => {

        if(!editMode) {
            setEditMode(true);
        } else {
            window.open('/config','_self');
        }

        

    }

    return (
        <div className='ConfigIndex animate__animated animate__fadeIn'>

            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            <h1>הגדרות</h1>

            {responseNurseData ? <div className='accordionSec'>

            
                <Accordion className="accordions acc1"  defaultActiveKey="accordion0" >

                    <Accordion.Item eventKey="accordion0" >
                        
                        <Accordion.Header>
                            <span>פרטי העובד</span>
                        </Accordion.Header>

                        <Accordion.Body>
                            <div className='userConfDataCont' >
                                <button className="editMode" onClick={ hadleEdit }>
                                    {!editMode ? <img src={edit} alt='edit'/> : <span>&laquo; חזור</span>}
                                </button>
                                
                                {!editMode ? <ConfigUserData userData = { responseNurseData.nurseData } /> :
                                <WorkerEditForm nurseData={responseNurseData.nurseData} is_newNurse={false} configNurse={true} />
                                }
                            </div>
                        </Accordion.Body>

                    </Accordion.Item>


                    {responseNurseData.nurseData ? <Accordion.Item eventKey="accordion1" onClick={ () => setLoadWorkerSchedule(true) } >
                    
                        <Accordion.Header>
                            <span>הגדרת לוז שבועי</span>
                        </Accordion.Header>

                        <Accordion.Body>  
                        <div className="NurseDataCont">
                            { loadWorkerSchedule ? < WorkerWeeklySchedule nurseData = { responseNurseData.nurseData } /> : false }
                        </div>
                        </Accordion.Body>

                    </Accordion.Item> : false }
                </Accordion>
            </div>  : false }
            
            
            
        </div>
    )
}
