import React from 'react'
import WorkerPaysIndex from './WorkerPaysIndex'

export default function NursePayPrati(props) {

    const {orderData, payData} = props
  
    return (
    <div className='nursePayPrati'>
        <WorkerPaysIndex orderData={orderData} payData={payData} notShowItems = {true} reloadOrder = {false} reloadResponse = {false} responsePayData={false}/>
    </div>
  )
}
