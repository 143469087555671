import React, {useState} from 'react'
import { Accordion } from 'react-bootstrap';


import SystemHospitalsIndex from './SystemHospitalsIndex';
import SystemHospitalsUsers from './SystemHospitalsUsers';
import SystemContractsIndex from './SystemContractsIndex';

export default function SystemConfigIndex() {

    const [loadHospital, setLoadHospital] = useState(false);
    const [loadContracts, setLoadContracts] = useState(false);

    const [loadHospitalUsers, setLoadHospitalUsers] = useState(false);

    const setLoad = (stateName, setStateName) => {
      
        if(!stateName) {
            
            setStateName(true);
        }

    };
        

    return (
        <div className="SystemConfigIndex animate__animated animate__fadeIn">
            <div className='smallWrapper'>
                <h1 className="titleStyle">הגדרות מערכת</h1>

                <div className='accordionSec shortContent'>

                    {/* defaultActiveKey="accordion0" */}
                    <Accordion className="accordions acc1"   >

                        <Accordion.Item eventKey="accordion0" onClick={()=>setLoad(loadHospital, setLoadHospital)}>
                            
                            <Accordion.Header>
                                <span>בתי חולים</span>
                            </Accordion.Header>

                            <Accordion.Body>
                                
                                {loadHospital && <SystemHospitalsIndex /> }
                            </Accordion.Body>

                        </Accordion.Item>

                        <Accordion.Item eventKey="accordion2" onClick={()=>setLoad(loadContracts, setLoadContracts)}>
                            
                            <Accordion.Header>
                                <span>חוזים עובדים</span>
                            </Accordion.Header>

                            <Accordion.Body>
                                {loadContracts && <SystemContractsIndex /> }
                            </Accordion.Body>

                        </Accordion.Item>

                        <Accordion.Item eventKey="accordion1" onClick={()=>setLoad(loadHospitalUsers, setLoadHospitalUsers)}>
                            
                            <Accordion.Header>
                                <span>משתמשים בתי חולים</span>
                            </Accordion.Header>

                            <Accordion.Body>
                                
                                {loadHospitalUsers && <SystemHospitalsUsers /> }
                            </Accordion.Body>

                        </Accordion.Item>
                        
                    </Accordion>
                </div>
            </div>
        </div>
    )
}
