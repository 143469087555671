import React, { useState, useEffect } from 'react'

import { convertDate } from '../../../Components/models/ordersFuncNathan'

import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css?v=6";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';



registerLocale('he', he);


export default function ShowDayPickerRetro(props) {


    const { hadleDateRetro } = props

    const today = new Date();
    let current_datetime = new Date(today.toString().replace(/-/g, '/'));

    const [datepickerValue, setDatepickerValue] = useState(current_datetime);


    useEffect(() => {

        const today = new Date();
        const current_datetime = new Date(today.toString().replace(/-/g, '/'));

        if(!datepickerValue) {
            setDatepickerValue(today);
        }

        if(datepickerValue) {

            if(convertDate(datepickerValue) !== convertDate(current_datetime)) {
                

                var days = ['ראשון', 'שני', 'שלישי', 'רביעי', 'חמישי', 'שישי', 'שבת'];
                var d = new Date(datepickerValue);
                var dayName = days[d.getDay()];


                hadleDateRetro(convertDate(datepickerValue),dayName);
                //console.log(convertDate(datepickerValue));

            }

        }
        
            
      
    }, [datepickerValue, hadleDateRetro])
    


  return (
    <FormControl className='dateWrapper' >

        <DatePicker 
            showYearDropdown = {true}
            selected={datepickerValue}
            dateFormat='dd/MM/yy'
            locale="he"
            onChange={(date) => {
                setDatepickerValue(date);
            }}
        />

    </FormControl>
  )
}
