import validator from "validator";
//npm install validator

//https://github.com/validatorjs/validator.js

export function ValidateData(data, rules) {
    let errors = {};
    Object.keys(data).forEach(field => {
        if (rules.hasOwnProperty(field)) {
            let fielderrors = [];
            let val = data[field];
            if (rules[field].true) {
                if (!val) {
                    fielderrors.push("חייב לסמן");
                }
            } else {
                if (rules[field].required && validator.isEmpty(val)) {
                    fielderrors.push("שדה חובה");
                }
                if (!validator.isEmpty(data[field])) {
                    if (rules[field].minlength 
                            && !validator.isLength(val, rules[field].minlength)) {
                        fielderrors.push(`נא להזין לפחות ${rules[field].minlength}` 
                            + " תווים");
                    } else if (rules[field].minlength 
                        && !validator.isByteLength(val, 0, rules[field].maxlength)) {
                        fielderrors.push(`נא להזין לפחות ${rules[field].maxlength}` 
                            + " תווים");
                    }

                    /* https://www.npmjs.com/package/validator */


                    //&& !val.includes(' ')

                    if (rules[field].alpha && ( (!validator.isAlpha(val, 'he', {ignore: ' '} ) && !validator.isAlpha(val, 'en-US',{ignore: ' '} ))  ) ) {
                        fielderrors.push("נא להזין רק אותיות");
                    }

                    if ( rules[field].tz && !checkTz(val)  ) {
                        fielderrors.push("מספר תעודת זהות לא תקין");
                    }

                    if (rules[field].numbers && !validator.isNumeric(val)) {
                        fielderrors.push("נא להזין רק מספרים");
                    }

                    if (rules[field].alphanumeric && !validator.isAlphanumeric(val)) {
                        fielderrors.push("Enter only letters and Numbers");
                    }

                    if (rules[field].email && !validator.isEmail(val)) {
                        fielderrors.push("נא להזין כתובת מייל חוקית");                        
                    }
                    if (rules[field].equals 
                            && !validator.equals(val, data[rules[field].equals])) {
                        fielderrors.push("הערכים אינם תואמים");                                                
                    }
                }
            }
            if (fielderrors.length > 0) {
                errors[field] = fielderrors;
            }
        }
    })
    return errors;
}





function checkTz(str) {

    //INPUT VALIDATION

   // Just in case -> convert to string
   var IDnum = String(str);

   // Validate correct input
   if ((IDnum.length > 9) || (IDnum.length < 5))
      return false;
   if (isNaN(IDnum))
      return false;

   // The number is too short - add leading 0000
   if (IDnum.length < 9)
   {
      while(IDnum.length < 9)
      {
         IDnum = '0' + IDnum;         
      }
   }

   // CHECK THE ID NUMBER
   var mone = 0, incNum;
   for (var i=0; i < 9; i++)
   {
      incNum = Number(IDnum.charAt(i));
      incNum *= (i%2)+1;
      if (incNum > 9)
         incNum -= 9;
      mone += incNum;
   }
   if (mone%10 === 0)
      return true;
   else
      return false;

}