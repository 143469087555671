import React, { Component } from 'react'

import UserViewComposer from '../views/UserViewComposer'
import NurseViewComposer from '../views/NurseViewComposer'

export default class SiteConnector extends Component {
  render() {
    
    if (this.props.router === 'userPages') {
      
      //open pages on UserIndex
      return <UserViewComposer {...this.props} />

    } else if (this.props.router === 'router2') {

      return <NurseViewComposer {...this.props} />

    } else if (this.props.router === 'logout') {

      localStorage.setItem('userData', '')
      window.open('/', '_self')
      return <p>טוען...</p>

    }

    /* else if(this.props.page === 'mekomotSherutPages') {

            return <PagesMekomotSherut {...this.props} />

        }
        
        else {

            return <Pages {...this.props} />

        } */
  }
}
