import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import SearchAutocompleteAndDbFocus from "./../../api/SearchAutocompleteAndDbFocus";

import { FormValidator } from "./validation/FormValidator";
import { ValidationMessage } from "./validation/ValidationMessage";
import { ValidateForm } from "./validation/wholeFormValidation";

export default class CustomFloatInput extends Component {
  constructor(props) {
    super(props);
    this.setInputFocus = this.setInputFocus.bind(this);

    const config = this.props;

    this.state = {
      inputValue: config.value ? config.value : "",
      isError: false,
      selectOptions: config.selectOptions ? config.selectOptions : [],
      loading: false,
      response: false,
      showInput: false,
    };

    this.rules = {
      inputValue: config.validationRules,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);
    });
  };

  isOK = (value) => {
    //console.log(this.state.inputValue);
    //console.log(value);

    const flag =
      (this.props.validationRules.required && this.state.inputValue) ||
      !this.props.validationRules.required
        ? true
        : false;

    if ((value.inputValue && value.inputValue.length > 0) || !flag) {
      this.setState(
        {
          isError: true,
        },
        () => this.props.updateValue({ [this.props.name]: "" })
      );
    } else {
      this.setState(
        {
          isError: false,
        },
        () =>
          this.props.updateValue({ [this.props.name]: this.state.inputValue })
      );
    }

    //console.log(value);
  };

  componentDidUpdate() {
    if (!this.state.isError && this.props.checkInputs) {
      this.isOK(this.state.inputValue);
      this.props.checked();
    }
  }

  handleChangeSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, function () {
      //console.log(this.state)
    });
  };

  setInputFocus() {
    this.setState({ showInput: true }, () => {
      ReactDOM.findDOMNode(this.myInputRef).focus();
    });
  }

  handleSearchSelected = (item) => {
    const field = this.props.dbParams.valueField;
    this.setState({ inputValue: item[field], isError: false }, function () {
      this.isOK(this.state.inputValue);
      //console.log(this.state);
    });
  };

  render() {
    const config = this.props;

    const zIndexClass = config.zIndexClass ? config.zIndexClass : "";

    return (
      <div className={"input inputType" + config.typeInput + " " + zIndexClass}>
        {config.typeInput === "text" ||
        config.typeInput === "password" ||
        config.typeInput === "number" ? (
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            <FloatingLabel
              controlId={config.name}
              label={config.placeholder}
              /* className="mb-3" */
            >
              <Form.Control
                className={"customFloatInput " + config.cssClass}
                isInvalid={this.state.isError}
                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                type={config.typeInput}
                name="inputValue"
                disabled={this.props.disabled ? true : false}
                placeholder={config.placeholder}
                onChange={this.handleChange}
                value={this.state.inputValue}
              />
              <Form.Control.Feedback type="invalid">
                <ValidationMessage field="inputValue" />
              </Form.Control.Feedback>
            </FloatingLabel>
          </FormValidator>
        ) : (
          false
        )}

        {config.typeInput === "searchDB" ? (
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            <div
              className={
                this.state.isError
                  ? "customInputSearch errorClass"
                  : "customInputSearch"
              }
            >
              <SearchAutocompleteAndDbFocus
                keys={["name"]}
                dbParams={{
                  functionName: config.dbParams.function,
                  controller: config.dbParams.controller,
                }}
                placeholder={config.placeholder}
                submit={this.handleSearchSelected}
                DBItems="app/getCities4Select"
              />
            </div>

            <Form.Control.Feedback type="invalid">
              <ValidationMessage field="inputValue" />
            </Form.Control.Feedback>
          </FormValidator>
        ) : (
          false
        )}

        {config.typeInput === "select" ? (
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            <FloatingLabel
              controlId={config.name}
              label={config.placeholder}
              /* className="mb-3" */
            >
              <Form.Control
                as="select"
                className={"customSelectInput " + config.cssClass}
                disabled={config.disabled ? config.disabled : false}
                isInvalid={this.state.isError}
                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                type={config.typeInput}
                name="inputValue"
                onChange={this.handleChangeSelect}
                value={this.state.inputValue}
                onFocus={this.populateSelect}
              >
                <option value="">{config.placeholder}</option>

                {this.state.selectOptions.map((item) => {
                  if (true) {
                    let keyNumber =
                      item.id + "_" + Math.floor(Math.random() * 99999);

                    return (
                      <option value={item.id} key={keyNumber}>
                        {item.value}
                      </option>
                    );
                  } else return false;
                })}
              </Form.Control>
            </FloatingLabel>

            <Form.Control.Feedback type="invalid">
              <ValidationMessage field="inputValue" />
            </Form.Control.Feedback>
          </FormValidator>
        ) : (
          false
        )}

        {config.typeInput === "date" ? (
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            {this.state.showInput ? (
              <FloatingLabel
                controlId={config.name}
                label={config.placeholder}
                /* className="mb-3" */
              >
                <Form.Control
                  ref={(c) => (this.myInputRef = c)}
                  className={"customFloatInput " + config.cssClass}
                  isInvalid={this.state.isError}
                  /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                  type="date"
                  name="inputValue"
                  placeholder={""}
                  onChange={this.handleChange}
                  value={this.state.inputValue}
                />
                <Form.Control.Feedback type="invalid">
                  <ValidationMessage field="inputValue" />
                </Form.Control.Feedback>
              </FloatingLabel>
            ) : (
              <button
                onClick={this.setInputFocus}
                className={
                  !this.state.isError
                    ? "fakeBtnInput"
                    : "fakeBtnInput errorClass"
                }
                type="button"
              >
                {this.state.inputValue
                  ? this.props.dateText
                  : config.placeholder}{" "}
              </button>
            )}
          </FormValidator>
        ) : (
          false
        )}

        {config.typeInput === "time" ? (
          <FormValidator
            data={this.state}
            rules={this.rules}
            isOK={(value) => this.isOK(value)}
            validateForm={ValidateForm}
          >
            {this.state.showInput || this.props.showValue ? (
              <div>
                <label style={{ fontSize: "14px" }}>
                  {this.props.dateText}
                </label>
                <Form.Control
                  ref={(c) => (this.myInputRef = c)}
                  className={"customFloatInput " + config.cssClass}
                  isInvalid={this.state.isError}
                  /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                  type="time"
                  name="inputValue"
                  onChange={this.handleChange}
                  value={this.state.inputValue}
                />
                <Form.Control.Feedback type="invalid">
                  <ValidationMessage field="inputValue" />
                </Form.Control.Feedback>
              </div>
            ) : (
              <button
                onClick={this.setInputFocus}
                className={
                  !this.state.isError
                    ? "fakeBtnInput"
                    : "fakeBtnInput errorClass"
                }
                type="button"
              >
                {this.state.inputValue
                  ? this.props.dateText
                  : config.placeholder}{" "}
              </button>
            )}
          </FormValidator>
        ) : (
          false
        )}
      </div>
    );
  }
}
