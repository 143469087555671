import React, { useState } from 'react';
import { toast } from 'react-toastify' //npm i react-toastify
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

export default function  WorkerEditHospitalsLinks(props) {

    const { selectOptionsDb, nurseData } = props;

    //console.log(selectOptionsDb);

    //console.log(nurseData);

    const nurseId =  selectOptionsDb?.nurseAllData?.id ? selectOptionsDb?.nurseAllData?.id : nurseData?.id;

    //console.log(nurseId);

    //const {dataApi, orderData,showErrorsP, changeHospital } = props;

    //console.log(orderData);

    const [formData, setFormData] = useState(defaultValueForm())
    const [showErrors, ] = useState(false);

    const [hospitalList, setHospitalList] = useState(false);
    
    //console.log(hospitalList);

    //const [response, setRequest] = useState('')
    const [loading, setLoading] = useState(false)


    if(!loading && !hospitalList && nurseId ) {

        const send = {
            nurseId : nurseId
           };

       sendtoAdmin('getNurseHospitals','nurses', send ,setHospitalList,setLoading);

    }

    const onChange = (e) => {

        if(e.target.name === 'hospitalId') {

            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
                divisionId: '',
            })

        } else {

            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
              })

        }

        

    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
    const addHospital = () => { 

        //console.log(formData);
        formData.nurseId = nurseId;
        sendtoApi('linkNurses', 'Nurses', formData, setLoading, setFormData, setHospitalList);
    }


    const unlinkHospital = (item) => {

        //console.log(item);
        item.nurseId = nurseId;
        sendtoApi('unlinkNurses', 'Nurses', item, setLoading, setFormData, setHospitalList);

    }


    const flagNewHospital = (formData.hospitalId && formData.divisionId) ? true : false;

    //console.log(formData);

  return <>
        
        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

        <div className='input inputTypeselect '>
            <FloatingLabel label='בחר בית חולים' >
                <Form.Control
                    as="select"
                    className="customSelectInput " 
                    disabled={false}
                    isInvalid={showErrors && !formData.hospitalId}
                    type='select'
                    name="hospitalId"
                    onChange={onChange}
                    value={formData.hospitalId}
                >
                    <option value="">בחר בית חולים</option>

                    {selectOptionsDb.hospitals.map((item) => {
                    if (true) {
                        return (
                        <option value={item.id} key={item.id}>
                            {item.value}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>
            </FloatingLabel>
        </div>

        <div className='input inputTypeselect '>
            <FloatingLabel label='בחר סוג שירות' >
                <Form.Control
                    as="select"
                    className="customSelectInput " 
                    disabled={formData.hospitalId ? false : true}
                    isInvalid={showErrors && !formData.divisionId}
                    type='select'
                    name="divisionId"
                    onChange={onChange}
                    value={formData.divisionId}
                >
                    <option value="">בחר סוג שירות</option>

                    {selectOptionsDb.hospitalDivisions.map((item) => {
                    if ( true ) {
                        return (
                        <option value={item.id} key={item.id}>
                            {item.value}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>
            </FloatingLabel>
        </div>

        <button  type='button' style={{ padding: '11px 60px'}} onClick={addHospital} className={`defaultBtnPic noPic ${!flagNewHospital ? 'disabledBtn' : 'animate__animated animate__bounceIn'}`} disabled={!flagNewHospital}>הוספת בית חולים לעובד/ת</button>
      
        {(hospitalList && hospitalList?.hospitals[0]?.id) && <div className='hospitalHospitalList'>

            <h4 className=''>בתי חולים קיימים</h4>
            
            <ul className='showHospitals'>
            {hospitalList?.hospitals.map((item) => {
                if (true) {
                    return (
                    <li key={item.hospitalId + item.hospitalDivisionId}>
                        <button className='deleteBtn' onClick={ () => unlinkHospital(item) }><i className="far fa-trash-alt"></i></button>
                        <strong>{item.hospital}</strong> <span>{item.hospitalDivision}</span>
                    </li>
                    );
                } else return false;
            })}
            </ul>

        </div> }
        
  </>;
}

function defaultValueForm() {
    return {
        
        hospitalId: '',
        divisionId: ''        
    }
  }


  function sendtoApi(url, controller, objectToSend, setLoading, setFormData,setHospitalList) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {
      if (getData.ok) {

        setFormData(defaultValueForm);
        setHospitalList(false);
        toast.success(getData.ok);

      } else if (getData.error) {
        toast.error(getData.error)
      }
    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }
  