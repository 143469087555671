import React, { Component } from "react";
import { InputGroup, FormControl } from "react-bootstrap";
import getDataFromApi from "../../api/getDataFromApi";

export default class WorkerLinkSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {

      priority: this.props.currentSearchStatus.priority,
      district: this.props.currentSearchStatus.district,
      freeSearch:this.props.currentSearchStatus.freeSearch,

      response: false,
    };
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);
      let updateValues = {
        freeSearch: this.state.freeSearch,
      };

      this.props.updateValues(updateValues);
    });
  };

  handleSelect = (event) => {
    this.setState({ [event.target.name]: event.target.value }, () => {
      //console.log(this.state);

      let updateValues = {
        priority: this.state.priority,
        district: this.state.district,
      };

      this.props.updateValues(updateValues);
    });
  };

  componentDidMount() {
    let sendObj = {};
    this.sendtoAdmin("workerLinkFields", "app", sendObj, "response");
  }

  render() {
    return (
      <div className="WorkerLinkSearch">
        {this.state.response ? (
          <div className="WorkerLinkSearchCont animate__animated animate__fadeIn">
            <h3>סינון</h3>

            <div className="inputs">
              <InputGroup className="inputGroup">
                <FormControl
                  as="select"
                  /* 'inputPrepend customSelect' */
                  className="customSelectInput noLabel"
                  onChange={this.handleSelect}
                  value={this.state.priority}
                  name="priority"
                >
                  <option value="">תעדוף...</option>

                  {this.state.response.workerPriorities.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item.id} key={item.id}>
                          {item.value}
                        </option>
                      );
                    } else return false;
                  })}
                </FormControl>
              </InputGroup>

              <InputGroup className="inputGroup">
                <FormControl
                  as="select"
                  /* 'inputPrepend customSelect' */
                  className="customSelectInput noLabel"
                  onChange={this.handleSelect}
                  value={this.state.district}
                  name="district"
                >
                  <option value="">מחוז...</option>

                  {this.state.response.districts.map((item) => {
                    if (true) {
                      /* currentState={this.state} */
                      return (
                        <option value={item.id} key={item.id}>
                          {item.value}
                        </option>
                      );
                    } else return false;
                  })}
                </FormControl>
              </InputGroup>

              <InputGroup className="inputGroup">
                <FormControl
                  className="customInput searchIco"
                  placeholder="חיפוש חופשי"
                  onChange={this.handleChange}
                  value={this.state.freeSearch}
                  name="freeSearch"
                ></FormControl>
              </InputGroup>
            </div>
          </div>
        ) : (
          false
        )}
      </div>
    );
  }

  sendtoAdmin = (
    url,
    controller,
    objectToSend,
    setStateName = "data",
    auth = "all"
  ) => {
    //console.log('sending...');

    this.setState({ loading: true });

    const getData = getDataFromApi(url, objectToSend, controller, auth);

    getData.then((getData) =>
      this.setState({ [setStateName]: getData, loading: false }, function () {
        //console.log(getData);
        /* if(getData.ok) {
                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
            } */
      })
    );
  };
}
