// src/components/Payments.js

import React, { useState } from 'react';
import { sendtoAdmin } from '../../../api/getDataFromApi';
import PaymentNav from './paymentNav';

function filterPaymentsByDate(paymentsArray, month, year) {
    return paymentsArray.filter(payment => {
        // Parse the date from the 'created_at' field
        const createdAt = new Date(payment.created_at);
        
        // Extract the month and year from the date
        const createdMonth = createdAt.getMonth() + 1; // Month is 0-indexed, adjust by adding 1
        const createdYear = createdAt.getFullYear();

        // Return true if the month and year match the criteria, false otherwise
        return createdMonth === month && createdYear === year;
    });
}

function extractRelevantData(paymentsArray, month, year) {
    const payments = filterPaymentsByDate(paymentsArray.data, month, year)
    return payments.map(payment => {
        // Parse the buildTranzila JSON to access its properties
        const buildTranzila = JSON.parse(payment.buildTranzila);
        
        return {
            createdDate: payment.created_at,
            sumValue: payment.SumValue,
            orderId: payment.orderId,
            documentNumber: buildTranzila.document.number,
            name :payment.patientName,
        };
    });

}

function downloadCSV(dataArray, filename = 'Export.csv') {
    // Create CSV content
    function arrayToCSV(data) {
        if (data.length === 0) return '';

        // Extract headers
        const headers = ["תאריך תשלום", "סכום", "מזהה הזמנה", "מספר חשבונית", "שם"];

        // Map each object to a CSV row
        const rows = data.map(obj =>
            Object.values(obj).map(value =>
                `"${value.toString().replace(/"/g, '""')}"` // Handle values containing commas or quotes
            ).join(',')
        );

        // Combine headers and rows
        return '\uFEFF' + [headers].concat(rows).join('\n');
    }

    // Convert array to CSV
    const csvContent = arrayToCSV(dataArray);

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    // Create a link element to download the blob
    const link = document.createElement('a');
    if (link.download !== undefined) { // Feature detection
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}




function Payments() {
    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);

    if(!loading && !responseData) {

        const send = {
             id : ''
            };

        sendtoAdmin('fetchAllPayments','pay', send ,setResponseData,setLoading);

    }

    const makeCsv = (month, year) => {
        if (responseData){
            const dataToCsv = extractRelevantData(responseData, parseInt(month), parseInt(year));
            console.log(dataToCsv);
            
// Call the function to download the CSV file
        downloadCSV(dataToCsv, `${month}-${year}.csv`);

        }
        
    }


    return (
        <PaymentNav makeCsv={makeCsv} ready={responseData}/>
    )

}

export default Payments;
