import React, { Component } from 'react'
import WorkersTD from './WorkersTD'
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

export default class WorkersList extends Component {

    

    constructor(props) {

        super(props)
    
        this.state = {
            
            currentOrder : { name: this.props.currentOrder.name, order : this.props.currentOrder.order },


            priority :  { name: 'priority', order : 'ASC' },
            district :  { name: 'district', order : 'ASC' },
            name :      { name: 'name', order : 'ASC' },
            phone :     { name: 'phone', order : 'ASC' },
            email :     { name: 'email', order : 'ASC' },
            
            smsMessage : ''

        }

    }

    sendNurseData(nurseData) {

        if(this.props.is_select) {

            this.props.selectNurse(nurseData.id, this.state.smsMessage );

        } else {

            this.props.editNurse(nurseData);

        }

        //console.log(nurseId);
        

    }

    changeOrder= (field) => {
        
        let order = '';

        if( field.order === 'DESC' ) {
            order = 'ASC';
        } else { order = 'DESC'; }

        let changeOrder = {
            name : field.name,
            order : order
        }

        this.setState({
            [field.name] : changeOrder,
            currentOrder : changeOrder
        }, () => {

            //console.log(this.state.currentOrder);
            this.props.changeOrder(this.state.currentOrder);

        });

    }
    

    render() {

        let menuTable = [

            { fieldName : 'priority', text : 'תעדוף' },
            { fieldName : 'district', text : 'מחוז' },

            { fieldName : 'name', text : 'שם מטפל' },
            { fieldName : 'phone', text : 'טלפון' },
            { fieldName : 'email', text : 'אימייל' }

        ]

        let nurses = this.props.nurses;

        return (
            <div>
                <div className="defaultTable workersTable">

                    {this.props.is_select && <div className='smsMessage'>
                            
                        <FloatingLabel label='מלל חופשי לשליחת SMS לעובד/ת'>
                            <Form.Control
                                className={"customFloatInput"}
                                type='text'
                                name="smsMessage"
                                disabled={false}
                                placeholder='מלל חופשי לשליחת SMS לעובד/ת'
                                onChange={(event) => {this.setState({ smsMessage : event.target.value })}}
                                value={this.state.smsMessage}
                            />
                        </FloatingLabel>
                        
                    </div>}

                    <div className="tableTR titleRow">
                        
                        <div className='tableTD'>#</div>
                        {menuTable.map(item => { if (true) {
                            /* currentState={this.state} */
                            return  <div key={ item.fieldName } className={this.state.currentOrder.name === item.fieldName ? 'tableTD active' : 'tableTD' } ><button onClick={ () => this.changeOrder(this.state[item.fieldName]) } >{ item.text } <i className={`fas fa-chevron-${ this.state[item.fieldName].order === 'DESC' ? 'up' : 'down' }`}></i></button></div> 
                        } else return false}) }
                        
                    </div>
                        
                    {   nurses.map(item => { if (true) {
                        /* currentState={this.state} */
                        return  <WorkersTD   key={ item.id } item={ item } is_select = { this.props.is_select } sendNurseData = { (data) => this.sendNurseData(data) } />
                    } else return false}) } 
                    
                </div>
            </div>
        )
    }
}
