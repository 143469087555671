import React, {useState} from 'react'
import { Form, FloatingLabel  } from 'react-bootstrap';

export default function OrderPayCreditCardForm(props) {

    const {pay, loading} = props;

    const [formData, setFormData] = useState(defaultValueForm(pay))
    const [showErrors, setshowErrors] = useState(false);

    const onChange = (e) => {

        setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        })

        //console.log('in')
    }

    const doSubmit = () => {

        setshowErrors(true);

        if(!formFlagError) {
            props.cardForm(formData);
        }
        
    }
    
    let formFlagError = (

        (!formData.cardNumber || formData.cardNumber.length < 15) ||
        !formData.cardValidityYear ||
        !formData.cardValidityMonth ||
        (!formData.cardCvv || formData.cardCvv.length < 3) ||
        (!formData.cardTz ) || /* formData.cardTz.length < 9 */
        (!formData.cardPaysCount) ||
        (!formData.cardCash)

    );

    return (
        <div className='OrderPayCreditCardForm'>

            <Form onChange={onChange} >

                {/* Fianance / PayOptions */}
                
                <div className='input cardNumber'>
                    <FloatingLabel label="מספר כרטיס אשראי" >

                        <Form.Control
                            className="customFloatInput"
                            placeholder='מספר כרטיס'
                            isInvalid={showErrors && (!formData.cardNumber || formData.cardNumber.length < 15) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="number"
                            name="cardNumber"
                        />

                    </FloatingLabel>
                </div>

                <div className='input inputDates'>

                    <FloatingLabel label="תוקף חודש" >

                        <Form.Control
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='customFloatInput customSelectInput'
                            name="cardValidityMonth"
                            isInvalid={showErrors && !formData.cardValidityMonth }
                        >

                            <option value="">תוקף חודש</option>
                            { getmonthYear().month }

                        </Form.Control>

                    </FloatingLabel>

                    
                </div>

                <div className='input inputDates'>

                    <FloatingLabel label="תוקף שנה" >

                        <Form.Control
                            as='select'
                            /* 'inputPrepend customSelect' */
                            className='customFloatInput customSelectInput'
                            name="cardValidityYear"
                            isInvalid={showErrors && !formData.cardValidityYear }
                        >

                            <option value="">תוקף שנה</option>
                            { getmonthYear().year}

                        </Form.Control>

                    </FloatingLabel>
                    
                </div>



                <div className='input CVV'>
                    <FloatingLabel label="cvv" >

                        <Form.Control
                            className="customFloatInput "
                            placeholder='cvv'
                            isInvalid={showErrors && (!formData.cardCvv || formData.cardCvv.length < 3) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="number"
                            name="cardCvv"
                        />

                    </FloatingLabel>
                </div>


                <div className='input TZ'>
                    <FloatingLabel label="ת.ז" >

                        <Form.Control
                            className="customFloatInput "
                            placeholder='ת.ז'
                            isInvalid={showErrors && (!formData.cardTz || formData.cardTz.length < 9) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="number"
                            name="cardTz"
                        />

                    </FloatingLabel>
                </div>

                <div className='input'>
                    <FloatingLabel label="תשלומים" >

                        <Form.Control
                            className="customFloatInput "
                            placeholder='תשלומים'
                            isInvalid={showErrors && (!formData.cardPaysCount) }
                            /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                            type="number"
                            name="cardPaysCount"
                            value={formData.cardPaysCount}
                            onChange={onChange}
                        />

                    </FloatingLabel>
                </div>

                <div className='centerTotal'>

                    <div className='input total'>
                        <FloatingLabel label="סכום" >

                            <Form.Control
                                className="customFloatInput "
                                placeholder='סכום'
                                isInvalid={showErrors && (!formData.cardCash) }
                                /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                                type="number"
                                name="cardCash"
                                value={formData.cardCash}
                                onChange={onChange}
                            />

                        </FloatingLabel>
                    </div>

                    <button type="button" disabled={loading} className={`defaultBtnPic noPic ${formFlagError ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} ><span>תשלום</span></button>

                </div>

            </Form>
            
        </div>
    )
}

function defaultValueForm(pay) {

    //console.log(pay);

    return {
        cardNumber : '',
        cardValidityMonth : '',
        cardValidityYear : '',
        cardCvv : '',
        cardTz : '',
        cardPaysCount : 1,
        cardCash : pay ? pay : ''
    }

}

function getmonthYear() {

    const month = [];
    const year = [];
    
    let number;

    for (var i = 1; i <= 12; i++) {

        if ( i >= 0 && i < 10 ) {number = '0' + i;} else {number = i;}
        month.push(<option value={number} key={i}>{number}</option>)

    };

    let currentYear = new Date().getFullYear().toString().substr(-2);

    currentYear = parseInt(currentYear);
    //console.log(currentYear);

    for (i = currentYear; i <= (currentYear + 10); i++) {
        if ( i >= 0 && i < 10 ) {number = '0' + i;} else {number = i;}
        year.push(<option value={number} key={i}>20{number}</option>)
    };

    return {
        month : month,
        year: year
    }
    
}

