import React, { Component } from 'react'
import edit from './../../../img/nathan/svg/edit.svg'
import select from './../../../img/nathan/svg/addBlue.svg'

export default class WorkersTD extends Component {
    render() {

        let item = this.props.item;

        return (
            <div onClick={ ()=> this.props.sendNurseData(item) } className={`tableTR ${item.orderStatus === '0' ? 'alert' : ''}`} >
                <div className='tableTD'> { item.id } </div>
                
                <div className='tableTD'> 
                    <div className={`showPriority colorId${item.priorityId}`}> { item.priority } </div>
                </div>

                <div className='tableTD'> { item.district } </div>
                
                <div className='tableTD'> { item.name } </div>
                <div className='tableTD'> { item.phone } </div>
                
                <div className='tableTD'>
                    
                    <span className='middle'>{ item.email }</span>
                    <button className="editBtn selectPic"><img src={ this.props.is_select ? select : edit } alt='ערוך'/></button>

                </div>
            </div>
        )
    }
}
