import React from 'react'
import { Form } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0
import { map } from "lodash"
import { cancelOrderOptions } from '../../api/cancelOrderOptions';

export default function CancelOrderDropdown(props) {

    const {closeOrder} = props;


    const onChange = (e) => {

        //console.log('selectChange >', e.target.value);
        closeOrder('ביטול הזמנה',e.target.value);

    }

  return (
    <div className='cancelOrderDropdown'>
        <div className='input inputTypeselect '>
            
                <Form.Control
                    as="select"
                    //className='defaultBtnPic noPic grey noPaddcance'
                    className="customSelectInput " 
                    //disabled={formData.hospitalId ? false : true}
                    //isInvalid={showErrors && !formData.divisionId}
                    type='select'
                    name="divisionId"
                    onChange={onChange}
                    //value={formData.divisionId}
                >
                    <option value="">ביטול הזמנה...</option>
                    
                    {map(cancelOrderOptions, item => {
              
                        return (
                            <option value={item.id} key={item.id}>{item.text}</option>
                        )
                    
                    })}
                    
                    

                    
                </Form.Control>
            
        </div>
    </div>
    
  )
}
