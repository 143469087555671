import React, {useState} from 'react';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'
import validator from "validator";
import { Form, FloatingLabel } from 'react-bootstrap';

import SystemHospitalsShowUsers from './SystemHospitalsShowUsers'

export default function SystemHospitalsUsers() {

    const [responseHospitals, setResponseHospitals] = useState(false);
    const [loading, setLoading] = useState(false)

    const [formData, setFormData] = useState(defaultValueForm())
    const [showErrors, setshowErrors] = useState(false);

    if(!loading && !responseHospitals) {

        sendtoAdmin('getHospitalsAndUsers','app', {} ,setResponseHospitals,setLoading);

    }

    const onChange = (e) => {

        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        })

    }

    const doSubmit = () => {

        setshowErrors(true);

        if (!formFlagError) {

            const send = formData;
            //console.log('send :>> ', send);


            if (!loading) {

                makeUserAPI('makeHospitalUser', 'users', send, setLoading, setFormData, setResponseHospitals, setshowErrors)

            }


        }

    }

    let formFlagError = (
        !formData.name || 
        !formData.usernameHospital  || !validator.isAlphanumeric(formData.usernameHospital) || !validator.isLength(formData.usernameHospital, 4) ||
        (!formData.password || !validator.isLength(formData.password, 4)    || !validator.isAlphanumeric(formData.password) )

        
        );


  return <div className='SystemHospitalsUsers accordeonContent'>
      
      <h2>הוספת משתמשים</h2>
      <img src={loader} alt="loader" className={!loading ? 'loader' : 'loader active animate__animated animate__fadeIn'} />


        <div className='inputs'>
            <div className='input'>
                <FloatingLabel label="שם מלא" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='שם מלא'
                        isInvalid={showErrors && (!formData.name)}
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={onChange}
                    />
                </FloatingLabel>


            </div>

            <div className='input'>
                <FloatingLabel label="טלפון נייד להתראות" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='טלפון נייד להתראות'
                        //isInvalid={showErrors && (!formData.phone)}
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={onChange}
                    />
                </FloatingLabel>


            </div>

            <div className='input'>
                <FloatingLabel label="שם משתמש באנגלית" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='שם משתמש באנגלית'
                        isInvalid={showErrors && (!formData.usernameHospital || !validator.isAlphanumeric(formData.usernameHospital) || !validator.isLength(formData.usernameHospital, 4)) }
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="usernameHospital"
                        value={formData.usernameHospital}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            <div className='input'>
                <FloatingLabel label="סיסמה" >

                    <Form.Control
                        className="customFloatInput"
                        placeholder='סיסמה'
                        isInvalid={showErrors && (!formData.password || !validator.isLength(formData.password, 4)    || !validator.isAlphanumeric(formData.password) ) }
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="text"
                        name="password"
                        value={formData.password}
                        onChange={onChange}
                    />
                </FloatingLabel>
            </div>

            {responseHospitals && <div className='input select'>
                <FloatingLabel label="בית חולים" >

                    <Form.Control
                        as="select"
                        className="customSelectInput customFloatInput"
                        placeholder='בית חולים'
                        isInvalid={showErrors && (!formData.hospitalId ) }
                        /*isValid={formik.touched.fullname && !formik.errors.fullname}  */
                        type="select"
                        name="hospitalId"
                        value={formData.hospitalId}
                        onChange={onChange}
                    >
                        <option value="">בחירת בית חולים</option>

                    {responseHospitals.hospitals.map((item) => {

                    if (true) {

                        return (
                        <option value={item.id} key={item.id}>
                            {item.value}
                        </option>
                        );
                    } else return false;
                    })}
                    </Form.Control>

                </FloatingLabel>
            </div> }

            <div className='input' style={{paddingTop: '7px'}}>
                <div className='btnCont'>
                    <button type="button" disabled={loading} className={`defaultBtnPic ${formFlagError ? 'disabled' : 'animate__animated animate__bounceIn'}`} onClick={doSubmit} >
                        <span>יצירת חשבון חדש</span>
                    </button>
                </div>
            </div>

        </div>

        {responseHospitals.users && <SystemHospitalsShowUsers users = {responseHospitals.users} setResponseHospitals = {setResponseHospitals} /> }


  </div>
}

function defaultValueForm() {

    //console.log(pay);

    return {

        name: '',
        usernameHospital: '',
        password: '',
        hospitalId: '',
        phone: ''
        }

}

function makeUserAPI(url, controller, objectToSend, setLoading, setFormData, setResponseHospitals, setshowErrors) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        //console.log(getData);

        if (getData.ok) {
            
            toast.success(getData.ok);
            setFormData(defaultValueForm());
            setshowErrors(false);
            setResponseHospitals(false);

        } else if (getData.error) {
            toast.error(getData.error)
        }
    })

    getData.catch((error) => {
        console.log(error)
    })

    getData.finally(() => {
        setLoading(false)
    })
}
