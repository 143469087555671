import React, { Component } from "react";

import WorkerLinkMain from "./WorkerLinkMain";
import addIco from './../../../img/nathan/svg/add.svg'

import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from 'react-router-dom'; 

export default class WorkersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editNurse: false,
      newNurse: false,
    };
  }

  render() {
    return (
      <div className="WorkersPage animate__animated animate__fadeIn">
        
        <div className="smallWrapper headerWorkers">
          <h1 className="titleStyle">עובדים</h1>

            <NavLink
                className="defaultBtnPic violet"
                role="menuitem" onClick={() => scroll.scrollToTop({duration : 0})}
                to='/worker' >

                <img src={addIco} alt='הוספה'/>
                <span>הוספת עובד</span>

            </NavLink>
        </div>

        <div className="smallWrapper noTopMarg">
            <WorkerLinkMain limit={6} orderData={false} editNurse={(id) => this.setState({ editNurse: id })} is_4selectWorker={false} />
        </div>
        
      </div>
    );
  }
}
