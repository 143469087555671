import React, { useState } from 'react';
import { toast } from 'react-toastify' //npm i react-toastify
import { Form, FloatingLabel } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0


import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

export default function WorkerEditNotes(props) {

    const {referId,notesType} = props;
    const [showErrors, ] = useState(false);

    const [loading, setLoading] = useState(false);
    const [notesList, setNotesList] = useState(false);

    const [formData, setFormData] = useState(
        {
            content : ''
        }
    )
    
    const onChange = (e) => {

            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
              })
    }

    if(!loading && !notesList) {

        const send = {
            referId : referId,
            notesType : notesType
           };

           //console.log(send);

       sendtoAdmin('getNotes','app', send ,setNotesList,setLoading);

    }

    const addNote = () => { 

        const send = {
            
            referId : referId,
            notesType : notesType,
            content :  formData.content
    
           };

        
        sendtoApi('newNote', 'app', send, setLoading, setFormData, setNotesList);

    }


    //console.log(notesList);

    const flagNewNote = formData.content;

  return (
    <div className='notesList'>

        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

        <h3>הוספת הערה</h3>

        <div className='input'>
            <FloatingLabel label='הערה'>
                <Form.Control
                    placeholder='הערה'
                    className="" 
                    disabled={false}
                    isInvalid={showErrors && !formData.content}
                    type='text'
                    name="content"
                    onChange={onChange}
                    value={formData.content}
                />
                 
            </FloatingLabel>

            <button  type='button' style={{ padding: '11px 60px'}} onClick={addNote} className={`defaultBtnPic noPic noPadd ${!flagNewNote ? 'disabledBtn' : 'animate__animated animate__bounceIn'}`} disabled={!flagNewNote}>הוספה</button>
        </div>


        {(notesList && notesList?.notes[0]?.content) && <div className='notesListExist'>

            <h4 className=''>הערות קיימות:</h4>
            
            <ul className='showNotesList'>
            { notesList.notes.map((item) => {
                if (true) {
                    return (
                    <li key={item.id}>
                        {/* <button className='deleteBtn' onClick={ () => unlinkHospital(item) }><i className="far fa-trash-alt"></i></button> */}
                        <strong>{item.created}</strong>
                        <span className='content'>{item.content}</span>
                        <small>({item.createdBy})</small>
                    </li>
                    );
                } else return false;
            })}
            </ul>

        </div> }

    </div>

    

  )
}


function sendtoApi(url, controller, objectToSend, setLoading, setFormData,setNotesList) {
    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {

      if (getData.ok) {

        setFormData( {
            content : ''
        });
        
        setNotesList(false);
        toast.success(getData.ok);

      } else if (getData.error) {
        toast.error(getData.error)
      }

    })
  
    getData.catch((error) => {
      console.log(error)
    })
  
    getData.finally(() => {
      setLoading(false)
    })
  }
