import React, {useState,useEffect } from 'react'
import { toast } from 'react-toastify' //npm i react-toastify


import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
//import { toast } from 'react-toastify' //npm i react-toastify

import WorkerEditNotes from './WorkerEditNotes';

import SystemEditHospitalGroups from './SystemEditHospitalGroups'

export default function SystemEditHospital(props) {

    const { item } = props;

    //console.log('item :>> ', item);

    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(false);

    if(responseData?.hospitalPrices && !formData) {
        setFormData(defaultValueForm(responseData.hospitalPrices));
        //console.log(formData);
    }

    useEffect(() => {

        if(!responseData) {

            //console.log('loading...');

            const send = item;
            sendtoAdmin('editHospitalShifts','hospitals', send ,setResponseData,setLoading);
    
        }

        //console.log(responseData);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData]);

   
    const changeValues = (groupName, values) => {

        //console.log(sendData);
        if (values?.shiftId1 || values?.shiftId2 || values?.shiftId3 ) {

            //console.log('in');

            let change = [];
            change.push(values);

            setFormData({
                ...formData,
                [groupName]:  change

            })

        }
        
        
    }



    const sendDataApi = () => {

        console.log(formData);

        let sendData = formData;
        sendData.hospitalsPricesId = item.id;

        console.log('sendData :>> ', sendData);
        

        sendtoApi('insertDataHospital', 'hospitals', sendData, setLoading, setResponseData)

    }
    


    


    return (
        <div>
            
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
            <div className='editHospital'>
                <header className='hospitalName'>
                    <h3>{item.hospitalName}</h3>
                    <p>{item.hospitalDivisionName}</p>
                </header>

                { formData &&  <div className='hospitalDataDays'>

                    <div className='groupDays'>
                        <SystemEditHospitalGroups priceType = 'holDays' hospitalPrices = {formData.holDays} changeValues = {(values) => changeValues( 'holDays', values)} />
                    </div>

                    <div className='groupDays'>
                        <SystemEditHospitalGroups priceType = 'shishiDays' hospitalPrices = {formData.shishiDays} changeValues = {(values) => changeValues( 'shishiDays', values)} />
                    </div>

                    <div className='groupDays'>
                        <SystemEditHospitalGroups priceType = 'shabatDays' hospitalPrices = {formData.shabatDays} changeValues = {(values) => changeValues( 'shabatDays', values)} />
                    </div>
                    
                </div> }
            </div>

            <div className='sendBtnCont'>
                <button className='sendBtn' onClick={sendDataApi}>אישור</button>
            </div>

            { formData &&  <div className='showNotesCont'>

                < WorkerEditNotes referId = { item.hospitalId + '|' + item.hospitalDivisionId + '|' + item.id } notesType='hospital' />

            </div> }
            
        </div>
    )

    
}

function defaultValueForm(hospitalPrices) {

    return {
        holDays: hospitalPrices?.holDays ?  hospitalPrices.holDays : [],
        shishiDays: hospitalPrices?.shishiDays ? hospitalPrices.shishiDays : [],
        shabatDays:  hospitalPrices?.shabatDays ? hospitalPrices.shabatDays : []
    }
    
}


function sendtoApi(url, controller, objectToSend, setLoading) {


    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

        console.log(getData)

      if (getData.ok) {
        toast.success(getData.ok)

      } else if (getData.warning) {

        toast.warning(getData.warning)

      } else if (getData.error) {
        toast.error(getData.error)
      }

    })

    getData.catch((error) => {
      console.log(error)
    })

    getData.finally(() => {
      setLoading(false)
    })
  }
