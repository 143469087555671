import React, {useState, useEffect } from 'react';

import { toast } from 'react-toastify' //npm i react-toastify
import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

import { Button } from "react-bootstrap"; //npm install react-bootstrap@next bootstrap@5.1.0

export default function Confirm(props) {

    const sendData = (props.search && props.search.orderid)  ?  props.search : false;

    const [response, setRequest] = useState(false)
    const [loading, setLoading] = useState(false)
    
    const [answer, setAnswer] = useState(false)
    
    useEffect(() => {

        if (!loading && !response && sendData) {

            const send = {
                orderId: sendData.orderid,
                token: sendData.token
            }
    
            sendtoAdmin('confirmOrder', 'NurseEnv', send, setLoading, setRequest, setAnswer)
    
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [response]);
    
    const sendAnswer = (value) => {
        
        if (!loading && (!answer || answer?.result !== value) ) {

            const send = {
                orderId: sendData.orderid,
                token: sendData.token,
                answer: value
            }
    
            sendtoAdmin('confirmOrder', 'NurseEnv', send, setLoading, setAnswer)
    
        }

    }

    const shiftData = response.shiftData ? response.shiftData : false;

  return <div className='ConfirmPage animate__animated animate__fadeIn'>

        <img src={loader} alt="loader" className={!loading ? 'loader': 'loader active animate__animated animate__fadeIn'} />

        {response && response.ok ? <div>
            
                <div className='whitePanel FirstScreenMainOrder'>
                    <h2>שלום: {response.order.nurseData.name}</h2>
                    <h1>אישור הזמנה <span>#{response.order.id}</span></h1>

                    <header className='infoHeader'>
                        <ul>
                            <li>
                                ליום <span>{response.order.targetDayShow}</span>
                            </li>
                            <li>
                                משמרת - { response.order.hospitalShift }
                            </li>

                            {shiftData && response.order.hospitalshiftId !== "6" && <li>
                            <span>({shiftData.timeStart} - {shiftData.timeEnd})</span>
                            </li>}

                            { response.order.hospitalshiftId === "6" ? <li className='emergencyLine'>
                                משעה: { response.order.emergencyFrom } - לשעה: { response.order.emergencyTo }
                            </li> : false }
                        </ul>
                    </header>

                    {answer && <div className='answer'>

                        {answer.result === "1" ? <div className='ok'><span>סטטוס: </span>יש אישור הגעה</div>
                                               : <div className='error'><span>סטטוס: </span>אין אישור הגעה</div> }

                    </div> }

                    <ul className='hospitalData'>
                        <li>{ response.order.hospital }</li>
                        <li>{ response.order.hospitalShiftText }</li>
                        <li>{ response.order.hospitalDivision }</li>
                        <li>פקיד/ה: { response.order.openUserName }</li>
                    </ul>

                    <div className='BtnCont'>

                        <Button variant="success" size="lg" disabled={loading} onClick={()=> sendAnswer('1') }>מאשר/ת הגעה</Button>
                        <Button variant="danger" size="lg" disabled={loading} onClick={()=> sendAnswer('2') } >לא מאשר/ת הגעה</Button>

                    </div>
                    
                    

                </div>

                

            </div> : <div className='errorDiv'>
                    <h1>שגיאה באישור הגעה</h1>
                </div>}

            
      
      </div>
}


function sendtoAdmin(url, controller, objectToSend, setLoading, setRequest, setAnswer = false) {
    
    setLoading(true)

    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

      if (getData.ok) {

        if(setAnswer && getData.order?.nurseConfirm !== '0' ) {
            setAnswer({ result : getData.order.nurseConfirm });
        }

        setRequest(getData)
        //toast.success('עודכן בהצלחה')
        
      } else if (getData.answer) {

        if(getData.answer.ok) {

            toast.success(getData.answer.ok)
            setRequest(getData.answer)

        } else {

            toast.error(getData.answer.error)

        }

        

      }  else if (getData.error) {

        toast.error(getData.error);

      }
    })

    getData.catch((error) => {
      console.log(error)
    })

    getData.finally(() => {
      setLoading(false)
    })
  }