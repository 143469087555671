import React from 'react'
import ShowExtraMoney from './Money/ShowExtraMoney';

export default function OrderPayShowSavedPayedData(props) {

  const {orderData, payData} = props;

  //console.log(orderData);
  //console.log('payData', payData);

  return (
    <div className='WorkerPaysIndex'>
        <h2>תשלום</h2>
        <ul>
            <li className='totalAmount'>שולם: <strong>
                <small>₪</small>{orderData.shiftMoney}</strong>
                {orderData.travelMoney > 0 && <>
                <span> + </span><small>₪</small>{orderData.travelMoney}
                <small> (נסיעות)</small>
                </>}
            </li>

            {payData?.paydDataDb?.extraHours?.result && <ShowExtraMoney extraHours={ payData.paydDataDb.extraHours } />}
        </ul>
    </div>
  )


}
