import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //npm install react-router-dom
import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect
import SiteConnector  from './SiteConnector';

import LoginPage from "../pages/loginPages/LoginPage"
//import LoginPage2 from "../pages/loginPages/LoginPage2"

import {getAllUrlParams} from "./../../Components/models/UrlParameters";


export default class loginRouter extends Component {

    render() {

        let urlParams = getAllUrlParams(window.location.search);

        return (
            <React.Fragment>

            <Router>

                <Routes>

                    <Route path="/confirm" element={ <SiteConnector search={ urlParams } router="router2"  page="confirm" isMobile = {isMobile ? true : false} />} />

                    {/* <Route path="/2" element={ <LoginPage2 search={ urlParams } page="LoginPage2" isMobile = {isMobile ? true : false} />} /> */}
                    <Route path="*" element={ <LoginPage search={ urlParams } page="LoginPage" isMobile = {isMobile ? true : false} />} />
                    

                </Routes>

            </Router>
          
      </React.Fragment>
        )
    }
}
