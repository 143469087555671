import React, {useState} from 'react'

import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import { Table } from 'react-bootstrap';

import OrderInvoiceIndex from './OrderInvoiceIndex'

export default function OrderPayShowPays(props) {

    const {orderData} = props;

    const [allPayments, setAllPayments] = useState(false);
    const [loading, setLoading] = useState(false);

    if(!loading && !allPayments?.payments) {

        const send = {
             orderId : orderData.id
            };

        sendtoAdmin('getPayments','pay', send ,setAllPayments,setLoading);

    }

    //console.log(payments);

    return (
        <div>
            
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            <h2>גביית תשלום</h2>
            {allPayments?.payments && <div className='ShowPayData'>
                <Table striped >
                        <thead>
                            <tr>
                                <th>תאריך תשלום</th>
                                <th>אמצעי תשלום</th>
                                <th>בוצע על ידי</th>
                                <th>סכום</th>
                            </tr>
                        </thead>

                        <tbody>
                        
                            {allPayments.payments.map((item) => {

                                if (true) { //orders[0].id !== item.id

                                    return (
                                        <tr key={item.id}>
                                            <td>{item.dateShow}</td>
                                            <td>{item.typePay}</td>
                                            <td>{item.payByUserName}</td>
                                            <td>₪{item.SumValue}</td>
                                        </tr>
                                    );
                                } else return false;
                            })}
                        </tbody>
                </Table>
            </div> }

            {(allPayments?.payments && allPayments.payments[0]) && < OrderInvoiceIndex orderData = {orderData} payment = {allPayments.payments[0]} />}
        </div>
    )
}
