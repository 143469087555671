import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
//import BulletsMenu from "./BulletsMenu";

import logo from "../../../img/nathan/logo.png";
import xMenu from "../../../img/icons/SVG/xMenu.svg";
import logOut from '../../../img/nathan/menuIcons/logOut.svg';
//import { RestUrls } from "../../api/config";


export default class NavMobileMenuContent extends Component {
 

  render() {

    //const props = this.props.allProps;

    /* let user = JSON.parse(localStorage.getItem("userData"));
    const isUser =
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
        ? true
        : false; */

    //let bgDesktop = RestUrls.img;
    //let currentPage = props.page;

    return (
      <div>
        <header className="m_header">
          <img src={xMenu} alt="סגירה" className="fakeClose" />

          {/* <a
            className="logoClient"
            href="https://sherut-leumi.co.il/"
            target="_self"
            rel="noopener noreferrer"
          > */}

          {<img src={logo} alt="נתן סיעוד" className="logoUp" />}
          
        </header>

        <nav className="mainMenuNav">
          <NavLink
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/"
          >
            
            <span>ראשי</span>
          </NavLink>

          <NavLink
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/config"
          >
            
            <span>הגדרות</span>
          </NavLink>

          {/* <NavLink
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/archive"
          >
            
            <span>ארכיון משמרות</span>
          </NavLink> */}

          
          <NavLink
            role="menuitem"
            onClick={() => scroll.scrollToTop({ duration: 0 })}
            to="/logout"
          >
            <img src={ logOut } alt="" />
            <span style={{ verticalAlign:'middle' }}> התנתקות</span>
          </NavLink>

          
        </nav>

        

          <a
            className="WaveProjectC"
            href="https://waveproject.co.il/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png"
              alt="WaveProject"
            />
          </a>
        
      </div>
    );
  }
}
