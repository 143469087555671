import React, { Component } from 'react'
import CustomFloatInput from '../../models/forms/CustomFloatInput';
import { RestUrls } from "../../api/config"

//import { animateScroll as scroll, } from 'react-scroll' /* https://www.npmjs.com/package/react-scroll npm install react-scroll */
//import { NavLink } from 'react-router-dom'; 

import {Button} from 'react-bootstrap' //npm install react-bootstrap@next bootstrap@5.1.0
import loader from "../../../img/preLoader.gif";

import getDataFromApi from '../../api/getDataFromApi';
//import ModalDefaul from '../../models/ModalDefaul';

//import {getAllUrlParams} from "./../../Components/models/UrlParameters";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

import logoNathan from './../../../img/nathan/logo.png';

import { toast } from 'react-toastify' //npm i react-toastify

export default class loginPage extends Component {


    constructor(props) {
        super(props)
    
        //console.log(props);
        const username = this.props.search.username ? this.props.search.username : '';

        this.state = {
            userName : username,
            password : '',
            loading: false,
            responseLogin : false
        }
    }
    
    

    updateValue = (newValue) => {

        this.setState(newValue,()=>{
            //console.log(this.state);
        })
    }

    sendForm = (sendRules) => {

        if(sendRules) {

            this.setState({
                loading: true
            });

            const sendObj = this.state;
            delete sendObj.responseLogin;

            this.sendtoAdmin('login','users',sendObj,'responseLogin','register');

            //console.log(sendObj);
            
        } else {
            this.setState({
                btnSendClass : 'danger'
            })
        }

        

    }


    sendtoAdmin = (url,controller,objectToSend, setStateName = 'data', auth = 'all') => {

        console.log('sending...');

        this.setState({loading : true});

        const getData = getDataFromApi(url,objectToSend,controller,auth);

        getData.then(getData =>this.setState({ [setStateName] : getData, loading : false,
        
        }, function(){

            console.log(getData);

            if(getData.ok) {

                localStorage.setItem('userData',JSON.stringify(getData.ok));
                window.open('/','_self');
                
            } else if(getData.error) {
                
                toast.error(getData.error);
            }
            
            /* if(url === 'reportBuilder') {
                
            } */
        }))
    }

    componentDidMount() {

        /* const urlQuery = getAllUrlParams(window.location.href);
        const rakazId = urlQuery.rakazid ? urlQuery.rakazid : false;

        if(rakazId) {
            localStorage.setItem('rakazid', rakazId );
        }  */
    }
    


    render() {

        const sendRules = this.state.userName && this.state.password;

        
        let picTopDesktop = RestUrls.pagesPictures  + 'login/rLogin.jpg?v=1';
        let m_picTop = RestUrls.pagesPictures + 'login/m_top.jpg?v=1';

        return (
            <div className={`body-container PagesLogin ${isMobile ? 'Mobile' : 'Desktop'}`}  >
              
                

                <div className="tableLoginRegister loginPage animate__animated animate__fadeIn">

                    <div className='picRight ColLogin'>
                        <img src={picTopDesktop} alt="ברוכים הבאים למערכת של נתן סיעוד להזמנות מטפלים" />
                    </div>

                    { isMobile ? <div className='mobileLoginHeader'>
                        <img className="logoNathan" src={logoNathan} alt='נתן - רפואה שיקום וסיעוד' />
                        <img className='topPic' src={m_picTop} alt="top" /> 
                        
                    </div>: false }
                
                    <div className="ColLogin LColLogin">
              
                        <div className="firstRegister">

                            <img src={loader} alt="loader" className={!this.state.loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

                            

                            <header>
                                { !isMobile ? <img src={logoNathan} alt='נתן - רפואה שיקום וסיעוד' /> : false }
                                <h1 className="boldTypeFamily">התחברות</h1>
                                <p>הזינו שם משתמש וסיסמה כדי להכנס למערכת</p>
                            </header>


                            <div className="inputs">

                                <div className="line">
                                    <CustomFloatInput name='userName' updateValue={this.updateValue} value={this.state.userName} placeholder="שם משתמש" cssClass='' validationRules={ {required: true} } typeInput='text' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })} />
                                </div>

                                <div className="line">
                                    <CustomFloatInput name='password' updateValue={this.updateValue} value={this.state.password} placeholder="סיסמה" cssClass='' validationRules={ {required: true} } typeInput='password' checkInputs={this.state.checkInputs} checked={()=>this.setState({ checkInputs : false })}/>
                                    {/* <div className="singIn reSendPass">
                                        <NavLink
                                            className="jumpPage" 
                                            role="menuitem" onClick={() => scroll.scrollTo(0)}
                                            to={'/reSendPass'} >
                                            <span><strong>שכחתי סיסמה</strong></span>
                                        </NavLink>
                                    </div> */}
                                </div>

                            </div>

                            <div className="text-center">
                                
                                <Button disabled={this.state.loading ? true : false} className="sendBlueBtn" onClick={() => this.sendForm(sendRules)} size="lg" variant={sendRules ? 'success animate__animated animate__bounceIn' : this.state.btnSendClass} >התחברות</Button>
                                
                                {/* <div className="singIn">
                                    <NavLink
                                        className="jumpPage" 
                                        role="menuitem" onClick={() => scroll.scrollTo(0)}
                                        to={'/register'} >
                                        <span>עדין לא רשומים? <strong>לחצו להרשמה</strong></span>
                                    </NavLink> 
                                </div> */}
                            </div>

                        </div>
                    </div>


                </div>

                <a  href="https://waveproject.co.il/" className="logoDown" target="_blank" rel="noopener noreferrer">
                    <img src="https://leads.wdev.co.il/commonAssetsWave/credit/wp-b-hd.png" alt="WaveProject" />
                </a>

                <div className="whiteBG"></div>
            </div>
        )
    }
}
