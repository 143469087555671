import React from 'react'
import { animateScroll as scroll } from "react-scroll"; /* https://www.npmjs.com/package/react-scroll */
import { NavLink } from "react-router-dom";
import config from "../../../img/nathan/svg/config.svg"

export default function FirstScreenUserData(props) {


    const { nurseData } = props;
    //console.log(nurseData);

    return (
        <div className='FirstScreenUserData'>
            
            <div className='userData'>

                <button className='reloadBtn' onClick={ ()=> window.open('/','_self') } >
                    <i className="fas fa-sync-alt"></i>
                </button>

                <div className='text'>
                    <h2>{nurseData?.name}</h2>
                    <p>שם משתמש: <small>{nurseData?.username}</small></p>
                </div>

            </div>
            

            <NavLink
                className='toConfig'
                role="menuitem"
                onClick={() => scroll.scrollToTop({ duration: 0 })}
                to="/config"
            >
                <img src={config} alt='הגדרות' />
                <span>הגדרות</span>
          </NavLink>
        </div>
    )
}
