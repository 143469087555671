import React, {useState} from 'react'

import SystemContracts from './SystemContracts';
import SystemEditContract from './SystemEditContract';

export default function SystemContractsIndex() {

    const [editContractItem, setEditContractItem] = useState(false);

    const editContract = (item) => {
        
        setEditContractItem(item);
    }
    

    return (
        <div>
            {!editContractItem ? <SystemContracts changeHospital = { (item)=>editContract(item) } /> :
            
                <div className='SystemEditHospital accordeonContent SystemContracts' >

                    <div className="goBackBtnCont">
                        <button className="goBackBtn bounce2" onClick={ ()=>setEditContractItem(false) }>« חזור</button>
                    </div>
                    
                    <SystemEditContract item={editContractItem} />
                    
                </div> }
        </div>
    )
}
