import React from 'react'

export default function workerConfirm(props) {

    const {orderData, showConfirm, output} = props;
    
    const getComponent= (params) => {

        if(orderData.orderStatus === '1') {

            if(orderData.nurseConfirm === '0') {

                return <div className="showStatus orange noAccepted padd">אין אישור</div>

            }

            else if (orderData.nurseConfirm === '2') {

                return <div className="showStatus red  padd">סירוב הגעה</div>

            }

            else if (orderData.nurseConfirm === '1' && showConfirm) {

                return <div className="showStatus green  padd">יש אישור עובד/ת</div>

            }


        } else {

            return false

        }
        
    }
    

    if (output === 'li') {

        return (
            <li>{ getComponent() }</li>
        )

    
    } else {

        return (
            <>{ getComponent() }</>
        )


    }

    
}
