import React, { useState } from 'react'

import { toast } from 'react-toastify' //npm i react-toastify

import getDataFromApi from '../../api/getDataFromApi'
import loader from './../../../img/preLoader.gif'

import OrderPayCreditCardForm from './OrderPayCreditCardForm'

import OrderPayShowPays from './OrderPayShowPays'
import UnificateOrders from './UnificateOrders'

export default function OrderPayCreditCard(props) {

  const { payData, orderData, reloadData } = props;

  const [payOk, setRequest] = useState(false)
  const [loading, setLoading] = useState(false)

  const hadleCardForm = (form) => {

    //console.log(form);
    //console.log(orderData);

    form.invoiceId = orderData.id
    form.clientId = orderData.patientTz
    form.orderId = orderData.id

    if (!loading && form) {

      sendtoAdmin('addPay', 'pay', form, setLoading, setRequest)
    }

  }


  return (
    <div className='OrderPayCreditCard'>

      <img src={loader} alt="loader" className={!loading ? 'loader' : 'loader active animate__animated animate__fadeIn'} />


      {!payOk && !payData?.payments[0] ? <div className='line addPad'>

        <h2>תשלום</h2>
        <OrderPayCreditCardForm pay={payData.timeData.payValue} cardForm={(form) => hadleCardForm(form)} loading={loading} />

      </div> : <div className='line addPad'>

        <OrderPayShowPays orderData={orderData} />

      </div>}

      {!payOk && !payData?.payments[0] && <div className='line addPad'>

        <UnificateOrders orderData={orderData} reloadData = { ()=> reloadData() } />
        
      </div>}
    </div>
  )


  function sendtoAdmin(url, controller, objectToSend, setLoading, setRequest) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')

    getData.then((getData) => {

      console.log(getData);

      if (getData.ok) {

        setRequest(true);
        toast.success(getData.ok)
        props.reloadOrders();
        props.reloadData();

      } else if (getData.error) {

        toast.error(getData.error)

      }
    })

    getData.catch((error) => {
      console.log(error)
    })

    getData.finally(() => {
      setLoading(false)
    })
  }

}
