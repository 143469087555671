
export function checkDay(number) {

    let textReturn = '';

    switch (number) {

        case 1:

            textReturn = {
                hebrewDay : 'ראשון',
                engDay : 'Sunday'
            }

            return textReturn;

        case 2:

            textReturn = {
                hebrewDay : 'שני',
                engDay : 'Monday'
            }

            return textReturn;

        case 3:

            textReturn = {
                hebrewDay : 'שלישי',
                engDay : 'Tuesday'
            }

            return textReturn;

        case 4:

        textReturn = {
            hebrewDay : 'רביעי',
            engDay : 'Wednesday'
        }

            return textReturn;

        case 5:

        textReturn = {
            hebrewDay : 'חמישי',
            engDay : 'Thursday'
        }

            return textReturn;

        case 6:

        textReturn = {
            hebrewDay : 'שישי',
            engDay : 'Friday'
        }

            return textReturn;

        case 7:

        textReturn = {
            hebrewDay : 'שבת',
            engDay : 'Saturday'
        }

            return textReturn;
        
        default:

            textReturn = {
                hebrewDay : '----'
            }

            return textReturn;
    }

    

}


export function defaultValues(setSelectedShifts) {

    let defaultArray = [];

    for (let i = 1; i <= 7; i++) {
        
        let newItem = {
            day : checkDay(i).engDay,
            shifts : []
        }

        defaultArray.push(newItem);

    }

    return defaultArray;
    //setSelectedShifts(defaultArray);
   

}


export  function checkActiveClass(objButton,mainArray) {

    //console.log(objButton);
    //let objButton = { day: checkDay(i).engDay, idShift: item.id };

    const shiftsDayArr = mainArray.multifilter('day',objButton.day);
    const idsShift = shiftsDayArr[0].shifts;

    if(idsShift.includes(objButton.idShift)) {

        return 'active'

    } return '';

}

export function checkActiveDay(engDay,mainArray) {

    

    const shiftsDayArr = mainArray.multifilter('day',engDay);
    const idsShift = shiftsDayArr[0].shifts;

    if(idsShift.length > 0 ) {
        return "active"
    } else return '';
}