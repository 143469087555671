import React, {useState,useEffect} from 'react'

import getDataFromApi, { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";
import { toast } from 'react-toastify' //npm i react-toastify
import addIco from './../../../img/nathan/svg/add.svg'

import { Form } from 'react-bootstrap';

export default function WorkerContracts(props) {

    const {nurseData} = props;
    const [responseData, setResponseData] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({
        contractId: '',
    });

    useEffect(() => {
       
        getContracts();

       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseData])
    
    const getContracts = () => {

        if(!loading && !responseData) {

            sendtoAdmin('getContractsNurses','contracts', {workerId: nurseData.id} ,setResponseData,setLoading);
    
        }
        
    }

    const addContract = () => { 

        const send = {
            workerId: nurseData.id,
            contractId: formData.contractId
          }

        sendtoApi('linkContractNurse', 'contracts', send, setLoading, nurseData, setFormData, setResponseData)

     }


    const unlinkContract = (item) => {

        //console.log('item.id :>> ', item.id);

        const send = {
            workerId: nurseData.id,
            contractId: item.id
          }

        sendtoApi('unlinkContractNurse', 'contracts', send, setLoading, nurseData, setFormData, setResponseData)

    }

    return (
        <div className='WorkerContracts'>
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />

            {responseData && <div className='addContractCont'>

                <Form.Control
                    as="select"
                    className="customSelectInput "
                    type='select'
                    name="contractId"
                    value={formData.contractId}
                    onChange={e=>setFormData({ ...formData, contractId : e.target.value })} 

                >
                    <option value="">בחירת חוזה עבודה</option>

                    {responseData.contracts.map((item) => {
                    if (true) {
                        
                        return (
                        <option value={item.id} key={item.id}>
                            {item.hospitalName} - {item.hospitalDivisionName} - {item.contractName}
                        </option>
                        );
                    } else return false;
                    })}
                </Form.Control>

                <button
                    className={`defaultBtnPic violet ${formData.contractId ? 'animate__animated animate__bounceIn' : 'disabledBtn'}`}
                    onClick={addContract}
                    disabled={formData.contractId ? false : true}
                    >
                    <img src={addIco} alt='הוספה'/>
                    <span>לשבץ חוזה עבודה</span>
                </button>

            </div> }
            
            {responseData && <div className='showContracts'>

                <h3>חוזים קיימים:</h3>
                <div className='showContractsCont'>
                    { responseData.linkedContracts && responseData.linkedContracts.length > 0 ? <ul> 
                        {responseData.linkedContracts.map((item) => {
                            if (true) {
                                
                                return (
                                <li key={item.id}>
                                    <span className='contractName'>{item.contractName}</span>
                                    <button onClick={ () => unlinkContract((item)) }><i className="far fa-trash-alt"></i></button>
                                    - {item.hospitalName} - {item.hospitalDivisionName}
                                </li>
                                );
                            } else return false;
                            })}
                        
                    </ul> : <ul>
                        <li style={{ color: 'red', textAlign: 'center' }}>אין חוזים במערכת</li>
                    </ul> }
                </div>

            </div> }
            
        </div>
    )
}




function sendtoApi(url, controller, objectToSend, setLoading, nurseData, setFormData, setResponseData) {

    setLoading(true)
    const getData = getDataFromApi(url, objectToSend, controller, 'all')
  
    getData.then((getData) => {
      
        if (getData.ok) {

        setFormData({contractId: ''});

        sendtoAdmin('getContractsNurses','contracts', {workerId: nurseData.id} ,setResponseData,setLoading);

        //setRequest(getData.ok)
        toast.success(getData.ok);

      
    } else if (getData.error) {

        toast.error(getData.error)
      }
    })
  
    getData.catch((error) => {

      console.log(error)

    })
  
    getData.finally(() => {
      setLoading(false)
    })

  }