import React, { useState, useEffect } from 'react'

import { convertDate2 } from '../../../../Components/models/ordersFuncNathan'

import FormControl from '@material-ui/core/FormControl';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css?v=6";
import { registerLocale } from  "react-datepicker";
import he from 'date-fns/locale/he';



registerLocale('he', he);


export default function ShowDatePicker(props) {


    const { hadleDateRetro, dateProp } = props

    //const today = new Date();
    //let current_datetime = new Date(today.toString().replace(/-/g, '/'));

    const [datepickerValue, setDatepickerValue] = useState( dateProp ?  dateProp : '');


    useEffect(() => {

        //const today = new Date();
        //const current_datetime = new Date(today.toString().replace(/-/g, '/'));

        /* if(!datepickerValue) {
            setDatepickerValue(today);
        } */

        if(datepickerValue) {

            //if(convertDate(datepickerValue) !== convertDate(current_datetime)) {
                
                hadleDateRetro(convertDate2(datepickerValue));

            //}

        }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datepickerValue]) //hadleDateRetro

    
    //console.log(datepickerValue);

  return (
    <FormControl className='dateWrapper' >

        <DatePicker 
            showYearDropdown = {true}
            selected={datepickerValue}
            dateFormat='dd/MM/yy'
            locale="he"
            onChange={(date) => {
                setDatepickerValue(date);
            }}
        />

    </FormControl>
  )
}
