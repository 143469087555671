//import logo from './logo.svg';
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/animateNew.css"; /* https://daneden.github.io/animate.css/ */

import 'react-toastify/dist/ReactToastify.css'

import "./css/site.scss";
import "./css/site_pages.scss";
import "./css/mobile.scss";

import { isMobile } from "react-device-detect"; //npm install react-device-detect //https://www.npmjs.com/package/react-device-detect

import { ToastContainer } from 'react-toastify' //npm i react-toastify
//hay otro import en el index js para los estilos
//https://fkhadra.github.io/react-toastify/introduction


//import './css/media.css';
//import './css/accessibility.css';

//https://getbootstrap.com/ //npm install bootstrap@next

import React, { Component } from "react";

//import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; //npm install react-router-dom
// https://stackoverflow.com/questions/63124161/attempted-import-error-switch-is-not-exported-from-react-router-dom

import LoginPage from "./Components/controllers/loginRouter";
import GetPage from "./Components/controllers/getPageRouter";

import IdleTimer from "react-idle-timer"; //time out timmer
//npm install react-idle-timer --save


export default class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  componentDidMount() {
    //Check user date session...

    if (
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
    ) {
      let userJ = JSON.parse(localStorage.getItem("userData"));
      let user = userJ;

      if (!user.name) {
        localStorage.setItem("userData", "");
        window.location.reload();
      }

      //let loginDate = user.dateTimeLogin;
      if (user.dateTimeLogin) {
        let now1 = new Date();
        let loginDate = new Date(
          user.dateTimeLogin.toString().replace(/-/g, "/")
        ); // IPHONE FIX
        let nowDate = new Date(now1.toString().replace(/-/g, "/")); // IPHONE FIX

        const diffTime = Math.abs(nowDate - loginDate);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        if (diffDays > 1) {
          localStorage.setItem("userData", "");
          window.location.reload();
        }
      }
    }
  }

  render() {
    if (
      localStorage.getItem("userData") &&
      localStorage.getItem("userData").length > 0
    ) {
      //תיקון SESSION מערכות אחרות

      let userJ = JSON.parse(localStorage.getItem("userData"));
      let user = userJ;

      if (!user.name) {
        localStorage.setItem("userData", "");
        window.location.reload();
      }
    }

    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          //http://72.9.151.42/time/minutes-to-milliseconds-conversion.html
          //timeout={1000 * 60 * 30} // 30 minute
          timeout={1000 * 60 * 240} // 30 minute
          //timeout={1000 * 60 * 1} // 1 mitutes
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />

        <React.Fragment>
          {localStorage.getItem("userData") &&
          localStorage.getItem("userData").length > 0 ? (
            <GetPage />
          ) : (
            <LoginPage />
          )}

          <ToastContainer

              position={ isMobile ? 'top-center' : 'top-left' }
              autoClose={3000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={true}
              pauseOnVisibilityChange
              draggable
              pauseOnHover={true}
              theme="colored"
          />
          
        </React.Fragment>
      </div>
    );
  }

  handleOnAction(event) {
    //console.log('user did something', event)
  }

  handleOnActive(event) {
    //console.log('user is active', event)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event) {
    localStorage.setItem("userData", "");
    window.location.reload();

    //console.log('user is idle', event)
    //console.log('last active', this.idleTimer.getLastActiveTime())
  }
}
