import React, { useState }  from 'react'
import FirstScreenUserData from './FirstScreenUserData'

import FirstScreenMainOrder from './FirstScreenMainOrder'
import FirstUpdataEmergency from './FirstUpdataEmergency'
import FirstNurseOrders from './FirstNurseOrders'

import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

export default function FirstScreen() {

    let user = JSON.parse(localStorage.getItem("userData"));

    const [responseNurseData, setResponseNurseData] = useState(false);
    const [loading, setLoading] = useState(false);

    //console.log(props);

    if(!loading && !responseNurseData) {

        const send = { nurseId : user.id.replace('-','') };
        sendtoAdmin('getNurseData','NurseEnv', send ,setResponseNurseData,setLoading);
    }

    //console.log(responseNurseData);

    return (
        <div className='FirstScreen animate__animated animate__fadeIn'>

            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
            
            { responseNurseData ? <div className='allPanels animate__animated animate__fadeIn'>
            
                {/* {console.log(responseNurseData)} */}
                
                <div className='whitePanel shadowDefault '>
                    
                    < FirstScreenUserData nurseData={ responseNurseData.nurseData } />

                </div>


                {responseNurseData.orders && parseInt(responseNurseData.totalOrders) > 0 ?  
                    
                    <div className='whitePanel shadowDefault '>
                        <FirstScreenMainOrder nurseAndOrdersData ={ responseNurseData } /> 
                    </div>
                
                : <div className='whitePanel shadowDefault'>
                    <h2 className='errorH2Panel'>אין משמרות פתוחות</h2>
                </div> }


                <div className='whitePanel shadowDefault '>
                    <FirstUpdataEmergency nurseData={ responseNurseData.nurseData } />
                </div>
                    
                <div className='whitePanel shadowDefault noWithPadd'>
                    <FirstNurseOrders nurseAndOrdersData ={ responseNurseData } isWeek = {true} /> 
                </div>
            
            </div> : false }

        </div>
    )
}
