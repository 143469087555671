import React from 'react'

export default function OrderPayShowTotals(props) {

    const {payData, orderData} = props; //, noPay

    const timeData = payData.timeData;

    //console.log(payData);

    return (
        <div className='OrderPayShowTotals animate__animated animate__fadeIn'>

            <section className='line'>
                <h2>פרטי הזמנה: </h2>
                <ul>
                    <li><strong>בית חולים:</strong> <span>{orderData.hospital}</span></li>
                    <li><strong>מחלקה:</strong> <span>{orderData.hospitalDivision}</span></li>

                    <li><strong>יום: </strong> <span>{timeData?.hebrewDay}</span></li>
                    <li className={orderData.hospitalshiftId === '6' ? 'price' : ''}><strong>משמרת:</strong> <span>{orderData.hospitalShift}</span></li>
                    {(orderData.hospitalShiftId !== '6' ) ? <>
                        <li><strong>התחלה:</strong> <span>{timeData?.shift.timeStart}</span></li>
                        <li><strong>סוף:</strong> <span>{timeData?.shift.timeEnd}</span></li>
                    </> :
                    <>
                        <li><strong>התחלה:</strong> <span>{orderData.emergencyFrom}</span></li>
                        <li><strong>סוף:</strong> <span>{orderData.emergencyTo}</span></li>
                    </>
                }
                    
                </ul>
            </section>

            <section className='line'>
                <h2>שעות עבודה</h2>
                <ul>
                    <li><strong>התחלה:</strong> <span>{timeData?.start}</span></li>
                    <li><strong>סוף:</strong> <span>{timeData?.stop}</span></li>
                    {timeData?.totalTime && <li><strong>סה"כ: </strong>{timeData?.totalTime.text}</li> }
                </ul>
            </section>
            
        </div>
    )
}
