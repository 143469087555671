import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import { RestUrls } from "../../api/config"
import logo from "../../../img/nathan/logo.png";

/* npm install @emotion/react @emotion/styled @material-ui/core @mui/material */

import NavMobileMenuContent from './NavMobileMenuContent'

import m_Open from '../../../img/icons/SVG/m_openMenu.svg';

export default function TemporaryDrawer(props) {
    
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
        <NavMobileMenuContent allProps={props} />

    </Box>
  );

  const anchor = 'right';

  //console.log(props);

  return (
      <React.Fragment key={anchor}>
          
        <div className='mobileNavPage'>

          <a href={ RestUrls.appUrl }  target="_self" rel="noopener noreferrer" >
            <img className='logo' src={logo} alt="" />
          </a>

            {props.page !== 'confirm' && <Button className="mobileOpenBtn" onClick={toggleDrawer(anchor, true)}>
                <img  src={m_Open} alt="לפתוח תפריט" />
            </Button> }
        </div>
        
        <Drawer
          className="mobileMenu"
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer('right', false)}
        >
          
          {list(anchor)}
        </Drawer>
      </React.Fragment>
  );
}