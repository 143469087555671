import React, { useState, useEffect } from 'react'
import SystemEditHospitalInputs from './SystemEditHospitalInputs';
import { Arr } from 'array-helpers'; //npm i array-helpers //https://www.npmjs.com/package/array-helpers

export default function SystemEditHospitalGroups(props) {

    const { hospitalPrices, priceType, dontShowShift } = props;
    const [hospitaPricesS, setHospitaPricesS] = useState(defaultValueForm(hospitalPrices))

    const changePrices = (shiftId, prices) => {
        
        //console.log( shiftId, prices );

        setHospitaPricesS({
        
            ...hospitaPricesS,

            [shiftId]: prices,

        })

        //props.changeValues(hospitaPricesS);

    }

    useEffect(() => {

        //console.log(hospitaPricesS);
        props.changeValues(hospitaPricesS);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hospitaPricesS])
    


    return (
        <>
            <h4>הגדרת משמרת: <strong>{showHeaderText(priceType)}</strong></h4>

            <ul>
                <li className='hospitalShifts'>{dontShowShift ? 'טווח ראשון' : 'בוקר'}</li>
                <li>{showInputs('טווח ראשון', priceType, changePrices, hospitaPricesS)}</li>
                
                <li className='hospitalShifts'>{dontShowShift ? 'טווח שני' : showNameShift2(priceType)}</li>
                
                <li>{showInputs('טווח שני', priceType, changePrices, hospitaPricesS)}</li>

                <li className='hospitalShifts'>{dontShowShift ? 'טווח שלישי' : showNameShift(priceType) }</li>
                {<li>{showInputs('טווח שלישי', priceType, changePrices, hospitaPricesS)}</li>}
            </ul>
        </>
    )
}

function showInputs(shift, priceType, changePrices, hospitaPricesS) {

    //console.log(hospitaPricesS);

    switch (shift) {

        case 'טווח ראשון':
            return <SystemEditHospitalInputs hospitalShiftId={1} changePrices={(prices) => changePrices('shiftId1', prices)} hospitalPrices={hospitaPricesS.shiftId1} />

        case 'טווח שני':
            if(priceType === 'shabatDays') {
                return <SystemEditHospitalInputs hospitalShiftId={5} changePrices={(prices) => changePrices('shiftId5', prices)} hospitalPrices={hospitaPricesS.shiftId5} />
            } else {
                return <SystemEditHospitalInputs hospitalShiftId={2} changePrices={(prices) => changePrices('shiftId2', prices)} hospitalPrices={hospitaPricesS.shiftId2} />
            }

        case 'טווח שלישי':
            if(priceType === 'shishiDays') {
                return <SystemEditHospitalInputs hospitalShiftId={4} changePrices={(prices) => changePrices('shiftId4', prices)} hospitalPrices={hospitaPricesS.shiftId4} />
            } else {
                return <SystemEditHospitalInputs hospitalShiftId={3} changePrices={(prices) => changePrices('shiftId3', prices)} hospitalPrices={hospitaPricesS.shiftId3} />
            }
            
    
        default:
            return false;
            
    }
    

}


//Center row
function showNameShift2(priceType) {

    switch (priceType) {

        case 'shabatDays':
            return "עד יציאת שבת"

        case 'shishiDays':
            return "ערב"

        default:
            return "ערב"
    }
    
}

//Last row
function showNameShift(priceType) {

    switch (priceType) {

        case 'shabatDays':
            return "לילה"

        case 'shishiDays':
            return "כניסת שבת"

        default:
            return "לילה"
    }
    
}




function defaultValueForm(hospitalPrices) {

    //console.log(hospitalPrices);

    
    const mainArray = new Arr(hospitalPrices);

    //const pricesShiftsArr = mainArray.multifilter('hospitalShiftId',hospitalShiftId);

    return {
        shiftId1: mainArray.multifilter('shiftId','1')[0],
        shiftId2: mainArray.multifilter('shiftId','2')[0],
        shiftId3: mainArray.multifilter('shiftId','3')[0],
        shiftId4: mainArray.multifilter('shiftId','4')[0],
        shiftId5: mainArray.multifilter('shiftId','5')[0]
      }
    
}

function showHeaderText(priceType) {

    switch (priceType) {
        
        case 'holDays':
            return "ימי חול";

        case 'shishiDays':
            return "ימי שישי";

        case 'shabatDays':
            return "ימי שבת";
            
    
        default:
            return "----";
    }

    
}
