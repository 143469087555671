import React, { useState } from 'react'
import { sendtoAdmin } from '../../api/getDataFromApi';
import loader from "./../../../img/preLoader.gif";

import FirstNurseOrders from '../NurseEnv/FirstNurseOrders'

export default function WorkerShifts(props) {

    const { nurseData } = props;
    
    const [responseNurseData, setResponseNurseData] = useState(false);
    const [loading, setLoading] = useState(false);
    
    if(!loading && !responseNurseData) {

        const send = { nurseId : nurseData.id };

        //console.log(send);

        sendtoAdmin('getNurseData','NurseEnv', send ,setResponseNurseData,setLoading);
    }

    

    return (
        <div>
            <img src={loader} alt="loader" className={!loading ? "loader" : "loader active animate__animated animate__fadeIn" } />
            
            {/* && parseInt(responseNurseData.totalOrders) > 0 */}
            
            {responseNurseData.orders  ?  
                    
                <div className='whitePanel shadowDefault noWithPadd'>
                    <FirstNurseOrders nurseAndOrdersData ={ responseNurseData } isWeek = {true}/> 
                </div>
            
            : <h3 className='noShift'>אין משמרות</h3> }

        </div>
    )
}
