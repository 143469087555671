import React, { useState } from 'react'
import {getDays} from '../../models/ordersFuncNathan'
import NurseOrders from './NurseOrders'

import archives from './../../../img/nathan/svg/archive.svg'

import NurseOrdersSearch from './NurseOrdersSearch'
import NurseArchive from './NurseArchive'

export default function FirstNurseOrders(props) {

    //console.log(props);

    const ordersData = props.nurseAndOrdersData.orders;
    const nurseData = props.nurseAndOrdersData.nurseData;

    const isWeekProps = props.isWeek;

    
    const [isWeek, setIsWeek] = useState(isWeekProps);
    const [archive, setArchive] = useState(false);


    const updateArchive = (orders) => {

        setArchive(orders);
        console.log(orders);

    }


    return (
        
        <div className='FirstNurseOrders'>

            {getHeader(isWeek, setIsWeek)}

            { !archive ? < NurseOrders orders={ordersData} /> : <NurseArchive orders={archive} /> }
            
        </div>

    )

    function getHeader(isWeek, setIsWeek) {

        const days =  getDays();

        const showArchiveChange = () => {
            
            setIsWeek(false);

        }

        if(isWeek) {
            
            return(
                <header>
                    <h2>לוז שבועי</h2>
                    <p>{days.today} - {days.nextdays}</p>
                    <button className='showArchiveChangeBtn' onClick={ showArchiveChange }>
                        <img src={archives} alt='ארכיון' />
                        <span>ארכיון</span>
                    </button>
                </header>
            ) }
            
        else {

            return(
                <header>
                    <NurseOrdersSearch
                        goBack={ () => {setIsWeek(true); updateArchive(false)} }
                        nurseData = {nurseData}
                        updateArchive = { (orders) => updateArchive(orders)}
                    />
                </header>
            );

        }
        

    }


    
}
