import React from 'react'
import OrderPayAddPayItemsShow from './OrderPayAddPayItemsShow'

import OrderPayAddPayItemsForm from './OrderPayAddPayItemsForm'

export default function OrderPayAddPayItems(props) {

    const {reloadData, payData, orderData, showTravelExpenses } = props;

    return (
        <div className='OrderPayAddPayItems line addPad animate__animated animate__fadeIn'>
            
            {( (!payData.payments[0] ) && (!payData.payItems?.items[0] || orderData.is_prati === '1' ) ) && <OrderPayAddPayItemsForm showTravelExpenses = { showTravelExpenses } orderData = { orderData } reloadData = {reloadData} /> }

            {payData.payItems?.items[0] && <OrderPayAddPayItemsShow orderData = { orderData } payData = {payData} reloadData = {reloadData} />}

        </div>
    )
}


